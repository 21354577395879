<script>
  import { afterUpdate, onMount } from "svelte";
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { conditions } from "../shared/Conditions.js";
  import {
    countries,
    usStates,
    auStates,
    esStates,
    gbStates,
    jpStates,
    caStates,
  } from "../shared/CountriesStates.js";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import {
    faMinusSquare,
    faTrash,
    faTrashUndoAlt,
    faExclamationTriangle,
    faChevronDown,
    faChevronRight,
    faPlusCircle,
  } from "@fortawesome/pro-solid-svg-icons";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let crt;
  const cartUnsubscribe = cart.subscribe((value) => {
    crt = value;
  });

  let isWorking = true;

  let totalItems = 0;
  let bookCount = 0;
  let framedCount = 0;
  let regulars = 0;
  let regularsTotal = 0.0;
  let cheaps = 0;
  let cheapsTotal = 0;
  let freebies = 0;
  let freebiesAllowed = 0;
  let primeTotal = 0;
  let giftCerts = 0;
  let pGiftCerts = 0;
  let pGiftCertsTotal = 0.0;
  let eGiftCerts = 0;
  let eGiftCertsTotal = 0.0;
  let coupons = 0;
  let couponType = "";
  let totalCouponDisc = 0.0;
  let couponDiscPercent = 0.0;
  let couponDiscSubTotal = 0.0;
  let totalDollarCouponDisc = 0.0;
  let dollarCouponDiscPercent = 0.0;
  let dollarCouponDiscSubTotal = 0.0;
  let subTotalTaxable = 0.0;
  let subTotalNonTaxable = 0.0;
  let subTotal = 0.0;
  let totalDisc = 0.0;
  let discPercent = 0.0;
  let discSubTotal = 0.0;
  let giftCreditsAmount = 0.0;
  let tax = "TBD";
  let shipping = "TBD";
  let total = "TBD";
  function reCalculateCart() {
    totalItems = 0;
    bookCount = 0;
    framedCount = 0;
    regulars = 0;
    regularsTotal = 0.0;
    cheaps = 0;
    cheapsTotal = 0;
    freebies = 0;
    freebiesAllowed = 0;
    primeTotal = 0;
    giftCerts = 0;
    pGiftCerts = 0;
    pGiftCertsTotal = 0.0;
    eGiftCerts = 0;
    eGiftCertsTotal = 0.0;
    coupons = 0;
    couponType = "";
    totalCouponDisc = 0.0;
    couponDiscPercent = 0.0;
    couponDiscSubTotal = 0.0;
    totalDollarCouponDisc = 0.0;
    dollarCouponDiscPercent = 0.0;
    dollarCouponDiscSubTotal = 0.0;
    subTotalTaxable = 0.0;
    subTotalNonTaxable = 0.0;
    let previousSubTotal = subTotal;
    subTotal = 0.0;
    totalDisc = 0.0;
    discPercent = 0.0;
    discSubTotal = 0.0;
    giftCreditsAmount = 0.0;
    tax = "TBD";
    shipping = "TBD";
    total = "TBD";
    if (crt && Array.isArray(crt)) {
      crt.forEach((element) => {
        if (element.Status != "X") {
          if (element.Typ == 0) {
            // regular comics
            regulars++;
            regularsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 10) {
            // bulk/shirts
            cheaps++;
            cheapsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 20) {
            // free comics
            freebies++;
          } else if (element.Typ == 50) {
            // paper gift certs
            pGiftCerts++;
            pGiftCertsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 55) {
            // email gift certs
            eGiftCerts++;
            eGiftCertsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 70 || element.Typ == 75) {
            // prime
            primeTotal += element.Quoted_Price;
          } else if (element.Typ == 140) {
            // $ discount coupons
            coupons++;
            couponType = "$";
            totalCouponDisc =
              parseFloat(element.Unit_Price) <= 0
                ? parseFloat(element.Unit_Price)
                : parseFloat(element.Unit_Price) * -1;
            totalDisc += totalCouponDisc;
          } else if (element.Typ == 141) {
            // $ off entire order coupons
            coupons++;
            couponType = "$off";
            totalDollarCouponDisc =
              parseFloat(element.Quoted_Price) <= 0
                ? parseFloat(element.Quoted_Price)
                : parseFloat(element.Quoted_Price) * -1;
            totalDisc += totalDollarCouponDisc;
          } else if (element.Typ == 145) {
            // % discount coupons
            coupons++;
            couponType = "%";
            totalCouponDisc =
              parseFloat(element.Quoted_Price) <= 0
                ? parseFloat(element.Quoted_Price)
                : parseFloat(element.Quoted_Price) * -1;
            totalDisc += totalCouponDisc;
          } else if (element.Typ == 150) {
            // gift credits
            giftCreditsAmount += parseFloat(element.Unit_Price);
          }
        }
        if (/framed and matted/gi.test(element.Comic_Name)) framedCount++;
      });
      bookCount = regulars + cheaps + freebies;
      giftCerts = pGiftCerts + eGiftCerts;
      totalItems = bookCount + giftCerts + (primeTotal > 0 ? 1 : 0);
      subTotalTaxable = regularsTotal + cheapsTotal;
      subTotalNonTaxable = pGiftCertsTotal + eGiftCertsTotal + primeTotal;
      subTotal = subTotalTaxable + subTotalNonTaxable;
      if (
        subTotal - eGiftCertsTotal > 0 &&
        subTotal >= freebieSettings["4freecomic_val"]
      )
        freebiesAllowed = 4;
      else if (
        subTotal - eGiftCertsTotal > 0 &&
        subTotal >= freebieSettings["3freecomic_val"]
      )
        freebiesAllowed = 3;
      else if (
        subTotal - eGiftCertsTotal > 0 &&
        subTotal >= freebieSettings["2freecomic_val"]
      )
        freebiesAllowed = 2;
      else if (
        subTotal - eGiftCertsTotal > 0 &&
        subTotal >= freebieSettings["1freecomic_val"]
      )
        freebiesAllowed = 1;
      else freebiesAllowed = 0;
      if (bookCount == 0 && pGiftCerts == 0 && eGiftCerts > 0) shipping = 0.0;
      else if (shippingSelected)
        shipping = parseFloat(shippingSelected.Shipping_Chrg);
      couponDiscSubTotal = subTotal + totalCouponDisc;
      if (subTotal > 0)
        couponDiscPercent =
          1 -
          (couponDiscSubTotal - eGiftCertsTotal) / (subTotal - eGiftCertsTotal);
      dollarCouponDiscSubTotal = subTotal + totalDollarCouponDisc;
      if (subTotal > 0)
        dollarCouponDiscPercent = 1 - dollarCouponDiscSubTotal / subTotal;
      discSubTotal = subTotal + totalDisc;
      if (subTotal > 0)
        discPercent =
          1 - (discSubTotal - eGiftCertsTotal) / (subTotal - eGiftCertsTotal);
      if (discSubTotal < 0) {
        if (totalDisc + subTotal + shipping <= 0)
          totalDisc = -1 * (subTotal + shipping);
        discSubTotal = 0;
        discPercent = 1.0;
      }
      calculateTaxes();
      if (taxKey != "" && taxable[taxKey] && subTotalTaxable > 0) {
        if (subTotalNonTaxable > 0)
          tax = subTotalTaxable * (1 - discPercent) * taxable[taxKey];
        else tax = discSubTotal * taxable[taxKey];
      } else tax = 0.0;
      total =
        subTotal +
        (shippingSelected ? shipping : 0) +
        (tax != "TBD" ? tax : 0) +
        totalDisc;
      if (giftCreditsAmount > 0)
        total = total - giftCreditsAmount > 0 ? total - giftCreditsAmount : 0;
      if (subTotal != previousSubTotal) getShippingOptions();
    }
  }

  let showFreebieList = false;
  let freebiesList = [];
  let freebieSettings = {};
  let freebieColors = {
    gold: "#FFEDA4",
    green: "#E1FFE1",
    blue: "lightblue",
    yellow: "yellow",
    grey: "#EEEEEE",
  };
  async function getFreebies() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "freebies/" + sess.currency, options)
      .then((res) => res.json())
      .then((res) => {
        freebiesList = [];
        var colorIndex = -1;
        var tmp = [colorIndex, []];
        var lastLevel = 0;
        for (var i = 0; i < res.length; i++) {
          if (i > 0 && res[i].Min_Chrg != lastLevel) {
            freebiesList.push(tmp);
            if (res[i].Min_Chrg > subTotal) colorIndex++;
            tmp = [colorIndex, []];
          }
          lastLevel = res[i].Min_Chrg;
          tmp[1].push(res[i]);
        }
        freebiesList.push(tmp);
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }
  async function getFreebieSettings() {
    var options = {
      method: "POST",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + sess.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify([
        "1freecomic_val",
        "2freecomic_val",
        "3freecomic_val",
        "4freecomic_val",
      ]),
    };
    await fetch(app.apiUrl + "adm_options/getoptions/" + sess.currency, options)
      .then((res) => res.json())
      .then((res) => {
        freebieSettings = res;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  function formatMoney(number, decPlaces, decSep, thouSep) {
    (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
      (decSep = typeof decSep === "undefined" ? "." : decSep);
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    if (sess.currency == "USD") sign = "$" + sign;
    var i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : "") +
      (sess.currency != "USD" ? "<small>" + sess.currency + "</small>" : "")
    );
  }

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function goToIssues(tskey, subseries, issuenumber) {
    application.update((n) => {
      return {
        ...n,
        screen: "issues",
        screenmode: tskey,
        screencriteria: subseries,
        screensubcriteria: issuenumber,
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  async function clearCart() {
    if (!isWorking) {
      isWorking = true;
      document.getElementById("dinger").volume = 0;
      document.getElementById("dinger").play();
      var options = {
        method: "POST",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ currency: sess.currency, sessionid: sess.id }),
      };
      await fetch(app.apiUrl + "cart/empty", options)
        .then((res) => res.json())
        .then((res) => {
          res.map((element) => {
            if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
              element.Ref = JSON.parse(element.Ref);
            else if (
              element.Ref != "" &&
              element.Ref.indexOf("?Comics=") > -1
            ) {
              var tmp = element.Ref.split("=");
              tmp = tmp[1].split("#");
              tmp = tmp[0].split("|");
              element.Ref = [tmp[0], "", tmp[1], "", ""];
            }
          });
          cart.update((n) => {
            return res;
          });
          reCalculateCart();
          if (
            navigator.vibrate ||
            navigator.webkitVibrate ||
            navigator.mozVibrate ||
            navigator.msVibrate
          )
            navigator.vibrate(200);
          else {
            document.getElementById("dinger").volume = 1;
            document.getElementById("dinger").play();
          }
          isWorking = false;
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    }
  }

  async function deleteRow($stockNumber) {
    if (!isWorking) {
      isWorking = true;
      document.getElementById("dinger").volume = 0;
      document.getElementById("dinger").play();
      var options = {
        method: "POST",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currency: sess.currency,
          sessionid: sess.id,
          stocknumbers: [$stockNumber],
        }),
      };
      await fetch(app.apiUrl + "cart/delete", options)
        .then((res) => res.json())
        .then((res) => {
          res.map((element) => {
            if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
              element.Ref = JSON.parse(element.Ref);
            else if (
              element.Ref != "" &&
              element.Ref.indexOf("?Comics=") > -1
            ) {
              var tmp = element.Ref.split("=");
              tmp = tmp[1].split("#");
              tmp = tmp[0].split("|");
              element.Ref = [tmp[0], "", tmp[1], "", ""];
            }
          });
          cart.update((n) => {
            return res;
          });
          reCalculateCart();
          if (
            navigator.vibrate ||
            navigator.webkitVibrate ||
            navigator.mozVibrate ||
            navigator.msVibrate
          )
            navigator.vibrate(200);
          else {
            document.getElementById("dinger").volume = 1;
            document.getElementById("dinger").play();
          }
          isWorking = false;
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    }
  }

  async function remove($stockNumber) {
    if (!isWorking) {
      isWorking = true;
      document.getElementById("dinger").volume = 0;
      document.getElementById("dinger").play();
      var options = {
        method: "POST",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currency: sess.currency,
          sessionid: sess.id,
          stocknumbers: [$stockNumber],
        }),
      };
      await fetch(app.apiUrl + "cart/remove", options)
        .then((res) => res.json())
        .then((res) => {
          res.map((element) => {
            if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
              element.Ref = JSON.parse(element.Ref);
            else if (
              element.Ref != "" &&
              element.Ref.indexOf("?Comics=") > -1
            ) {
              var tmp = element.Ref.split("=");
              tmp = tmp[1].split("#");
              tmp = tmp[0].split("|");
              element.Ref = [tmp[0], "", tmp[1], "", ""];
            }
          });
          cart.update((n) => {
            return res;
          });
          reCalculateCart();
          if (
            navigator.vibrate ||
            navigator.webkitVibrate ||
            navigator.mozVibrate ||
            navigator.msVibrate
          )
            navigator.vibrate(200);
          else {
            document.getElementById("dinger").volume = 1;
            document.getElementById("dinger").play();
          }
          isWorking = false;
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    }
  }

  async function addBack($stockNumber) {
    if (!isWorking) {
      isWorking = true;
      document.getElementById("dinger").volume = 0;
      document.getElementById("dinger").play();
      var options = {
        method: "POST",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currency: sess.currency,
          sessionid: sess.id,
          stocknumbers: [$stockNumber],
        }),
      };
      await fetch(app.apiUrl + "cart/addback", options)
        .then((res) => res.json())
        .then((res) => {
          res.map((element) => {
            if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
              element.Ref = JSON.parse(element.Ref);
            else if (
              element.Ref != "" &&
              element.Ref.indexOf("?Comics=") > -1
            ) {
              var tmp = element.Ref.split("=");
              tmp = tmp[1].split("#");
              tmp = tmp[0].split("|");
              element.Ref = [tmp[0], "", tmp[1], "", ""];
            }
          });
          cart.update((n) => {
            return res;
          });
          reCalculateCart();
          if (
            navigator.vibrate ||
            navigator.webkitVibrate ||
            navigator.mozVibrate ||
            navigator.msVibrate
          )
            navigator.vibrate(200);
          else {
            document.getElementById("dinger").volume = 1;
            document.getElementById("dinger").play();
          }
          isWorking = false;
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    }
  }

  async function addToCart(code) {
    if (!isWorking) {
      if (code != "") {
        isWorking = true;
        document.getElementById("dinger").volume = 0;
        document.getElementById("dinger").play();
        var options = {
          method: "POST",
          cache: "no-cache",
          headers: {
            Authorization: "Bearer " + sess.token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currency: sess.currency,
            sessionid: sess.id,
            code: code,
          }),
        };
        await fetch(app.apiUrl + "cart/addfreebie", options)
          .then((res) => res.json())
          .then((res) => {
            res.map((element) => {
              if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
                element.Ref = JSON.parse(element.Ref);
              else if (
                element.Ref != "" &&
                element.Ref.indexOf("?Comics=") > -1
              ) {
                var tmp = element.Ref.split("=");
                tmp = tmp[1].split("#");
                tmp = tmp[0].split("|");
                element.Ref = [tmp[0], "", tmp[1], "", ""];
              }
            });
            cart.update((n) => {
              return res;
            });
            reCalculateCart();
            if (
              navigator.vibrate ||
              navigator.webkitVibrate ||
              navigator.mozVibrate ||
              navigator.msVibrate
            )
              navigator.vibrate(200);
            else {
              document.getElementById("dinger").volume = 1;
              document.getElementById("dinger").play();
            }
            isWorking = false;
          })
          .catch((err) => {
            /*console.log(err)*/
          });
      }
    }
  }

  async function addPrime(whichPrime) {
    if (!isWorking) {
      isWorking = true;
      document.getElementById("dinger").volume = 0;
      document.getElementById("dinger").play();
      shippingSelected = null;
      session.update((n) => {
        return { ...n, shippingSelected: null };
      });
      var options = {
        method: "POST",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currency: sess.currency,
          sessionid: sess.id,
          whichPrime: whichPrime,
        }),
      };
      await fetch(app.apiUrl + "cart/addprime", options)
        .then((res) => res.json())
        .then((res) => {
          res.map((element) => {
            if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
              element.Ref = JSON.parse(element.Ref);
            else if (
              element.Ref != "" &&
              element.Ref.indexOf("?Comics=") > -1
            ) {
              var tmp = element.Ref.split("=");
              tmp = tmp[1].split("#");
              tmp = tmp[0].split("|");
              element.Ref = [tmp[0], "", tmp[1], "", ""];
            }
          });
          cart.update((n) => {
            return res;
          });
          reCalculateCart();
          if (
            navigator.vibrate ||
            navigator.webkitVibrate ||
            navigator.mozVibrate ||
            navigator.msVibrate
          )
            navigator.vibrate(200);
          else {
            document.getElementById("dinger").volume = 1;
            document.getElementById("dinger").play();
          }
          isWorking = false;
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    }
  }

  let originalAddresses = [];
  let addresses = [];
  let selectedAddress = null;
  let addressError = "";
  let addressUpdating = false;
  async function getAddresses() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "cust_addrs/" + usr.Cust_ID, options)
      .then((res) => res.json())
      .then((res) => {
        addresses = res;
        originalAddresses = JSON.parse(JSON.stringify(res));
        if (sess && sess.selectedAddress) {
          selectedAddress = addresses.filter(
            (address) => address.id == sess.selectedAddress.id
          )[0];
          session.update((n) => {
            return {
              ...n,
              country: selectedAddress.Country,
              state: selectedAddress.State,
            };
          });
        } else if (selectedAddress == null) {
          selectedAddress = addresses.filter(
            (address) => address.Current == "Y"
          )[0];
          session.update((n) => {
            return {
              ...n,
              country: selectedAddress.Country,
              state: selectedAddress.State,
              selectedAddress: selectedAddress,
            };
          });
        }
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }
  function addressSelected() {
    session.update((n) => {
      return {
        ...n,
        country: selectedAddress.Country,
        state: selectedAddress.State,
        selectedAddress: selectedAddress,
      };
    });
    updateTaxKey();
    getShippingOptions();
  }
  function updateCountry(ev) {
    session.update((n) => {
      return { ...n, country: ev.target.value };
    });
    updateTaxKey();
    getShippingOptions();
  }
  function updateState(ev) {
    session.update((n) => {
      return { ...n, state: ev.target.value };
    });
    updateTaxKey();
    getShippingOptions();
  }

  let primeDetails = {};
  let primeUntil = null;
  let monthsOfYear = [
    ["January", "Jan"],
    ["February", "Feb"],
    ["March", "Mar"],
    ["April", "Apr"],
    ["May", "May"],
    ["June", "Jun"],
    ["July", "Jul"],
    ["August", "Aug"],
    ["September", "Sep"],
    ["October", "Oct"],
    ["November", "Nov"],
    ["December", "Dec"],
  ];
  async function getPrime() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "adm_options/get/prime", options)
      .then((res) => res.json())
      .then((res) => {
        primeDetails = res;
        primeUntil =
          new Date().getTime() + 86400000 * primeDetails.primeLengthDays;
        primeUntil = new Date(primeUntil);
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  let shippingOptions = [];
  let shippingSelected = null;
  let priorityCost = 0.0;
  let standardCost = 0.0;
  async function getShippingOptions() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    var url =
      app.apiUrl +
      "newcust_shipping/" +
      sess.country +
      "/" +
      (sess.state && sess.state != "" ? sess.state : "0") +
      "/" +
      sess.currency +
      "/" +
      cheaps +
      "/" +
      regulars +
      "/" +
      subTotal;
    if (usr && usr.Orders_Placed && usr.Orders_Placed > 0)
      url =
        app.apiUrl +
        "shipping/" +
        sess.country +
        "/" +
        (sess.state && sess.state != "" ? sess.state : "0") +
        "/" +
        sess.currency +
        "/" +
        cheaps +
        "/" +
        regulars +
        "/" +
        subTotal;
    await fetch(url, options)
      .then((res) => res.json())
      .then((res) => {
        shippingOptions = res;
        var whichPrime = usr.prime ? usr.prime : 0;
        if (primeTotal > 0) {
          if (crt.filter((item) => item.Typ == 75).length > 0)
            whichPrime = 2; // standard
          else if (crt.filter((item) => item.Typ == 70).length > 0)
            whichPrime = 1; // priority
        }
        priorityCost = 0.0;
        standardCost = 0.0;
        for (var i = 0; i < shippingOptions.length; i++) {
          if (shippingOptions[i].Ship_Code == "Priority")
            priorityCost = shippingOptions[i].Base_Cost;
          if (shippingOptions[i].Ship_Code == "Standard")
            standardCost = shippingOptions[i].Base_Cost;
        }
        for (var i = 0; i < shippingOptions.length; i++) {
          shippingOptions[i].Ship_By = shippingOptions[i].Ship_By.replace(
            /\s\s+/gi,
            ""
          );
          if (
            whichPrime == 1 &&
            shippingOptions[i].Ship_Code == "Priority" &&
            sess.country == "US"
          ) {
            shippingOptions[i].Ship_By =
              "<em>Prime Priority</em></b><br/>Free shipping for 6 months";
            shippingOptions[i].Base_Cost = 0.0;
            shippingOptions[i].Shipping_Chrg = 0.0;
          } else if (
            whichPrime == 1 &&
            shippingOptions[i].Ship_Code == "Standard" &&
            sess.country == "US"
          ) {
            shippingOptions.splice(i, 1);
          } else if (
            whichPrime == 2 &&
            shippingOptions[i].Ship_Code == "Standard" &&
            sess.country == "US"
          ) {
            shippingOptions[i].Ship_By =
              "<em>Prime Standard</em></b><br/>Free shipping for 6 months";
            shippingOptions[i].Base_Cost = 0.0;
            shippingOptions[i].Shipping_Chrg = 0.0;
          } else {
            if (whichPrime == 1 && sess.country == "US")
              shippingOptions[i].Base_Cost -= priorityCost;
            else if (whichPrime == 2 && sess.country == "US")
              shippingOptions[i].Base_Cost -= standardCost;
            if (shippingOptions[i].Base_Cost < 0)
              shippingOptions[i].Base_Cost = 0;
            if (shippingOptions[i].Group_Size == 1) {
              shippingOptions[i].Shipping_Chrg =
                parseFloat(shippingOptions[i].Base_Cost) +
                parseFloat(shippingOptions[i].Comic_Cost) *
                  parseFloat(regulars) +
                parseFloat(shippingOptions[i].Cheap_Cost) * parseFloat(cheaps) +
                parseFloat(shippingOptions[i].Free_Cost) * parseFloat(freebies);
            } else {
              if (framedCount > 0)
                shippingOptions[i].Shipping_Chrg =
                  Math.ceil(bookCount / shippingOptions[i].Group_Size) *
                    parseFloat(shippingOptions[i].Base_Cost) +
                  parseFloat(framedCount) *
                    parseFloat(shippingOptions[i].Cheap_Cost);
              else
                shippingOptions[i].Shipping_Chrg =
                  Math.ceil(bookCount / shippingOptions[i].Group_Size) *
                  parseFloat(shippingOptions[i].Base_Cost);
            }
          }
        }
        var previousSelection = shippingSelected;
        var found = false;
        if (sess.shippingSelected) {
          for (var i = 0; i < shippingOptions.length; i++) {
            if (shippingOptions[i].ship_id == sess.shippingSelected.ship_id) {
              shippingSelected = shippingOptions[i];
              found = true;
              break;
            }
          }
        }
        if (!found) {
          var cheapest = 999999999;
          var cheapestIndex = -1;
          for (var i = 0; i < shippingOptions.length; i++) {
            if (shippingOptions[i].Shipping_Chrg < cheapest) {
              cheapest = shippingOptions[i].Shipping_Chrg;
              cheapestIndex = i;
            }
          }
          shippingSelected = shippingOptions[cheapestIndex];
        }
        if (previousSelection != shippingSelected) {
          session.update((n) => {
            return { ...n, shippingSelected: shippingSelected };
          });
          reCalculateCart();
        }
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }
  function selectShipping(option) {
    shippingSelected = option;
    session.update((n) => {
      return { ...n, shippingSelected: shippingSelected };
    });
    reCalculateCart();
  }

  let taxable = {};
  let taxKey = "";
  async function getTaxes() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "adm_options/taxes", options)
      .then((res) => res.json())
      .then((res) => {
        taxable = res;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }
  function updateTaxKey() {
    if (sess.country && sess.country != "") {
      taxKey = sess.country;
      if (["US", "AU", "ES", "CA"].indexOf(sess.country) > -1)
        taxKey += sess.state;
      else if (sess.state && sess.state != "")
        taxKey += sess.state.replace(/\s/g, "");
      reCalculateCart();
    }
  }
  async function calculateTaxes() {
    //    let addr = { "Country": sess.country ? sess.country : '' , "State": sess.state ? sess.state : '' };
    //    if (selectedAddress) addr = selectedAddress;
    //    var options = {
    //        method: "POST",
    //        //cache: "no-cache",
    //		headers: {
    //			"Authorization": "Bearer " + sess.token,
    //			"Accept": "application/json",
    //		},
    //        body: JSON.stringify({
    //            "selectedAddress": addr,
    //            "taxableTotal": subTotalTaxable,
    //            "shippingCost": typeof shipping == 'number' ? shipping : 0
    //        })
    //    };
    //    console.log(options);
    //    await fetch(app.apiUrl + "cart/calculatetax", options)
    //        .then(res => res.json())
    //        .then(res => {
    //            console.log(res);
    //        })
    //        .catch(err => { /*console.log(err)*/ });
  }

  let giftCreditError = "";
  let giftCardCode = "";
  async function addGiftCredit(ev) {
    ev.target.blur();
    if (giftCardCode != "") {
      if (!isWorking) {
        isWorking = true;
        document.getElementById("dinger").volume = 0;
        document.getElementById("dinger").play();
        giftCreditError = "";
        var options = {
          method: "POST",
          cache: "no-cache",
          headers: {
            Authorization: "Bearer " + sess.token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currency: sess.currency,
            sessionid: sess.id,
            code: giftCardCode,
          }),
        };
        await fetch(app.apiUrl + "cart/addgiftcredit", options)
          .then((res) => res.json())
          .then((res) => {
            res.cart.map((element) => {
              if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
                element.Ref = JSON.parse(element.Ref);
              else if (
                element.Ref != "" &&
                element.Ref.indexOf("?Comics=") > -1
              ) {
                var tmp = element.Ref.split("=");
                tmp = tmp[1].split("#");
                tmp = tmp[0].split("|");
                element.Ref = [tmp[0], "", tmp[1], "", ""];
              }
            });
            cart.update((n) => {
              return res.cart;
            });
            reCalculateCart();
            if (res.errors != "") giftCreditError = res.errors;
            giftCardCode = "";
            if (
              navigator.vibrate ||
              navigator.webkitVibrate ||
              navigator.mozVibrate ||
              navigator.msVibrate
            )
              navigator.vibrate(200);
            else {
              document.getElementById("dinger").volume = 1;
              document.getElementById("dinger").play();
            }
            isWorking = false;
          })
          .catch((err) => {
            /*console.log(err)*/
          });
      }
    }
  }

  let couponError = "";
  let couponCode = "";
  async function addCoupon(ev) {
    ev.target.blur();
    if (couponCode != "") {
      if (!isWorking) {
        isWorking = true;
        document.getElementById("dinger").volume = 0;
        document.getElementById("dinger").play();
        couponError = "";
        var options = {
          method: "POST",
          cache: "no-cache",
          headers: {
            Authorization: "Bearer " + sess.token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currency: sess.currency,
            sessionid: sess.id,
            code: couponCode,
            subtotal: subTotal,
          }),
        };
        await fetch(app.apiUrl + "cart/addcoupon", options)
          .then((res) => res.json())
          .then((res) => {
            res.cart.map((element) => {
              if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
                element.Ref = JSON.parse(element.Ref);
              else if (
                element.Ref != "" &&
                element.Ref.indexOf("?Comics=") > -1
              ) {
                var tmp = element.Ref.split("=");
                tmp = tmp[1].split("#");
                tmp = tmp[0].split("|");
                element.Ref = [tmp[0], "", tmp[1], "", ""];
              }
            });
            cart.update((n) => {
              return res.cart;
            });
            reCalculateCart();
            if (res.errors != "") couponError = res.errors;
            couponCode = "";
            if (
              navigator.vibrate ||
              navigator.webkitVibrate ||
              navigator.mozVibrate ||
              navigator.msVibrate
            )
              navigator.vibrate(200);
            else {
              document.getElementById("dinger").volume = 1;
              document.getElementById("dinger").play();
            }
            isWorking = false;
          })
          .catch((err) => {
            /*console.log(err)*/
          });
      }
    }
  }

  let couponGiftCreditCode = "";
  function addCouponOrGiftCredit(ev) {
    couponError = "";
    giftCreditError = "";
    if (couponGiftCreditCode.substring(0, 2).toLowerCase() == "gc") {
      giftCardCode = couponGiftCreditCode;
      addGiftCredit(ev);
    } else {
      couponCode = couponGiftCreditCode;
      addCoupon(ev);
    }
    couponGiftCreditCode = "";
  }

  onMount(async () => {
    if (crt && crt.length) {
      await getFreebieSettings();
      await getPrime();
      await getTaxes();
      updateTaxKey();
      if (usr && usr.Cust_ID && usr.Cust_ID > 0) await getAddresses();
      await getShippingOptions();
      await getFreebies();
    }
    isWorking = false;
  });

  let lastCheckedPrime = 0;
  afterUpdate(async () => {
    if (
      usr &&
      usr.Cust_ID &&
      (!lastCheckedPrime || Date.now() > lastCheckedPrime + 30000)
    ) {
      lastCheckedPrime = Date.now();
      var options = {
        method: "GET",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
        },
      };
      await fetch(app.apiUrl + "active_customers_prime", options)
        .then((res) => res.json())
        .then((res) => {
          if (typeof res.errors == "undefined") {
            user.update((n) => {
              return { ...n, prime: res.prime, primeuntil: res.primeuntil };
            });
          }
        })
        .catch((err) => {
          /* console.log(err); */
        });
    }
  });
</script>

<div class="row m-0 p-0">
  <div class="col-12 m-0 p-0" style="position:relative;">
    {#if isWorking}
      <div class="subshade" />
      <div class="subshadeloader">
        <div class="container my-1 py-1 text-center">
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    {/if}
    <div class="row m-0 p-0 border-bottom border-dark">
      <div class="col-12 m-0 p-0 text-center nkred">
        <h4 class="title">Shopping Cart</h4>
      </div>
    </div>
    {#if totalItems > 0}
      <div class="row m-0 p-0">
        <div
          class="col-12 text-right"
          role="button"
          tabindex="0"
          on:click|preventDefault={clearCart}
          on:keypress|preventDefault={clearCart}
        >
          <FontAwesomeIcon icon={faMinusSquare} class="small nkred" /> Empty Cart
        </div>
      </div>
    {/if}
    {#if crt.length > 0}
      <div class="row m-0 px-0 py-1 small nkbglightergrey">
        <div class="col-7"><strong>Item</strong></div>
        <div class="col-2"><strong>Cond</strong></div>
        <div class="col-2"><strong>Price</strong></div>
        <div class="col-1" />
      </div>
      {#each crt as row, ind}
        {#if row.Typ == 0 || row.Typ == 50 || row.Typ == 55 || row.Typ == 70 || row.Typ == 75}
          <div
            class="row m-0 px-0 py-1 small{row.Status == 'X'
              ? ' NKc_oosGrey'
              : ind % 2 == 0
                ? ' NKc_FBlue'
                : ' NKc_MBlue'}"
          >
            {#if row.Typ == 50 || row.Typ == 55 || row.Typ == 70 || row.Typ == 75}
              <div class="col-7{row.Status == 'X' ? ' strike' : ''}">
                {@html row.Comic_Name}
              </div>
            {:else}
              <div
                class="col-7 nkblue{row.Status == 'X' ? ' strike' : ''}"
                role="button"
                tabindex="0"
                on:click|preventDefault={() =>
                  goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
                on:keypress|preventDefault={() =>
                  goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
              >
                {@html row.Comic_Name}
              </div>
            {/if}
            <div class="col-2{row.Status == 'X' ? ' strike' : ''}">
              {row.Condition == "" ? "" : conditions[row.Condition]}
            </div>
            <div class="col-2 text-right{row.Status == 'X' ? ' strike' : ''}">
              {@html formatMoney(row.Quoted_Price)}
            </div>
            <div class="col-1 nkblue text-center">
              {#if row.Typ == 50 || row.Typ == 55}
                <div
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => deleteRow(row.Stock_Number)}
                  on:keypress|preventDefault={() => deleteRow(row.Stock_Number)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              {:else if row.Typ == 70 || row.Typ == 75}
                <div
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => {
                    shippingSelected = null;
                    session.update((n) => {
                      return { ...n, shippingSelected: null };
                    });
                    deleteRow(row.Stock_Number);
                  }}
                  on:keypress|preventDefault={() => {
                    shippingSelected = null;
                    session.update((n) => {
                      return { ...n, shippingSelected: null };
                    });
                    deleteRow(row.Stock_Number);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              {:else if row.Status != "X"}
                <div
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => remove(row.Stock_Number)}
                  on:keypress|preventDefault={() => remove(row.Stock_Number)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              {:else}
                <div
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => addBack(row.Stock_Number)}
                  on:keypress|preventDefault={() => addBack(row.Stock_Number)}
                >
                  <FontAwesomeIcon icon={faTrashUndoAlt} />
                </div>
              {/if}
            </div>
          </div>
        {:else if row.Typ == 10}
          <div class="row m-0 px-0 py-1 small NKc_FGold">
            <div
              class="col-7 nkblue{row.Status == 'X' ? ' strike' : ''}"
              role="button"
              tabindex="0"
              on:click|preventDefault={() =>
                goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
              on:keypress|preventDefault={() =>
                goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
            >
              {row.Comic_Name}
            </div>
            <div class="col-2{row.Status == 'X' ? ' strike' : ''}">
              {row.Condition == "" ? "" : conditions[row.Condition]}
            </div>
            <div class="col-2 text-right{row.Status == 'X' ? ' strike' : ''}">
              {@html formatMoney(row.Quoted_Price)}
            </div>
            <div class="col-1 nkblue text-center">
              {#if row.Status != "X"}
                <div
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => remove(row.Stock_Number)}
                  on:keypress|preventDefault={() => remove(row.Stock_Number)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </div>
              {:else}
                <div
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => addBack(row.Stock_Number)}
                  on:keypress|preventDefault={() => addBack(row.Stock_Number)}
                >
                  <FontAwesomeIcon icon={faTrashUndoAlt} />
                </div>
              {/if}
            </div>
          </div>
        {:else if row.Typ == 20}
          <div class="row m-0 px-0 py-1 small NKc_NGreen">
            <div
              class="col-7 nkblue{row.Status == 'X' ? ' strike' : ''}"
              role="button"
              tabindex="0"
              on:click|preventDefault={() =>
                goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
              on:keypress|preventDefault={() =>
                goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
            >
              {row.Comic_Name}
            </div>
            <div class="col-2{row.Status == 'X' ? ' strike' : ''}">
              {row.Condition == "Free" ? "" : conditions[row.Condition]}
            </div>
            <div class="col-2 text-right{row.Status == 'X' ? ' strike' : ''}">
              {@html row.Quoted_Price > 0
                ? formatMoney(row.Quoted_Price)
                : "Free"}
            </div>
            <div class="col-1 nkblue text-center">
              <div
                role="button"
                tabindex="0"
                on:click|preventDefault={() => deleteRow(row.Stock_Number)}
                on:keypress|preventDefault={() => deleteRow(row.Stock_Number)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </div>
          </div>
        {:else if row.Typ == 140 || row.Typ == 141 || row.Typ == 145 || row.Typ == 150 || row.Typ == 200}
          <div class="row m-0 px-0 py-1 small NKc_LYellow">
            <div class="col-9">
              {@html row.Comic_Name}<br />{@html row.Condition}
            </div>
            <div class="col-2 text-right">
              {@html formatMoney(row.Quoted_Price)}
            </div>
            <div class="col-1 nkblue text-center">
              <div
                role="button"
                tabindex="0"
                on:click|preventDefault={() => deleteRow(row.Stock_Number)}
                on:keypress|preventDefault={() => deleteRow(row.Stock_Number)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </div>
          </div>
        {/if}
      {/each}
      {#if subTotal > 0}
        <div
          class="row m-0 p-0{crt.length % 2 == 0 ? ' NKc_FBlue' : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right">
            <strong>Product Total:</strong>
          </div>
          <div class="col-2 py-1 text-right">{@html formatMoney(subTotal)}</div>
          <div class="col-1 py-1" />
        </div>
        {#if totalDisc < 0}
          <div
            class="row m-0 p-0{(crt.length + 1) % 2 == 0
              ? ' NKc_FBlue'
              : ' NKc_MBlue'}"
          >
            <div class="col-9 py-1 text-right"><strong>Discount:</strong></div>
            <div class="col-2 py-1 text-right">
              {@html formatMoney(totalDisc)}
            </div>
            <div class="col-1 py-1" />
          </div>
        {/if}
        <div
          class="row m-0 p-0{(crt.length + (totalDisc < 0 ? 2 : 1)) % 2 == 0
            ? ' NKc_FBlue'
            : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right"><strong>Sales Tax:</strong></div>
          <div class="col-2 py-1 text-right">
            {@html isNaN(tax) ? tax : formatMoney(tax)}
          </div>
          <div class="col-1 py-1" />
        </div>
        <div
          class="row m-0 p-0{(crt.length + (totalDisc < 0 ? 3 : 2)) % 2 == 0
            ? ' NKc_FBlue'
            : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right"><strong>Shipping:</strong></div>
          <div class="col-2 py-1 text-right">
            {@html isNaN(shipping) ? shipping : formatMoney(shipping)}
          </div>
          <div class="col-1 py-1" />
        </div>
        <div
          class="row m-0 p-0{(crt.length + (totalDisc < 0 ? 4 : 3)) % 2 == 0
            ? ' NKc_FBlue'
            : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right"><strong>Insurance:</strong></div>
          <div class="col-2 py-1 text-right">Free</div>
          <div class="col-1 py-1" />
        </div>
        <div
          class="row m-0 p-0{(crt.length + (totalDisc < 0 ? 5 : 4)) % 2 == 0
            ? ' NKc_FBlue'
            : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right">
            <strong>Comics bags/boards:</strong>
          </div>
          <div class="col-2 py-1 text-right">Free</div>
          <div class="col-1 py-1" />
        </div>
        <div
          class="row m-0 p-0{(crt.length + (totalDisc < 0 ? 6 : 5)) % 2 == 0
            ? ' NKc_FBlue'
            : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right"><strong>Total:</strong></div>
          <div class="col-2 py-1 text-right">
            {@html isNaN(total) ? total : formatMoney(total)}
          </div>
          <div class="col-1 py-1" />
        </div>
        <div class="row m-0 p-0">
          <div class="col-12 py-1 nkbglightergrey clearfix">
            <div class="d-inline-block align-middle">
              <strong>Coupons &amp; Gift Cards</strong>
            </div>
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-10 p-1">
            <input
              type="text"
              id="couponGiftCreditCode"
              placeholder="Enter code"
              class="px-1 nkbgpaleyellow"
              style="width:100%;"
              maxlength="22"
              bind:value={couponGiftCreditCode}
            /><br />
            <small
              ><em
                >Coupons valid for comics with prices in <span
                  class="strong nkgreen">green</span
                >.</em
              ></small
            >
            {#if couponError != ""}<br /><span class="nkred strong"
                ><FontAwesomeIcon icon={faExclamationTriangle} />
                {couponError}</span
              >{/if}
            {#if giftCreditError != ""}<br /><span class="nkred strong"
                ><FontAwesomeIcon icon={faExclamationTriangle} />
                {giftCreditError}</span
              >{/if}
          </div>
          <div class="col-2 p-1">
            <button
              class="btn btn-sm btn-secondary border-0"
              on:click|preventDefault={(event) => addCouponOrGiftCredit(event)}
              >Apply</button
            >
          </div>
        </div>
        {#if freebiesAllowed > 0}
          <div class="row m-0 p-0">
            <div class="col-12 py-1 nkbglightergrey clearfix">
              <div class="d-inline-block align-middle">
                <strong>Free Comics</strong>
              </div>
            </div>
          </div>
          <div
            class="row m-0 p-0 small {freebiesAllowed - freebies == 0
              ? ''
              : ' NKc_NGreen'}"
          >
            <div class="col-12 mb-1 py-1 text-center">
              {#if freebiesAllowed - freebies == 0}
                You added {freebiesAllowed} free {freebiesAllowed > 1
                  ? "comics"
                  : "comic"}. To add a different one, you need to remove one
                from your cart first.
              {:else}
                Add {freebiesAllowed - freebies} free {freebiesAllowed -
                  freebies >
                1
                  ? "comics"
                  : "comic"} from the
                <div
                  class="d-inline nkblue"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => {
                    showFreebieList = !showFreebieList;
                  }}
                  on:keypress|preventDefault={() => {
                    showFreebieList = !showFreebieList;
                  }}
                >
                  Free Comic List <FontAwesomeIcon
                    icon={showFreebieList ? faChevronDown : faChevronRight}
                  />
                </div>
                <div
                  class="row m-0 mt-1 p-0 pb-2 bgwhite"
                  style="max-height:600px;overflow:scroll;display:{showFreebieList
                    ? 'block'
                    : 'none'};"
                >
                  <div class="col-12 py-1">
                    <p class="m-0 p-0">Select from the comics in white.</p>
                    {#each freebiesList as fbs}
                      {#if subTotal < fbs[1][0].Min_Chrg}
                        <p class="m-0 p-0">
                          Spend {@html formatMoney(fbs[1][0].Min_Chrg, 0)}+ and
                          choose from the {Object.keys(freebieColors)[fbs[0]]} section.
                        </p>
                      {/if}
                    {/each}
                    <p class="m-0 mb-1">
                      USA shipping free, elsewhere $0.99/each.
                    </p>
                    {#each freebiesList as fbs}
                      {#each fbs[1] as fb}
                        {#if crt.filter((item) => item.Stock_Number == fb.Code).length <= 0}
                          <div
                            role="button"
                            tabindex="0"
                            on:click|preventDefault={() => {
                              if (fbs[0] == -1) addToCart(fb.Code);
                            }}
                            on:keypress|preventDefault={() => {
                              if (fbs[0] == -1) addToCart(fb.Code);
                            }}
                            class="d-inline-block border border-lightergrey px-3 py-1 align-top"
                            style="position:relative;margin:2px;min-width:45%;max-width:45%;background:{fbs[0] ==
                            -1
                              ? '#FFF'
                              : freebieColors[
                                  Object.keys(freebieColors)[fbs[0]]
                                ]};"
                          >
                            <div class="m-0 p-0 strong" style="height:4.5em;">
                              {#if fbs[0] == -1}
                                <FontAwesomeIcon
                                  icon={faPlusCircle}
                                  class="nkred"
                                  style="font-size:1.25em;"
                                />
                              {/if}
                              {#if fb.Comic_Name.indexOf("(") > -1}
                                {@html fb.Comic_Name.split("(")[0]}
                              {:else}
                                {@html fb.Comic_Name}
                              {/if}
                            </div>
                            <img
                              src={fb.Cover_Img_URL}
                              class="smallCoverImg"
                              style="min-width:102px;max-width:102px"
                              alt={fb.Comic_Name}
                            />
                            <div
                              class="m-0 p-0 strong"
                              style="height:3em;overflow:hidden;text-overflow:ellipsis;"
                            >
                              {#if fb.Comic_Name.indexOf("(") > -1}
                                {@html fb.Comic_Name.split("(")[1].split(
                                  ")"
                                )[0]}
                              {/if}
                            </div>
                          </div>
                        {/if}
                      {/each}
                    {/each}
                  </div>
                </div>
              {/if}
            </div>
          </div>
        {/if}
        {#if sess.country == "US" && primeDetails && (primeDetails.bPrime || primeDetails.bPrimeBeta) && primeTotal == 0 && (!usr || !usr.Cust_ID || usr.prime == 0)}
          <div class="row m-0 p-0">
            <div class="col-12 py-1 nkbglightergrey clearfix">
              <div class="d-inline-block align-middle">
                <strong>NewKadia Prime</strong>
              </div>
            </div>
          </div>
          <div class="row m-0 p-0 border-bottom border-info">
            <div class="col-4 py-1">
              <img
                src="https://www.imagesnk.com/freeshippingMONO77x128.jpg"
                alt="Prime Standard - Free Shipping"
                style="max-width:100%;"
              />
            </div>
            <div class="col-8 py-1">
              Optional: <span class="nkdarkred"
                ><strong>Free USA Standard Shipping</strong></span
              >
              for 6 months for {@html formatMoney(
                primeDetails.primeStandard
              )}<br />
              <small
                ><strong>Save {@html formatMoney(standardCost)}</strong> today
                and <strong>Standard is FREE</strong> until {monthsOfYear[
                  primeUntil.getMonth()
                ][1]}. {primeUntil.getDate()}</small
              ><br />
              <button
                class="btn btn-primary btn-sm border-0 nkbgdarkred white"
                on:click|preventDefault={() => addPrime(2)}
                >Add Prime Standard</button
              >
            </div>
          </div>
          <div class="row m-0 p-0">
            <div class="col-4 py-1">
              <img
                src="https://www.imagesnk.com/freeshipping77x128.jpg"
                alt="Prime Priority - Free Shipping"
                style="max-width:100%;"
              />
            </div>
            <div class="col-8 py-1">
              Optional: <span class="nkred"
                ><strong>Free USA Priority Shipping</strong></span
              >
              for 6 months for {@html formatMoney(
                primeDetails.primePriority
              )}<br />
              <small
                ><strong>Save {@html formatMoney(priorityCost)}</strong> today
                and <strong>Priority is FREE</strong> until {monthsOfYear[
                  primeUntil.getMonth()
                ][1]}. {primeUntil.getDate()}</small
              ><br />
              <button
                class="btn btn-primary btn-sm border-0 nkbgred white"
                on:click|preventDefault={() => addPrime(1)}
                >Add Prime Priority</button
              >
            </div>
          </div>
        {/if}
        {#if bookCount > 0 || pGiftCerts > 0}
          {#if usr && usr.Cust_ID && usr.Cust_ID > 0 && addresses && addresses.length > 0}
            <div class="row m-0 p-0">
              <div class="col-12 py-1 nkbglightergrey clearfix">
                <div class="d-inline-block align-middle">
                  <strong>Ship To</strong>
                </div>
                <button
                  class="btn btn-secondary btn-sm float-right"
                  on:click|preventDefault={() => goTo("account")}
                  >Manage Addresses</button
                >
              </div>
            </div>
            <div class="row m-0 p-0">
              <div class="col-12 py-1 text-center">
                <select
                  bind:value={selectedAddress}
                  on:change={addressSelected}
                  style="width:100%;"
                >
                  {#each addresses as address}
                    <option value={address}>
                      {address.Addr_1},
                      {#if typeof address.City != "undefined" && address.City != ""}
                        {address.City},
                      {/if}
                      {#if typeof address.State != "undefined" && address.State != ""}
                        {address.State},
                      {/if}
                      {address.Country}
                    </option>
                  {/each}
                </select>
              </div>
            </div>
          {:else}
            <div class="row m-0 p-0">
              <div class="col-12 py-1 nkbglightergrey">
                <strong>Shipping To</strong>
              </div>
            </div>
            <div class="row m-0 p-0">
              <div class="col-4 py-1 text-right">Country:</div>
              <div class="col-8 py-1">
                <select
                  class="w-100 px-1"
                  bind:value={sess.country}
                  on:change={(event) => updateCountry(event)}
                >
                  {#each countries as country}
                    <option value={country.abbreviation}>{country.name}</option>
                  {/each}
                </select>
              </div>
            </div>
            {#if sess.country == "US"}
              <div class="row m-0 p-0">
                <div class="col-4 py-1 text-right">State:</div>
                <div class="col-8 py-1">
                  <select
                    class="w-100 mb-1 px-1"
                    bind:value={sess.state}
                    on:change={(event) => updateState(event)}
                  >
                    <option value="">- Select State -</option>
                    {#each usStates as st}
                      <option value={st.abbreviation}>{st.name}</option>
                    {/each}
                  </select>
                </div>
              </div>
            {:else if sess.country == "AU"}
              <div class="row m-0 p-0">
                <div class="col-4 py-1 text-right">State:</div>
                <div class="col-8 py-1">
                  <select
                    class="w-100 mb-1 px-1"
                    bind:value={sess.state}
                    on:change={(event) => updateState(event)}
                  >
                    <option value="">- Select State -</option>
                    {#each auStates as st}
                      <option value={st.abbreviation}>{st.name}</option>
                    {/each}
                  </select>
                </div>
              </div>
            {:else if sess.country == "ES"}
              <div class="row m-0 p-0">
                <div class="col-4 py-1 text-right">Province:</div>
                <div class="col-8 py-1">
                  <select
                    class="w-100 mb-1 px-1"
                    bind:value={sess.state}
                    on:change={(event) => updateState(event)}
                  >
                    <option value="">- Select Province -</option>
                    {#each esStates as st}
                      <option value={st.abbreviation}>{st.name}</option>
                    {/each}
                  </select>
                </div>
              </div>
            {:else if sess.country == "GB"}
              <div class="row m-0 p-0">
                <div class="col-4 py-1 text-right">County:</div>
                <div class="col-8 py-1">
                  <select
                    class="w-100 mb-1 px-1"
                    bind:value={sess.state}
                    on:change={(event) => updateState(event)}
                  >
                    <option value="">- Select County -</option>
                    {#each gbStates as st}
                      <option value={st.name}>{st.region} - {st.name}</option>
                    {/each}
                  </select>
                </div>
              </div>
            {:else if sess.country == "JP"}
              <div class="row m-0 p-0">
                <div class="col-4 py-1 text-right">County:</div>
                <div class="col-8 py-1">
                  <select
                    class="w-100 mb-1 px-1"
                    bind:value={sess.state}
                    on:change={(event) => updateState(event)}
                  >
                    <option value="">- Select County -</option>
                    {#each jpStates as st}
                      <option value={st.name}>{st.name}</option>
                    {/each}
                  </select>
                </div>
              </div>
            {:else if sess.country == "CA"}
              <div class="row m-0 p-0">
                <div class="col-4 py-1 text-right">Province:</div>
                <div class="col-8 py-1">
                  <select
                    class="w-100 mb-1 px-1"
                    bind:value={sess.state}
                    on:change={(event) => updateState(event)}
                  >
                    <option value="">- Select Province -</option>
                    {#each caStates as st}
                      <option value={st.abbreviation}>{st.name}</option>
                    {/each}
                  </select>
                </div>
              </div>
            {/if}
          {/if}
          {#if shippingOptions.length > 0}
            <div class="row m-0 p-0">
              <div class="col-12 py-1 nkbglightergrey">
                <strong>Shipping Method</strong>
              </div>
            </div>
          {/if}
          {#each shippingOptions as option, ind}
            <div
              class="row m-0 p-0{shippingSelected &&
              option.ship_id == shippingSelected.ship_id
                ? ' NKc_LYellow'
                : ind % 2 == 0
                  ? ' NKc_FBlue'
                  : ' NKc_MBlue'}"
            >
              <div class="col-1 px-2 py-1 text-center">
                <input
                  type="radio"
                  name="shipping"
                  on:click={() => selectShipping(option)}
                  checked={shippingSelected &&
                    option.ship_id == shippingSelected.ship_id}
                />
              </div>
              <div
                class="col-6 px-2 py-1 small"
                style="padding-left:1em;text-indent:-1em;"
              >
                {@html "<b>" + option.Ship_By}
              </div>
              <div class="col-3 px-2 py-1 small text-right italic">
                {#if option.Shipping_Chrg < 0}
                  <strong
                    ><span class="nkred"
                      >Save {@html formatMoney(option.Base_Cost)}</span
                    ></strong
                  >
                {:else if option.Shipping_Chrg == 0}
                  <strong><span class="nkred">Free</span></strong>
                {:else}
                  {#if option.Base_Cost > 0}
                    {@html formatMoney(option.Base_Cost)}<br />
                  {/if}
                  {#if option.Comic_Cost > 0 && regulars + freebies > 0}
                    {#if option.Base_Cost > 0}&nbsp;+&nbsp;{/if}
                    {@html formatMoney(option.Comic_Cost)}/bk<br />
                  {/if}
                  {#if option.Cheap_Cost > 0 && cheaps > 0 && option.Ship_Code != "FedexIntP"}
                    {#if option.Base_Cost > 0 || (option.Comic_Cost > 0 && regulars + freebies > 0)}&nbsp;+&nbsp;{/if}
                    {@html formatMoney(option.Cheap_Cost)} per gift set or price
                    guide<br />
                  {/if}
                {/if}
              </div>
              <div
                class="col-2 px-2 py-1 small text-right{shippingSelected &&
                option.ship_id == shippingSelected.ship_id
                  ? ' strong'
                  : ' nklightgrey'}"
              >
                {@html formatMoney(option.Shipping_Chrg)}
              </div>
            </div>
          {/each}
        {/if}
        {#if totalItems > 0 && (regulars + cheaps + freebies == 0 || shippingSelected) && (selectedAddress || (sess.country && sess.country != "" && ((sess.country != "US" && sess.country != "AU" && sess.country != "ES" && sess.country != "GB" && sess.country != "JP" && sess.country != "CA") || (sess.state && sess.state != ""))))}
          <div class="row m-0 p-0">
            <div class="col-12 m-0 p-2 pt-3 text-center">
              <button
                class="btn btn-primary border-0 nkbgred white"
                style="width:calc(100% - 1.5rem);"
                on:click|preventDefault={() => goTo("checkout")}
                >Checkout Securely</button
              >
            </div>
          </div>
        {:else}
          <div class="row m-0 p-0">
            <div class="col-12 m-0 p-2 pt-3 text-center">
              <p style="font-size:150%;">
                Select a &quot;Ship to&quot; location above to continue
                checkout.
              </p>
            </div>
          </div>
        {/if}
      {/if}
    {:else}
      <div class="row m-0 p-0">
        <div class="col-12 m-0 px-1 py-4 text-center">
          <em>Your shopping cart is empty,<br />but full of possibility</em>
        </div>
      </div>
    {/if}
  </div>
</div>
