<script>
  import { onMount } from "svelte";
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import {
    countries,
    usStates,
    auStates,
    esStates,
    gbStates,
    jpStates,
    caStates,
  } from "../shared/CountriesStates.js";
  import Login from "./Login.svelte";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import {
    faExclamationTriangle,
    faMoneyCheckEditAlt,
  } from "@fortawesome/pro-solid-svg-icons";
  import { faCreditCard } from "@fortawesome/pro-duotone-svg-icons";
  import { faCcPaypal } from "@fortawesome/free-brands-svg-icons";
  import { faMoneyCheckAlt } from "@fortawesome/pro-regular-svg-icons";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let crt;
  const cartUnsubscribe = cart.subscribe((value) => {
    crt = value;
  });

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  let isWorking = true;

  let totalItems = 0;
  let bookCount = 0;
  let framedCount = 0;
  let regulars = 0;
  let regularsTotal = 0.0;
  let cheaps = 0;
  let cheapsTotal = 0;
  let freebies = 0;
  let primeTotal = 0;
  let giftCerts = 0;
  let pGiftCerts = 0;
  let pGiftCertsTotal = 0.0;
  let eGiftCerts = 0;
  let eGiftCertsTotal = 0.0;
  let coupons = 0;
  let couponType = "";
  let totalCouponDisc = 0.0;
  let couponDiscPercent = 0.0;
  let couponDiscSubTotal = 0.0;
  let totalDollarCouponDisc = 0.0;
  let dollarCouponDiscPercent = 0.0;
  let dollarCouponDiscSubTotal = 0.0;
  let subTotalTaxable = 0.0;
  let subTotalNonTaxable = 0.0;
  let subTotal = 0.0;
  let totalDisc = 0.0;
  let discPercent = 0.0;
  let discSubTotal = 0.0;
  let giftCreditsAmount = 0.0;
  let tax = "TBD";
  let shipping = "TBD";
  let total = "TBD";
  function reCalculateCart() {
    totalItems = 0;
    bookCount = 0;
    framedCount = 0;
    regulars = 0;
    regularsTotal = 0.0;
    cheaps = 0;
    cheapsTotal = 0;
    freebies = 0;
    primeTotal = 0;
    giftCerts = 0;
    pGiftCerts = 0;
    pGiftCertsTotal = 0.0;
    eGiftCerts = 0;
    eGiftCertsTotal = 0.0;
    coupons = 0;
    couponType = "";
    totalCouponDisc = 0.0;
    couponDiscPercent = 0.0;
    couponDiscSubTotal = 0.0;
    totalDollarCouponDisc = 0.0;
    dollarCouponDiscPercent = 0.0;
    dollarCouponDiscSubTotal = 0.0;
    subTotalTaxable = 0.0;
    subTotalNonTaxable = 0.0;
    subTotal = 0.0;
    totalDisc = 0.0;
    discPercent = 0.0;
    discSubTotal = 0.0;
    giftCreditsAmount = 0.0;
    tax = "TBD";
    shipping = "TBD";
    total = "TBD";
    if (crt && Array.isArray(crt)) {
      crt.forEach((element) => {
        if (element.Status != "X") {
          if (element.Typ == 0) {
            // regular comics
            regulars++;
            regularsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 10) {
            // bulk/shirts
            cheaps++;
            cheapsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 20) {
            // free comics
            freebies++;
          } else if (element.Typ == 50) {
            // paper gift certs
            pGiftCerts++;
            pGiftCertsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 55) {
            // email gift certs
            eGiftCerts++;
            eGiftCertsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 70 || element.Typ == 75) {
            // prime
            primeTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 140) {
            // $ discount coupons
            coupons++;
            couponType = "$";
            totalCouponDisc =
              parseFloat(element.Unit_Price) <= 0
                ? parseFloat(element.Unit_Price)
                : parseFloat(element.Unit_Price) * -1;
            totalDisc += totalCouponDisc;
          } else if (element.Typ == 141) {
            // $ off entire order coupons
            coupons++;
            couponType = "$off";
            totalDollarCouponDisc =
              parseFloat(element.Quoted_Price) <= 0
                ? parseFloat(element.Quoted_Price)
                : parseFloat(element.Quoted_Price) * -1;
            totalDisc += totalDollarCouponDisc;
          } else if (element.Typ == 145) {
            // % discount coupons
            coupons++;
            couponType = "%";
            totalCouponDisc =
              parseFloat(element.Quoted_Price) <= 0
                ? parseFloat(element.Quoted_Price)
                : parseFloat(element.Quoted_Price) * -1;
            totalDisc += totalCouponDisc;
          } else if (element.Typ == 150) {
            // gift credits
            giftCreditsAmount += parseFloat(element.Unit_Price);
          }
        }
        if (/framed and matted/gi.test(element.Comic_Name)) framedCount++;
      });
      bookCount = regulars + cheaps + freebies;
      giftCerts = pGiftCerts + eGiftCerts;
      totalItems = bookCount + giftCerts + (primeTotal > 0 ? 1 : 0);
      subTotalTaxable = regularsTotal + cheapsTotal;
      subTotalNonTaxable = pGiftCertsTotal + eGiftCertsTotal + primeTotal;
      subTotal = subTotalTaxable + subTotalNonTaxable;
      if (bookCount == 0 && pGiftCerts == 0 && eGiftCerts > 0) shipping = 0.0;
      else if (sess.shippingSelected)
        shipping = parseFloat(sess.shippingSelected.Shipping_Chrg);
      couponDiscSubTotal = subTotal + totalCouponDisc;
      if (subTotal > 0)
        couponDiscPercent =
          1 -
          (couponDiscSubTotal - eGiftCertsTotal) / (subTotal - eGiftCertsTotal);
      dollarCouponDiscSubTotal = subTotal + totalDollarCouponDisc;
      if (subTotal > 0)
        dollarCouponDiscPercent = 1 - dollarCouponDiscSubTotal / subTotal;
      discSubTotal = subTotal + totalDisc;
      if (subTotal > 0)
        discPercent =
          1 - (discSubTotal - eGiftCertsTotal) / (subTotal - eGiftCertsTotal);
      if (discSubTotal < 0) {
        if (totalDisc + subTotal + shipping <= 0)
          totalDisc = -1 * (subTotal + shipping);
        discSubTotal = 0;
        discPercent = 1.0;
      }
      if (taxKey != "" && taxable[taxKey] && subTotalTaxable > 0) {
        if (subTotalNonTaxable > 0)
          tax = subTotalTaxable * (1 - discPercent) * taxable[taxKey];
        else tax = discSubTotal * taxable[taxKey];
      } else tax = 0.0;
      total =
        subTotal +
        (sess.shippingSelected ? shipping : 0) +
        (tax != "TBD" ? tax : 0) +
        totalDisc;
      if (giftCreditsAmount > 0)
        total = total - giftCreditsAmount > 0 ? total - giftCreditsAmount : 0;
    }
  }

  let taxable = {};
  let taxKey = "";
  async function getTaxes() {
    let options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "adm_options/taxes", options)
      .then((res) => res.json())
      .then((res) => {
        taxable = res;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }
  function updateTaxKey() {
    if (sess.country && sess.country != "") {
      taxKey = sess.country;
      if (["US", "AU", "ES", "CA"].indexOf(sess.country) > -1)
        taxKey += sess.state;
      else if (sess.state && sess.state != "")
        taxKey += sess.state.replaceAll(" ", "");
      reCalculateCart();
    }
  }

  function updateGiftMsg(ev) {
    session.update((n) => {
      return { ...n, giftMessage: ev.target.value };
    });
  }

  let address = {
    Name: sess.selectedAddress ? sess.selectedAddress.Name : "",
    Company: sess.selectedAddress ? sess.selectedAddress.Company : "",
    Addr_1: sess.selectedAddress ? sess.selectedAddress.Addr_1 : "",
    Suite: sess.selectedAddress ? sess.selectedAddress.Suite : "",
    City: sess.selectedAddress ? sess.selectedAddress.City : "",
    State: sess.selectedAddress ? sess.selectedAddress.State : sess.state,
    ZIP_1: sess.selectedAddress ? sess.selectedAddress.ZIP_1 : "",
    Country: sess.selectedAddress ? sess.selectedAddress.Country : sess.country,
    Phone: sess.selectedAddress ? sess.selectedAddress.Phone : "",
    Instr: sess.selectedAddress ? sess.selectedAddress.Instr : "",
  };
  let addressError = {};

  function updatePaymentType(ev) {
    session.update((n) => {
      return { ...n, paymentType: ev.target.value };
    });
  }

  let checkoutReady = false;
  $: {
    if (
      usr &&
      usr.Cust_ID &&
      usr.Cust_ID > 0 &&
      (total == 0 || (total > 0 && sess.paymentType && sess.paymentType != ""))
    )
      checkoutReady = true;
  }

  let errMsg = "";
  async function goToReview() {
    isWorking = true;
    animateScroll.scrollToTop();
    if (bookCount > 0 || pGiftCerts > 0) {
      if (!sess.selectedAddress) {
        addressError = {};
        if (address.Name == "") addressError.Name = "Please enter name";
        if (address.Addr_1 == "")
          addressError.Addr_1 = "Please enter street address";
        if (address.City == "") addressError.City = "Please enter city";
        if (address.State == "")
          addressError.State = "Please select state/province";
        if (address.ZIP_1 == "")
          addressError.ZIP_1 = "Please enter zip/postal code";
        if (address.Country == "")
          addressError.Country = "Please select country";
        if (Object.keys(addressError).length > 0) isWorking = false;
        else {
          address.id = 0;
          address.Current = "Y";
          address.Cust_ID = usr.Cust_ID;
          let options = {
            method: "PUT",
            cache: "no-cache",
            headers: {
              Authorization: "Bearer " + sess.token,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify([address]),
          };
          const response = await fetch(app.apiUrl + "cust_addrs", options);
          const res = await response.json();
          session.update((n) => {
            return { ...n, selectedAddress: res[0] };
          });
        }
      }
    }
    if (!isWorking) errMsg = "Please correct errors before continuing";
    else goTo("orderreview");
  }

  onMount(async () => {
    if (crt && crt.length) {
      await getTaxes();
      updateTaxKey();
    }
    isWorking = false;
  });
</script>

{#if usr && usr.Cust_ID && usr.Cust_ID > 0}
  {#if errMsg != ""}
    <div class="modalNotification">
      <button
        type="button"
        class="close"
        aria-label="Close"
        style="margin-top:-0.5em;"
        on:click|preventDefault={() => {
          errMsg = "";
        }}><span aria-hidden="true">&times;</span></button
      >
      {@html errMsg}
    </div>
  {/if}
  <div class="row m-0 p-0">
    <div class="col-12 m-0 p-0" style="position:relative;">
      {#if isWorking}
        <div class="subshade" />
        <div class="subshadeloader">
          <div class="container my-1 py-1 text-center">
            <div class="spinner-grow text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow text-light" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      {/if}
      <div class="row m-0 p-0 border-bottom border-dark">
        <div class="col-12 m-0 p-0 text-center nkred">
          <h4 class="title">Checkout</h4>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-12 py-1 nkbglightergrey">
          <div class="d-inline-block align-middle">
            <strong>Shipping To</strong>
          </div>
        </div>
      </div>
      {#if bookCount > 0 || pGiftCerts > 0}
        <div class="row m-0 p-0">
          <div class="col-12 py-1">
            {#if sess.selectedAddress && sess.selectedAddress.id > 0}
              {sess.selectedAddress.Name}<br />
              {#if sess.selectedAddress.Company && sess.selectedAddress.Company != ""}{sess
                  .selectedAddress.Company}<br />{/if}
              {sess.selectedAddress.Addr_1}<br />
              {#if sess.selectedAddress.Suite && sess.selectedAddress.Suite != ""}{sess
                  .selectedAddress.Suite}<br />{/if}
              {sess.selectedAddress.City}, {sess.selectedAddress.State}
              {sess.selectedAddress.ZIP_1}
              {sess.selectedAddress.Country}
            {:else}
              <input
                type="text"
                class="w-100 mb-1 px-1 border-red"
                placeholder="Name"
                bind:value={address.Name}
              />
              {#if addressError.Name && addressError.Name != ""}<br /><span
                  class="nkred strong"
                  ><FontAwesomeIcon icon={faExclamationTriangle} />
                  {addressError.Name}</span
                ><br />{/if}
              <input
                type="text"
                class="w-100 mb-1 px-1"
                placeholder="Company"
                bind:value={address.Company}
              />
              <input
                type="text"
                class="w-100 mb-1 px-1 border-red"
                placeholder="Street or P.O. Box"
                bind:value={address.Addr_1}
              />
              {#if addressError.Addr_1 && addressError.Addr_1 != ""}<br /><span
                  class="nkred strong"
                  ><FontAwesomeIcon icon={faExclamationTriangle} />
                  {addressError.Addr_1}</span
                ><br />{/if}
              <input
                type="text"
                class="w-100 mb-1 px-1"
                placeholder="Apt, Suite, Unit or Bldg"
                bind:value={address.Suite}
              />
              <input
                type="text"
                class="w-100 mb-1 px-1 border-red"
                placeholder="City"
                bind:value={address.City}
              />
              {#if addressError.City && addressError.City != ""}<br /><span
                  class="nkred strong"
                  ><FontAwesomeIcon icon={faExclamationTriangle} />
                  {addressError.City}</span
                ><br />{/if}
              <input
                type="text"
                class="w-100 mb-1 px-1 border-red"
                placeholder="State / Province"
                bind:value={address.State}
                readonly
              />
              {#if addressError.State && addressError.State != ""}<br /><span
                  class="nkred strong"
                  ><FontAwesomeIcon icon={faExclamationTriangle} />
                  {addressError.State}</span
                ><br />{/if}
              <input
                type="text"
                class="w-100 mb-1 px-1 border-red"
                placeholder={address.Country == "US"
                  ? "5-Digit Zip Code"
                  : "Postal Code"}
                bind:value={address.ZIP_1}
              />
              {#if addressError.ZIP_1 && addressError.ZIP_1 != ""}<br /><span
                  class="nkred strong"
                  ><FontAwesomeIcon icon={faExclamationTriangle} />
                  {addressError.ZIP_1}</span
                ><br />{/if}
              <input
                type="text"
                class="w-100 mb-1 px-1 border-red"
                bind:value={address.Country}
                readonly
              />
              {#if addressError.Country && addressError.Country != ""}<br
                /><span class="nkred strong"
                  ><FontAwesomeIcon icon={faExclamationTriangle} />
                  {addressError.Country}</span
                ><br />{/if}
              <input
                type="text"
                class="w-100 mb-1 px-1"
                placeholder="Area Code + Phone (optional)"
                bind:value={address.Phone}
              />
              <textarea
                class="w-100 mb-1 px-1"
                placeholder="Delivery instructions (optional)"
                bind:value={address.Instr}
              />
            {/if}
          </div>
        </div>
      {/if}
      {#if eGiftCerts > 0}
        <div class="row m-0 p-0">
          <div class="col-12 py-1 pb-2 text-center italic">
            Email gift cards will be sent immediately upon payment
          </div>
        </div>
      {/if}
      <div class="row m-0 p-0">
        <div class="col-12 py-1 nkbglightergrey">
          <div class="d-inline-block align-middle">
            <strong>Payment</strong>
          </div>
        </div>
      </div>
      {#if total > 0}
        <div class="row m-0 p-0">
          <div class="col-12 py-1">
            <label for="pay_cc"
              ><input
                type="radio"
                name="paymentType"
                value="cc"
                on:click={(event) => updatePaymentType(event)}
                checked={sess.paymentType && sess.paymentType == "cc"}
              />
              <FontAwesomeIcon icon={faCreditCard} style="font-size:1.25em;" /> Credit
              Card</label
            >
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-12 py-1">
            <label for="pay_cc"
              ><input
                type="radio"
                name="paymentType"
                value="pp"
                on:click={(event) => updatePaymentType(event)}
                checked={sess.paymentType && sess.paymentType == "pp"}
              />
              <FontAwesomeIcon icon={faCcPaypal} style="font-size:1.25em;" /> PayPal</label
            >
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-12 py-1">
            <label for="pay_cc"
              ><input
                type="radio"
                name="paymentType"
                value="ck"
                on:click={(event) => updatePaymentType(event)}
                checked={sess.paymentType && sess.paymentType == "ck"}
              />
              <FontAwesomeIcon
                icon={faMoneyCheckEditAlt}
                style="font-size:1.25em;"
              /> Check</label
            >
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-12 py-1">
            <label for="pay_cc"
              ><input
                type="radio"
                name="paymentType"
                value="mo"
                on:click={(event) => updatePaymentType(event)}
                checked={sess.paymentType && sess.paymentType == "mo"}
              />
              <FontAwesomeIcon
                icon={faMoneyCheckAlt}
                style="font-size:1.25em;"
              /> Money Order</label
            >
          </div>
        </div>
      {:else}
        <div class="row m-0 p-0">
          <div class="col-12 py-1 pb-2 text-center italic">
            No additional payment required
          </div>
        </div>
      {/if}
      {#if bookCount > 0 || pGiftCerts > 0}
        <div class="row m-0 p-0">
          <div class="col-12 py-1 nkbglightergrey">
            <div class="d-inline-block align-middle">
              <strong>Gift Message (optional)</strong>
            </div>
          </div>
        </div>
        <div class="row m-0 p-0">
          <div class="col-12 py-1">
            <textarea
              bind:value={sess.giftMessage}
              on:change={(event) => updateGiftMsg(event)}
              rows="3"
              style="width:100%;"
            />
          </div>
        </div>
      {/if}
      {#if checkoutReady}
        <div class="row m-0 p-0">
          <div class="col-12 m-0 p-2 pt-3 text-center">
            <button
              class="btn btn-primary border-0 nkbgred white"
              style="width:calc(100% - 1.5rem);"
              on:click|preventDefault={goToReview}>Review Order</button
            >
          </div>
        </div>
      {/if}
    </div>
  </div>
{:else}
  <Login />
{/if}
