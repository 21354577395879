<script>
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import { faChevronCircleUp } from "@fortawesome/pro-solid-svg-icons";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let cartCount = 0;
  const cartUnsubscribe = cart.subscribe((value) => {
    cartCount = 0;
    if (value && Array.isArray(value))
      value.forEach((element) => {
        if ([0, 50, 55, 60].indexOf(element.Typ) > -1 && element.Status != "X")
          cartCount += element.Qty;
      });
  });

  async function getCart() {
    var options = {
      method: "GET",
      cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "cart/" + sess.currency + "/" + sess.id, options)
      .then((res) => res.json())
      .then((res) => {
        res.map((element) => {
          if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
            element.Ref = JSON.parse(element.Ref);
          else if (element.Ref != "" && element.Ref.indexOf("?Comics=") > -1) {
            var tmp = element.Ref.split("=");
            tmp = tmp[1].split("#");
            tmp = tmp[0].split("|");
            element.Ref = [tmp[0], "", tmp[1], "", ""];
          }
        });
        cart.update((n) => {
          return res;
        });
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  $: {
    const tok = sess.token;
    getCart();
  }

  function doLogout() {
    user.update((n) => {
      return {};
    });
    session.update((n) => {
      return {
        ...n,
        token: app.token,
        shippingSelected: null,
        selectedAddress: null,
        paymentType: null,
        lastOrderNum: null,
      };
    });
  }

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  let dt = new Date();
</script>

<div class="row m-0 mt-2 p-2 nkbglightgrey white">
  <div class="col-12">
    <p
      class="text-center m-0 cursorPointer"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => {
        animateScroll.scrollToTop();
        if (document.getElementById("titlesList"))
          animateScroll.scrollToTop({ container: "#titlesList" });
      }}
      on:keypress|preventDefault={() => {
        animateScroll.scrollToTop();
        if (document.getElementById("titlesList"))
          animateScroll.scrollToTop({ container: "#titlesList" });
      }}
    >
      <FontAwesomeIcon icon={faChevronCircleUp} /><br />
      Top of Page
    </p>
  </div>
</div>
<div class="row m-0 p-2 nkbgdarkgrey white">
  <div class="col-6">
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("cart")}
      on:keypress|preventDefault={() => goTo("cart")}
      >Cart{#if cartCount > 0}&nbsp;(<span class="nkyellow"
          ><strong>{cartCount}</strong></span
        >){/if}</a
    >
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("orders")}
      on:keypress|preventDefault={() => goTo("orders")}>Your Orders</a
    >
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("sales")}
      on:keypress|preventDefault={() => goTo("sales")}>Today's Sales</a
    >
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("shipping")}
      on:keypress|preventDefault={() => goTo("shipping")}>Shipping Info</a
    >
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("returns")}
      on:keypress|preventDefault={() => goTo("returns")}>Returns - Refunds</a
    >
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("privacy")}
      on:keypress|preventDefault={() => goTo("privacy")}
      >Security &amp; Privacy</a
    >
    {#if (sess.country == "US" || sess.country == "PR") && (!usr || !user.prime)}
      <a
        href={null}
        class="d-block my-2 white"
        role="button"
        tabindex="0"
        on:click|preventDefault={() => goTo("freeshipping")}
        on:keypress|preventDefault={() => goTo("freeshipping")}
        >Free USA Shipping</a
      >
    {/if}
  </div>
  <div class="col-6">
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("giftcards")}
      on:keypress|preventDefault={() => goTo("giftcards")}>Gift Cards</a
    >
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("newarrivals")}
      on:keypress|preventDefault={() => goTo("newarrivals")}>New Arrivals</a
    >
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("ninetypercent")}
      on:keypress|preventDefault={() => goTo("ninetypercent")}>90% off</a
    >
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("recommendations")}
      on:keypress|preventDefault={() => goTo("recommendations")}
      >Recommendations</a
    >
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("sellcomics")}
      on:keypress|preventDefault={() => goTo("sellcomics")}>Sell your comics</a
    >
    <a
      href={null}
      class="d-block my-2 white"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("wingiftcard")}
      on:keypress|preventDefault={() => goTo("wingiftcard")}>Win a Gift Card</a
    >
  </div>
</div>
<div class="row m-0 p-2 pt-0 nkbgdarkgrey white">
  <div class="col-12">
    <hr class="w-75 mt-0 mb-2" style="border-color:#FFF;" />
    <p class="text-center mx-0 my-1">
      <a href="https://newkadia.com/?manual=1" class="nkyellow"
        >Desktop version</a
      >
    </p>
  </div>
</div>
<div class="row m-0 p-2 bgblack white">
  <div class="col-12">
    {#if usr && usr.Cust_ID && usr.Cust_ID > 0}
      <p class="text-center m-0">
        <a
          href={null}
          class="d-inline white mr-2"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goTo("account")}
          on:keypress|preventDefault={() => goTo("account")}>My Account</a
        >
        <a
          href={null}
          class="d-inline white"
          role="button"
          tabindex="0"
          on:click|preventDefault={doLogout}
          on:keypress|preventDefault={doLogout}>Sign Out</a
        >
      </p>
    {:else}
      <p class="text-center m-0">
        <a
          href={null}
          class="white"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goTo("account")}
          on:keypress|preventDefault={() => goTo("account")}
          >Shopped here before?&nbsp;&nbsp;&nbsp;&nbsp;Sign In</a
        >
      </p>
    {/if}
    <p class="text-center m-0 mt-1">
      <small>&copy; {dt.getFullYear()} NewKadia.com, Inc.</small>
    </p>
  </div>
</div>
