<script>
  import { onMount } from "svelte";
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  let isWorking = true;
  let monthsOfYear = [
    ["January", "Jan"],
    ["February", "Feb"],
    ["March", "Mar"],
    ["April", "Apr"],
    ["May", "May"],
    ["June", "Jun"],
    ["July", "Jul"],
    ["August", "Aug"],
    ["September", "Sep"],
    ["October", "Oct"],
    ["November", "Nov"],
    ["December", "Dec"],
  ];

  let settings = "";
  async function getWinners() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "adm_options/giftCardWinners", options)
      .then((res) => res.json())
      .then((res) => {
        var today = new Date();
        for (var i = 0; i < res.length; i++) {
          if (res[i][0] == monthsOfYear[today.getMonth()][0]) {
            settings = res[i][1][today.getDate() - 1];
            break;
          }
        }
        isWorking = false;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  let winner = false;
  let rules = false;
  let today = new Date();

  onMount(async () => {
    await getWinners();
  });
</script>

{#if winner || rules}
  <div
    class="shade"
    role="button"
    tabindex="0"
    on:click|preventDefault={() => {
      winner = false;
      rules = false;
    }}
    on:keypress|preventDefault={() => {
      winner = false;
      rules = false;
    }}
  />
  <div class="informationModal">
    <button
      type="button"
      class="close"
      aria-label="Close"
      style="margin:-0.5em -0.25em 0 0;"
      on:click|preventDefault={() => {
        winner = false;
        rules = false;
      }}><span aria-hidden="true">&times;</span></button
    >
    <div
      class="container m-0 p-0"
      style="width:85vw;max-height:90vh;overflow-y:scroll;"
    >
      {#if winner}
        <h5 class="nkred m-0 mb-3">Claiming a Gift Card</h5>
        <p class="indent">
          If your name is listed as a winner, send an email to <a
            href="mailto:CardPromotion@NewKadia.com"
            >CardPromotion@NewKadia.com</a
          > by the deadline listed. In the subject line type: &quot;I'm a Gift Card
          Winner&quot;
        </p>
        <div class="row m-0 p-0">
          <div class="col-8 m-0 p-1">
            <p class="indent m-0">
              You must send the email from the email address listed. You will
              receive your gift card via email within 7 days.
            </p>
          </div>
          <div class="col-4 m-0 p-1 pt-4">
            <div class="card">
              Gift Card
              <img
                src="https://www.imagesnk.com/A/LogoTrans98.png"
                alt="NewKadia"
              />
              <div
                style="font-size:0.8em;color:#4267b2;background-color:#ddd;border-radius:8px;width:95%;margin:0 auto;text-align:center;"
              >
                $100
              </div>
            </div>
          </div>
        </div>
      {:else if rules}
        <h5 class="nkred m-0 mb-3">How to Enter</h5>
        <p class="indent">
          If you have ever placed any order with us, you're already entered.
        </p>
        <p class="indent">
          If you've haven't placed an order, to enter: Send one email before
          Dec. 25, {today.getFullYear()} to
          <a href="mailto:GiftCardEntry@NewKadia.com"
            >GiftCardEntry@NewKadia.com</a
          >. In the email, type (A) your first and last name, and (B) the city
          where you live.
        </p>
        <p class="indent">
          If you send more than one email, all entries will be disqualified.
        </p>
        <h5 class="nkred m-0 mt-3">Official Rules for the Contest</h5>
        <p class="text-center m-0 mb-2" style="text-indent:0;">
          which runs Jan. 1 to Dec. 31, {today.getFullYear()}
        </p>
        <p class="strong" style="text-indent:0;">
          NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT IMPROVE
          YOUR CHANCES OF WINNING. YOU HAVE NOT YET WON. READ BELOW.
        </p>
        <p style="text-indent:0;">
          1.  NewKadia.com, Inc. will select, at random, 10 entries each day
          from Jan. 1, {today.getFullYear()} through Dec. 31, {today.getFullYear()}.
          Any person who has placed an order after July 31, 2005 ('the
          designated time') is automatically entered. NewKadia will display the
          first name and first initial of the entrant's last name along with the
          first four characters of each entrant's email address on the
          www.NewKadia.com website for one 24-hour period, starting at 12:01 am
          EST and ending at midnight the same day.
        </p>
        <p style="text-indent:0;">
          2.  The names listed each day can win a $100 Gift Card. If your name,
          address and email appears on our website as one of 'Today's Winners'
          you will win the Gift Card, but only if you claim it by (A) sending an
          email (the 'claim email') within 24 hours of the time your name and
          email first appear on our website to <a
            href="mailto:CardPromotion@NewKadia.com"
            >CardPromotion@NewKadia.com</a
          > and (B) the claim email is sent from the same email address as the email
          address that appeared on our website. Copy and paste the following into
          the subject line of the email, &quot;I'm a winner of a Gift Card&quot;.
        </p>
        <p style="text-indent:0;">
          3.  Anyone who wins a Gift Card will be sent the Gift Card within 7
          days of NewKadia receiving a properly completed claim email. Gift
          Cards will be sent to winners by email and to the same email address
          as their entry. No substitutions for lost Gift Cards. No prize
          substitutions. Only one winner per person or address. No purchase
          necessary.
        </p>
        <p style="text-indent:0;">
          4.  How to Enter  If you didn't order at NewKadia within the
          designated time, you can enter the contest. Send one email before Dec,
          25, {today.getFullYear()} to<br
          />CardPromotionEntryForm@NewKadia.com.<br />In the email type (A) your
          first name and last name, and (B) the city where you live. If you send
          more than one email, all of your entries will be disqualified.
        </p>
        <p style="text-indent:0;">
          5.  The chance of winning depends on the number of entrants. The
          number of entrants will be the number of email addresses of people who
          have placed an order at NewKadia.com during the designated time plus
          the number of valid entries received from non-customers. Unclaimed
          Gift Cards will not be awarded. Void where prohibited by law.
        </p>
        <p style="text-indent:0;">
          6.  Any gift card must be used at NewKadia.com through Dec. 31, {today.getFullYear()}.
          If you do not use it by that date, you forfeit it. You may not
          substitute cash or products for a Gift Card.
        </p>
        <p style="text-indent:0;">
          7.  Winners are responsible for taxes, if any, on the value of the
          Gift Card.
        </p>
        <p style="text-indent:0;">
          8.  By claiming a Gift Card, you agree to permit NewKadia to use your
          first name, first initial of your last name, your city of residence
          and first 4 characters of your email address for promotional and
          advertising purposes on a list or lists of winners publicly displayed.
        </p>
        <p style="text-indent:0;">
          9.  RELEASE AND LIMITATIONS OF LIABILITY. By participating in this
          Contest, you agree to release and hold harmless NewKadia.com, Inc.,
          its officers, directors, employees, and agents (collectively, the
          'Released Parties') from and against any claim or cause of action
          arising out of participation in the Contest or receipt or use of any
          prize, including, but not limited to: (a) unauthorized human
          intervention in the Drawings; (b) technical errors related to
          computers, servers, providers or telephone or network lines; (c)
          errors in the administration of the Contest or the processing of
          entries; or (d) injury or damage to persons or property which may be
          caused, directly or indirectly, in whole or in part, from your
          participation in the Contest or receipt of any prize. You also agree
          that in any cause of action, the Released Parties' liability will be
          limited to the cost of entering and participating in the Contest, and
          in no event shall the Released Parties be liable for attorneys' fees.
          You waive the right to claim any damages whatsoever, including, but
          not limited to, punitive, consequential, direct or indirect damages.
          Except where prohibited, entrant agrees that any and all disputes,
          claims and causes of action arising out of or connected with this
          Contest or any prize awarded shall be resolved under the laws of
          Pennsylvania, without respect to any conflict of law issues, and
          entrant agrees that such shall be resolved individually, without
          resort to any form of class action, and exclusively by the U.S.
          Federal and State Courts of Philadelphia, Pennsylvania.
        </p>
        <p style="text-indent:0;">
          10. If for any reason your entry is erroneously deleted, lost or
          otherwise destroyed or corrupted, your sole remedy is another entry in
          the Contest. We reserves the right in our sole discretion to
          disqualify any person it finds to be tampering with the entry process
          or the operation of the Contest or to be acting in violation of these
          Official Rules or in a disruptive manner. Our failure to enforce any
          term of these Official Rules is not a waiver of that provision.
        </p>
      {/if}
    </div>
  </div>
{/if}
<div class="row m-0 mb-3 p-0">
  <div class="col-12 m-0 p-0" style="position:relative;">
    {#if isWorking}
      <div class="subshade" />
      <div class="subshadeloader">
        <div class="container my-1 py-1 text-center">
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    {/if}
    <div class="row m-0 p-0 border-bottom border-dark">
      <div class="col-12 m-0 p-0 text-center nkred">
        <h4 class="title">Today's $100 Gift Card Winners</h4>
      </div>
    </div>
    <div class="row m-0 mb-1 p-1">
      <div class="col-12 m-0 mb-1 p-1">
        <div class="row m-0 p-0">
          <div class="col-4 m-0 p-1 pt-4">
            <div class="card">
              Gift Card
              <img
                src="https://www.imagesnk.com/A/LogoTrans98.png"
                alt="NewKadia"
              />
              <div
                style="font-size:0.8em;color:#4267b2;background-color:#ddd;border-radius:8px;width:95%;margin:0 auto;text-align:center;"
              >
                $100
              </div>
            </div>
          </div>
          <div class="col-8 m-0 p-1 strong">
            <p class="indent mb-0">
              If your name appears, <span
                class="nkblue strong"
                role="button"
                tabindex="0"
                on:click|preventDefault={() => {
                  winner = true;
                }}
                on:keypress|preventDefault={() => {
                  winner = true;
                }}>click here</span
              > to claim your Gift Card before 11:59PM Eastern USA time.
            </p>
            <p class="indent m-0">
              To enter or for the official rules, <span
                class="nkblue strong"
                role="button"
                tabindex="0"
                on:click|preventDefault={() => {
                  rules = true;
                }}
                on:keypress|preventDefault={() => {
                  rules = true;
                }}>click here</span
              >.
            </p>
          </div>
        </div>
        {@html settings}
        <p class="m-1 mt-2 p-0 text-center strong" style="text-indent:0;">
          Names abbreviated to protect your privacy.
        </p>
        <hr />
        <p class="mt-0 larger120" style="text-indent:0;">
          <span class="strong nkred">3,650 chances to win;</span> 10 each day<br
          />Check again tomorrow!
        </p>
        <p class="larger120" style="text-indent:0;">
          <span class="strong nkred">Save 25% to 80% every day</span><br />
          New sales every 24 hours!<br />
          <span
            class="nkblue strong"
            role="button"
            tabindex="0"
            on:click|preventDefault={() => goTo("sales")}
            on:keypress|preventDefault={() => goTo("sales")}>Click</span
          > for today's sales.
        </p>
        <img
          src="https://www.newkadia.com/A/NewKadia20-200x366.png"
          alt="NewKadia.com - 20 years online, 2000-2020"
          style="display:block;margin:1em auto;width:90%;"
        />
      </div>
    </div>
  </div>
</div>
