<script>
  import {
    application,
    history,
    session,
    cart,
    user,
  } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { conditions } from "../shared/Conditions.js";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
  import {
    faSearchPlus,
    faCameraAlt,
  } from "@fortawesome/pro-duotone-svg-icons";
  import {
    faUsdCircle,
    faInfoCircle,
  } from "@fortawesome/pro-regular-svg-icons";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let hist;
  const histUnsubcribe = history.subscribe((value) => {
    hist = value;
  });
  function rewindHistory() {
    if (hist.length > 0) {
      var hs = hist;
      hs.pop();
      var lastPage = hs.pop();
      history.update((h) => {
        return hs;
      });
      application.update((n) => {
        return {
          ...n,
          screen: lastPage.screen,
          screenmode: lastPage.screenmode,
          screencriteria: lastPage.screencriteria,
          screensubcriteria: lastPage.screensubcriteria,
          letternav: sess.lastSubNavLetter,
        };
      });
    }
  }

  let title = {};
  let subseries = [];
  let issuenumbers = [];
  let issuenav = [];
  let page = 1;
  let pages = 1;
  let perPage = 50;
  let issues = [];
  let outOfStock = {};
  let coverImage = "";
  let coverScan = "";
  let info = "";
  let overstreet = "";
  let rating = "";
  let loadingTimeout = null;
  let scrollTo = null;

  function getTitle() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    fetch(app.apiUrl + "titles/" + app.screenmode, options)
      .then((res) => res.json())
      .then((res) => {
        title = res;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  function getSubseries() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    fetch(app.apiUrl + "issuespage/subseries/" + app.screenmode, options)
      .then((res) => res.json())
      .then((res) => {
        subseries = res;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  function getIssuenumbers() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    fetch(
      app.apiUrl +
        "issuespage/issuenumbers/" +
        app.screenmode +
        (app.screencriteria != "" ? "/" + app.screencriteria : ""),
      options
    )
      .then((res) => res.json())
      .then((res) => {
        issuenumbers = res;
        outOfStock = {};
        let out = [];
        const uniqueIssueNums = [
          ...new Set(issuenumbers.map((item) => item.Issue_Num)),
        ];
        for (var i = 0; i < uniqueIssueNums.length; i++) {
          if (app.screencriteria == "Other Issues" || uniqueIssueNums[i] > 0) {
            if (
              issuenumbers.filter(
                (item) =>
                  item.Issue_Num == uniqueIssueNums[i] && item.instock > 0
              ).length > 0
            ) {
              if (out.length) {
                outOfStock[out[0]] = out;
                out = [];
              }
            } else out.push(uniqueIssueNums[i]);
          }
        }
        if (out.length) outOfStock[out[0]] = out;
        issuenav = [];
        var start = 0;
        for (var i = 0; i < issuenumbers.length; i++) {
          if (
            app.screencriteria == "Other Issues" ||
            issuenumbers[i].Issue_Num > 0
          ) {
            start = issuenumbers[i].Issue_Num;
            break;
          }
        }
        var end = issuenumbers[issuenumbers.length - 1].Issue_Num;
        var numberOfNumbers = end - start + 1;
        var step = 1;
        if (numberOfNumbers <= 25) step = 1;
        else if (numberOfNumbers <= 50) step = 2;
        else if (numberOfNumbers <= 100) step = 5;
        else step = Math.ceil(numberOfNumbers / 25 / 5) * 5;
        for (var i = start; i <= end; i += step) issuenav.push(i);
        pages = Math.ceil(numberOfNumbers / perPage);
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  function isLoadingVisible() {
    var el = document.getElementById("loading");
    if (el != null) {
      var bounding = el.getBoundingClientRect();
      if (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.right <=
          (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      )
        return true;
    }
    return false;
  }

  function getIssues(first) {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    var start = issuenav[0] - 1;
    var thisPageMin = page == 1 ? start : start + ((page - 1) * perPage + 1);
    var thisPageMax = start + page * perPage;
    fetch(
      app.apiUrl +
        "issuespage/" +
        app.screenmode +
        "/" +
        sess.currency +
        "/" +
        thisPageMin +
        "/" +
        thisPageMax +
        "/" +
        app.screencriteria,
      options
    )
      .then((res) => res.json())
      .then((res) => {
        if (first) issues = res;
        else issues = [...issues, ...res];
        if (page < pages) {
          clearTimeout(loadingTimeout);
          loadingTimeout = setTimeout(function () {
            if (isLoadingVisible()) getNextPage();
          }, 500);
        }
        if (
          scrollTo != null &&
          scrollTo <= issuenumbers[0].Issue_Num + page * perPage
        ) {
          isWorking = false;
          setTimeout(function () {
            doScrollToIssue(scrollTo);
            scrollTo = null;
          }, 250);
        } else if (scrollTo != null) {
          isWorking = true;
          getNextPage();
        }
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  function getNextPage() {
    if (page < pages) {
      page++;
      getIssues(false);
    }
  }

  const observer = new IntersectionObserver(onIntersect);
  function lazyLoad(node) {
    observer.observe(node);
    return {
      destroy() {
        observer.unobserve(node);
      },
    };
  }
  function onIntersect(entries) {
    if (issues.length && entries[0].isIntersecting) getNextPage();
  }

  function doScrollToIssue(num) {
    var el = document.querySelectorAll("li.in" + num);
    while (el.length == 0 && num > 0) {
      num--;
      el = document.querySelectorAll("li.in" + num);
    }
    if (el.length)
      animateScroll.scrollTo({
        container: "div#issuesList",
        element: "li.in" + num,
      });
  }

  function scrollToNumber(ev, num) {
    if (num <= issuenumbers[0].Issue_Num + page * perPage) doScrollToIssue(num);
    else {
      isWorking = true;
      scrollTo = num;
      getNextPage();
    }
  }

  function picImage(img, size) {
    if (img != "") {
      img = JSON.parse(img);
      if (img) {
        switch (size.toLowerCase()) {
          case "l":
            if (img.L && img.L != "") return img.L;
            else if (img.S && img.S != "") return img.S;
            break;
          case "s":
          default:
            if (img.S && img.S != "") return img.S;
            else if (img.L && img.L != "") return img.L;
            break;
        }
      }
    }
    return "";
  }

  function formatMoney(number, decPlaces, decSep, thouSep) {
    (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
      (decSep = typeof decSep === "undefined" ? "." : decSep);
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    if (sess.currency == "USD") sign = "$" + sign;
    var i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : "") +
      (sess.currency != "USD" ? "<small>" + sess.currency + "</small>" : "")
    );
  }

  function deDupeRatings(instock) {
    var out = [];
    var added = [];
    for (var i = 0; i < instock.length; i++) {
      if (added.indexOf(instock[i].Rating_Text) == -1) {
        out.push(instock[i]);
        added.push(instock[i].Rating_Text);
      }
    }
    return out;
  }

  $: {
    const tsKey = app.screenmode;
    page = 1;
    pages = 1;
    getTitle();
    getSubseries();
  }
  $: {
    const subSeries = app.screencriteria;
    page = 1;
    pages = 1;
    issuenumbers = [];
    outOfStock = {};
    issuenav = [];
    issues = [];
    getIssuenumbers();
  }
  $: {
    const tmp = issuenav.length ? issuenav[0] : -999;
    if (tmp > -999) {
      getIssues(true);
      if (
        app.screensubcriteria &&
        app.screensubcriteria != "" &&
        app.screensubcriteria > 0
      )
        scrollTo = app.screensubcriteria;
    }
  }

  let addError = "";
  let isWorking = false;
  let isAdding = false;
  async function addToCart(ev) {
    ev.target.blur();
    if (!isAdding) {
      isAdding = true;
      var stockNumbers = [];
      Array.from(document.querySelectorAll("input.issue:checked")).map(
        (element) => stockNumbers.push(element.value)
      );
      Array.from(document.querySelectorAll("input.issue")).map(
        (element) => (element.disabled = true)
      );
      if (stockNumbers.length) {
        document.getElementById("dinger").volume = 0;
        document.getElementById("dinger").play();
        var options = {
          method: "POST",
          cache: "no-cache",
          headers: {
            Authorization: "Bearer " + sess.token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currency: sess.currency,
            sessionid: sess.id,
            stocknumbers: stockNumbers,
          }),
        };
        await fetch(app.apiUrl + "cart/add", options)
          .then((res) => res.json())
          .then((res) => {
            res.cart.map((element) => {
              if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
                element.Ref = JSON.parse(element.Ref);
              else if (
                element.Ref != "" &&
                element.Ref.indexOf("?Comics=") > -1
              ) {
                var tmp = element.Ref.split("=");
                tmp = tmp[1].split("#");
                tmp = tmp[0].split("|");
                element.Ref = [tmp[0], "", tmp[1], "", ""];
              }
            });
            cart.update((n) => {
              return res.cart;
            });
            Array.from(document.querySelectorAll("input.issue")).map(
              (element) => (element.disabled = false)
            );
            Array.from(document.querySelectorAll("input.issue:checked")).map(
              (element) => (element.checked = false)
            );
            for (var i = 0; i < res.cart.length; i++) {
              outer: for (var j = 0; j < issues.length; j++) {
                for (var k = 0; k < issues[j].instock.length; k++) {
                  if (
                    issues[j].instock[k].Stock_Number ==
                    res.cart[i].Stock_Number
                  ) {
                    issues[j].instock.splice(k, 1);
                    break outer;
                  }
                }
              }
            }
            var msg = "";
            Object.keys(res.notcarted).forEach(function (key) {
              outer: for (var j = 0; j < issues.length; j++) {
                for (var k = 0; k < issues[j].instock.length; k++) {
                  if (issues[j].instock[k].Stock_Number == key) {
                    issues[j].instock.splice(k, 1);
                    msg += res.notcarted[key] + "<br/>";
                    break outer;
                  }
                }
              }
            });
            if (msg != "") {
              if (res.notcarted.length > 1)
                addError =
                  "Sorry, these copies were bought seconds ago:<br/>" + msg;
              else
                addError =
                  "Sorry, this copy was bought seconds ago:<br/>" + msg;
            }
            getIssuenumbers();
            issues = issues;
            if (
              navigator.vibrate ||
              navigator.webkitVibrate ||
              navigator.mozVibrate ||
              navigator.msVibrate
            )
              navigator.vibrate(200);
            else {
              document.getElementById("dinger").volume = 1;
              document.getElementById("dinger").play();
            }
            isAdding = false;
          })
          .catch((err) => {
            /*console.log(err)*/
          });
      } else {
        isAdding = false;
        addError = "Click box next to each one you want";
      }
    }
  }

  let showCouponDetails = false;
</script>

{#if addError != ""}
  <div class="modalNotification">
    <button
      type="button"
      class="close"
      aria-label="Close"
      style="margin-top:-0.5em;"
      on:click|preventDefault={() => {
        addError = "";
      }}><span aria-hidden="true">&times;</span></button
    >
    {@html addError}
  </div>
{/if}
{#if coverScan != "" || coverImage != "" || info != "" || overstreet != "" || rating != "" || showCouponDetails}
  <div
    class="shade"
    role="button"
    tabindex="0"
    on:click|preventDefault={() => {
      coverScan = "";
      coverImage = "";
      info = "";
      overstreet = "";
      rating = "";
      showCouponDetails = false;
    }}
    on:keypress|preventDefault={() => {
      coverScan = "";
      coverImage = "";
      info = "";
      overstreet = "";
      rating = "";
      showCouponDetails = false;
    }}
  />
  <div class="informationModal">
    <button
      type="button"
      class="close"
      aria-label="Close"
      style="margin:-0.5em -0.25em 0 0;"
      on:click|preventDefault={() => {
        coverScan = "";
        coverImage = "";
        info = "";
        overstreet = "";
        rating = "";
        showCouponDetails = false;
      }}><span aria-hidden="true">&times;</span></button
    >
    {#if coverScan != ""}
      <p class="m-0 small text-center">
        <strong>Photo of the exact copy for sale.</strong> For a written description
        of this copy, click it's condition.
      </p>
      <img
        src={app.coverUrl + coverScan + "-FC.jpg"}
        alt="Comic cover"
        class="coverImage"
      />
    {:else if coverImage != ""}
      <p class="m-0 small text-center">
        <strong>This is not a scan of the copy for sale.</strong> For a detailed
        description of each copy, click it's condition.
      </p>
      <img src={app.imgUrl + coverImage} alt="Comic cover" class="coverImage" />
    {:else if info != ""}
      <p class="text-center">
        <strong class="large">Issue Information</strong>
      </p>
      <div class="container" style="width:75vw;">
        {#if info.Pub_Date && info.Pub_Date != ""}
          <div class="row">
            <div class="col-6"><strong>Published:</strong></div>
            <div class="col-6">{info.Pub_Date}</div>
          </div>
        {/if}
        {#if info.Cover_Price && info.Cover_Price != ""}
          <div class="row">
            <div class="col-6"><strong>Cover Price:</strong></div>
            <div class="col-6">{@html formatMoney(info.Cover_Price)}</div>
          </div>
        {/if}
        {#if info.Writers && info.Writers != ""}
          <div class="row">
            <div class="col-6"><strong>Writers:</strong></div>
            <div class="col-6">{info.Writers}</div>
          </div>
        {/if}
        {#if info.Story_Artists && info.Story_Artists != ""}
          <div class="row">
            <div class="col-6"><strong>Story Artists:</strong></div>
            <div class="col-6">{info.Story_Artists}</div>
          </div>
        {/if}
        {#if info.Cover_Artists && info.Cover_Artists != ""}
          <div class="row">
            <div class="col-6"><strong>Cover Artists:</strong></div>
            <div class="col-6">{info.Cover_Artists}</div>
          </div>
        {/if}
        {#if info.Plot_Summary && info.Plot_Summary != ""}
          <div class="row">
            <div class="col-12">
              <strong>Summary:</strong><br />
              {info.Plot_Summary}
            </div>
          </div>
        {/if}
      </div>
    {:else if overstreet != ""}
      <p class="text-center">
        <strong class="large">Overstreet<br />Guide Values</strong><br /><small
          >Overstreet doesn't price NM+;<br />NM+ prices are ours</small
        >
      </p>
      <div
        class="container border border-dark nkbglightblue"
        style="width:55vw;"
      >
        <div class="row">
          <div class="col-7">Near Mint +</div>
          <div class="col-5 text-right">
            {@html formatMoney(overstreet.Os_Price_NM_p)}
          </div>
        </div>
        <div class="row">
          <div class="col-7">Near Mint -</div>
          <div class="col-5 text-right">
            {@html formatMoney(overstreet.Os_Price_NM_m)}
          </div>
        </div>
        <div class="row">
          <div class="col-7">Very Fine</div>
          <div class="col-5 text-right">
            {@html formatMoney(overstreet.Os_Price_VF)}
          </div>
        </div>
        <div class="row">
          <div class="col-7">Fine</div>
          <div class="col-5 text-right">
            {@html formatMoney(overstreet.Os_Price_F)}
          </div>
        </div>
        <div class="row">
          <div class="col-7">Very Good</div>
          <div class="col-5 text-right">
            {@html formatMoney(overstreet.Os_Price_VG)}
          </div>
        </div>
        <div class="row">
          <div class="col-7">Good</div>
          <div class="col-5 text-right">
            {@html formatMoney(overstreet.Os_Price_G)}
          </div>
        </div>
      </div>
    {:else if rating != ""}
      <p class="text-center">
        <strong>This copy is in {rating.Rating_Text} condition</strong>
      </p>
      <div class="container border border-dark" style="width:80vw;">
        <div class="row border-bottom border-dark">
          <div class="col-5 py-1 border-right border-dark">
            <strong>Tears at staple?</strong>
          </div>
          <div class="col-7 py-1">
            {#if rating.NK_Rating.substring(0, 1) == 1}
              Cover detached
            {:else if rating.NK_Rating.substring(0, 1) == 2}
              2/3 spine split or missing
            {:else if rating.NK_Rating.substring(0, 1) == 3}
              1 staple detached
            {:else if rating.NK_Rating.substring(0, 1) == 4}
              1 staple loose OK
            {:else if rating.NK_Rating.substring(0, 1) == 5}
              Staples firm
            {:else if rating.NK_Rating.substring(0, 1) == 6}
              Stress, but staples very firm
            {:else if rating.NK_Rating.substring(0, 1) == 7}
              No, 1/16&quot; max
            {:else if rating.NK_Rating.substring(0, 1) == 8}
              Yes
            {/if}
          </div>
        </div>
        <div class="row border-bottom border-dark">
          <div class="col-5 py-1 border-right border-dark">
            <strong>Inside pages</strong>
          </div>
          <div class="col-7 py-1">
            {#if rating.NK_Rating.substring(1, 2) == 1}
              Brittle OK or pages missing
            {:else if rating.NK_Rating.substring(1, 2) == 2}
              Brittle &amp; coupons missing
            {:else if rating.NK_Rating.substring(1, 2) == 3}
              Brown &amp; moderate tears OK
            {:else if rating.NK_Rating.substring(1, 2) == 4}
              Brown &amp; minor tears OK
            {:else if rating.NK_Rating.substring(1, 2) == 5}
              Yellow or tan
            {:else if rating.NK_Rating.substring(1, 2) == 6}
              Off-white, yellow or tan
            {:else if rating.NK_Rating.substring(1, 2) == 7}
              White
            {:else if rating.NK_Rating.substring(1, 2) == 8}
              White
            {/if}
          </div>
        </div>
        <div class="row border-bottom border-dark">
          <div class="col-5 py-1 border-right border-dark">
            <strong>Flat</strong>
          </div>
          <div class="col-7 py-1">
            {#if rating.NK_Rating.substring(2, 3) == 1}
              No
            {:else if rating.NK_Rating.substring(2, 3) == 2}
              No
            {:else if rating.NK_Rating.substring(2, 3) == 3}
              No
            {:else if rating.NK_Rating.substring(2, 3) == 4}
              Slight roll OK
            {:else if rating.NK_Rating.substring(2, 3) == 5}
              Bend OK
            {:else if rating.NK_Rating.substring(2, 3) == 6}
              Slight bend OK
            {:else if rating.NK_Rating.substring(2, 3) == 7}
              Yes
            {:else if rating.NK_Rating.substring(2, 3) == 8}
              Yes
            {/if}
          </div>
        </div>
        <div class="row border-bottom border-dark">
          <div class="col-5 py-1 border-right border-dark">
            <strong>Cover free of tears?</strong>
          </div>
          <div class="col-7 py-1">
            {#if rating.NK_Rating.substring(3, 4) == 1}
              1/3 + cover missing or severe rips
            {:else if rating.NK_Rating.substring(3, 4) == 2}
              Major tears up to 1/12<sup>th</sup> missing OK
            {:else if rating.NK_Rating.substring(3, 4) == 3}
              Minor tear or major crease
            {:else if rating.NK_Rating.substring(3, 4) == 4}
              Tiny tears, long crease or color chipping
            {:else if rating.NK_Rating.substring(3, 4) == 5}
              Yes, minor creases OK
            {:else if rating.NK_Rating.substring(3, 4) == 6}
              Yes, tiny indentations
            {:else if rating.NK_Rating.substring(3, 4) == 7}
              Yes
            {:else if rating.NK_Rating.substring(3, 4) == 8}
              Yes
            {/if}
          </div>
        </div>
        <div class="row border-bottom border-dark">
          <div class="col-5 py-1 border-right border-dark">
            <strong>Corners sharply cut?</strong>
          </div>
          <div class="col-7 py-1">
            {#if rating.NK_Rating.substring(4, 5) == 1}
              Corners+ missing OK
            {:else if rating.NK_Rating.substring(4, 5) == 2}
              Corners missing OK
            {:else if rating.NK_Rating.substring(4, 5) == 3}
              Major creases OK
            {:else if rating.NK_Rating.substring(4, 5) == 4}
              Medium crease or 1/2&quot; missing
            {:else if rating.NK_Rating.substring(4, 5) == 5}
              Minor crease
            {:else if rating.NK_Rating.substring(4, 5) == 6}
              1/4&quot; max crease
            {:else if rating.NK_Rating.substring(4, 5) == 7}
              Barely visible blunting
            {:else if rating.NK_Rating.substring(4, 5) == 8}
              Yes
            {/if}
          </div>
        </div>
        <div class="row border-bottom border-dark">
          <div class="col-5 py-1 border-right border-dark">
            <strong>Cover colors reflect?</strong>
          </div>
          <div class="col-7 py-1">
            {#if rating.NK_Rating.substring(5, 6) == 1}
              No
            {:else if rating.NK_Rating.substring(5, 6) == 2}
              No
            {:else if rating.NK_Rating.substring(5, 6) == 3}
              No
            {:else if rating.NK_Rating.substring(5, 6) == 4}
              Low or no
            {:else if rating.NK_Rating.substring(5, 6) == 5}
              Medium (fading)
            {:else if rating.NK_Rating.substring(5, 6) == 6}
              High to medium
            {:else if rating.NK_Rating.substring(5, 6) == 7}
              Yes
            {:else if rating.NK_Rating.substring(5, 6) == 8}
              Yes
            {/if}
          </div>
        </div>
        <div class="row">
          <div class="col-5 py-1 border-right border-dark">
            <strong>Cover clean?</strong>
          </div>
          <div class="col-7 py-1">
            {#if rating.NK_Rating.substring(6, 7) == 1}
              Severe stains / mildew
            {:else if rating.NK_Rating.substring(6, 7) == 2}
              No, very dirty
            {:else if rating.NK_Rating.substring(6, 7) == 3}
              No, dirty
            {:else if rating.NK_Rating.substring(6, 7) == 4}
              Medium wear; some ink / H<sub>2</sub>O
            {:else if rating.NK_Rating.substring(6, 7) == 5}
              Minor wear
            {:else if rating.NK_Rating.substring(6, 7) == 6}
              Yes
            {:else if rating.NK_Rating.substring(6, 7) == 7}
              Yes
            {:else if rating.NK_Rating.substring(6, 7) == 8}
              Yes
            {/if}
          </div>
        </div>
      </div>
    {:else if showCouponDetails}
      <p style="width:85vw;text-indent:0.5em;">
        Coupons are valid only for comics in stock over 60 days. Their prices
        appear in <span class="nkgreen strong">green</span>.
      </p>
      <p style="width:85vw;text-indent:0.5em;">
        Here's why: When coupons were valid anytime, competitors would scour our
        website every day for key issues, buy them with a coupon, and resell
        them at full price.
      </p>
      <p style="width:85vw;text-indent:0.5em;">
        But now, for 60 days, coupons are not valid so regular customers like
        you have 60 days to get these key issues.
      </p>
    {/if}
  </div>
{/if}
<div class="row m-0 p-0">
  <div class="col-12 m-0 p-0" style="position:relative;">
    {#if isWorking}
      <div class="subshade" />
      <div class="subshadeloader">
        <div class="container my-1 py-1 text-center">
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    {/if}
    <div class="row m-0 py-0 px-1 nkred">
      <div class="col-1 m-0 px-0 py-1 text-center">
        <div
          style="font-size:1.5em;padding-right:0.5em;"
          role="button"
          tabindex="0"
          on:click|preventDefault={rewindHistory}
          on:keypress|preventDefault={rewindHistory}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
      </div>
      <div class="col-11 m-0 p-0 text-center">
        <h4 class="title">
          {title && title.Title ? title.Title : ""}{@html title &&
          title.Series &&
          title.Series != ""
            ? " <small>" + title.Series + "</small>"
            : ""}
        </h4>
      </div>
    </div>
    <div class="row m-0 p-0 border-bottom border-dark">
      <div
        class="col-12 m-0 px-1 py-0 text-center nkred"
        style="position:relative;"
      >
        {#if subseries.filter((ss) => ss.instock > 0).length > 1}
          <select
            class="d-block mt-0 mb-1 mr-auto ml-auto small"
            bind:value={app.screencriteria}
          >
            {#each subseries as ss}
              {#if ss.instock > 0}
                <option value={ss.Sub_Series}
                  >{@html ss.Sub_Series != ""
                    ? ss.Sub_Series
                    : "Issues #" +
                      title.First_Issue_Number +
                      (title.Latest_Issue_Number > 0
                        ? " - " + title.Latest_Issue_Number
                        : " and up") +
                      " &mdash; Click here for special issues"}</option
                >
              {/if}
            {/each}
          </select>
        {/if}
        {#if issues.length > 0}
          <p
            class="m-0 text-center"
            role="button"
            tabindex="0"
            on:click|preventDefault={() => {
              showCouponDetails = !showCouponDetails;
            }}
            on:keypress|preventDefault={() => {
              showCouponDetails = !showCouponDetails;
            }}
          >
            <small
              ><span class="nkblue">Coupons valid</span>
              <span class="black">for comics priced in</span>
              <span class="nkgreen">green</span></small
            >
          </p>
        {/if}
      </div>
    </div>
    <div class="row m-0 p-0">
      <div
        class="{issuenumbers.length > 1 && app.screencriteria != 'Other Issues'
          ? 'col-10'
          : 'col-12'} m-0 p-0 overflow-auto"
        style="max-height:77vh;"
        id="issuesList"
      >
        <ul class="list-unstyled m-0 p-0">
          {#each issues as issue}
            {#if (issue.Issue_Num >= issuenav[0] || app.screencriteria == "Other Issues") && issue.instock.length > 0}
              <li
                class="row m-0 p-1 border-bottom border-dark in{issue.Issue_Num}"
              >
                <div class="col-4 m-0 p-1 text-center">
                  {#if issue.Cover_Image == null}
                    <div class="noCoverImage"><p>Cover Coming Soon</p></div>
                  {:else}
                    <img
                      class="smallCoverImg"
                      role="button"
                      tabindex="0"
                      on:click|preventDefault={() => {
                        coverImage = picImage(issue.Cover_Image, "l");
                      }}
                      on:keypress|preventDefault={() => {
                        coverImage = picImage(issue.Cover_Image, "l");
                      }}
                      src={app.imgUrl + picImage(issue.Cover_Image, "s")}
                      alt="{title && title.Title ? title.Title : ''}{title &&
                      title.Series &&
                      title.Series != ''
                        ? ' (' + title.Series + ')'
                        : ''}"
                    />
                    <div
                      class="py-1 mx-0 my-1 nkblue"
                      style="display:inline-block;padding-left:2px;padding-right:2px;"
                      role="button"
                      tabindex="0"
                      on:click|preventDefault={() => {
                        coverImage = picImage(issue.Cover_Image, "l");
                      }}
                      on:keypress|preventDefault={() => {
                        coverImage = picImage(issue.Cover_Image, "l");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSearchPlus}
                        style="font-size:1.5em;"
                      />
                    </div>
                  {/if}
                  {#if issue.Overstreet_ed != ""}
                    <div
                      class="py-1 mx-0 my-1 nkblue"
                      style="display:inline-block;padding-left:2px;padding-right:2px;"
                      role="button"
                      tabindex="0"
                      on:click|preventDefault={() => {
                        overstreet = issue;
                      }}
                      on:keypress|preventDefault={() => {
                        overstreet = issue;
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUsdCircle}
                        style="font-size:1.5em;"
                      />
                    </div>
                  {/if}
                  {#if (issue.Pub_Date && issue.Pub_Date != "") || (issue.Cover_Price && issue.Cover_Price != "") || (issue.Writers && issue.Writers != "") || (issue.Story_Artists && issue.Story_Artists != "") || (issue.Cover_Artists && issue.Cover_Artists != "") || (issues.Plot_Summary && issues.Plot_Summary != "")}
                    <div
                      class="py-1 mx-0 my-1 nkblue"
                      style="display:inline-block;padding-left:2px;padding-right:2px;"
                      role="button"
                      tabindex="0"
                      on:click|preventDefault={() => {
                        info = issue;
                      }}
                      on:keypress|preventDefault={() => {
                        info = issue;
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        style="font-size:1.5em;"
                      />
                    </div>
                  {/if}
                </div>
                <div class="col-8 m-0 p-1 pl-3">
                  <p class="mb-0">
                    <strong class="larger125">#{issue.Issue_Num}</strong>
                    {issue.Issue_Txt}
                    {issue.Printing}
                  </p>
                  <table
                    border="0"
                    cellpadding="2"
                    cellspacing="1"
                    width="100%"
                    class="nkblue large"
                  >
                    {#each deDupeRatings(issue.instock) as comic}
                      <tr>
                        <td width="28" align="left"
                          ><input
                            type="checkbox"
                            class="issue"
                            value={comic.Stock_Number}
                          /></td
                        >
                        <td
                          width="60"
                          class="text-nowrap"
                          role="button"
                          tabindex="0"
                          on:click|preventDefault={() => {
                            rating = comic;
                          }}
                          on:keypress|preventDefault={() => {
                            rating = comic;
                          }}
                        >
                          <div class="d-sm-none">
                            {conditions[comic.Rating_Text]}
                          </div>
                          <div class="d-none d-sm-inline">
                            {comic.Rating_Text}
                          </div>
                        </td>
                        <td width="22" align="center">
                          {#if comic.hasCoverScan == 1}
                            <div
                              role="button"
                              tabindex="0"
                              on:click|preventDefault={() => {
                                coverScan = comic.StockNumberNoConsignor;
                              }}
                              on:keypress|preventDefault={() => {
                                coverScan = comic.StockNumberNoConsignor;
                              }}
                            >
                              <FontAwesomeIcon icon={faCameraAlt} />
                            </div>
                          {:else}
                            <div style="width:17.609px;" />
                          {/if}
                        </td>
                        <td
                          class="text-right {comic.Days_Available > 60
                            ? 'nkgreen'
                            : 'black'}"
                          >{@html formatMoney(comic.Current_NK_Price)}</td
                        >
                      </tr>
                    {/each}
                  </table>
                  <button
                    class="btn btn-primary btn-sm border-darkishgrey nkbgred white d-block mt-2 mr-auto ml-auto{isAdding
                      ? ' btn-pulse-red'
                      : ''}"
                    style="border-width:2px;"
                    on:click|preventDefault={(event) => addToCart(event)}
                    >Add to Cart</button
                  >
                </div>
              </li>
            {:else if outOfStock[issue.Issue_Num] && issue.Issue_Txt == "" && issue.Printing == ""}
              <li
                class="row m-0 p-1 border-bottom border-dark in{issue.Issue_Num}"
              >
                <div class="col-4 m-0 py-0 px-1" />
                <div class="col-8 m-0 py-0 pr-1 pl-3" style="line-height:94%;">
                  {#if outOfStock[issue.Issue_Num].length > 1 && issue.Issue_Num != outOfStock[issue.Issue_Num][outOfStock[issue.Issue_Num].length - 1]}
                    <strong class="larger125"
                      >#{issue.Issue_Num} - {outOfStock[issue.Issue_Num][
                        outOfStock[issue.Issue_Num].length - 1
                      ]}</strong
                    >
                  {:else}
                    <strong class="larger125">#{issue.Issue_Num}</strong>
                  {/if}
                  <br />out of stock
                </div>
              </li>
            {/if}
          {/each}
        </ul>
        {#if page < pages}
          <div
            class="container my-1 py-1 text-center"
            id="loading"
            use:lazyLoad
          >
            <div class="spinner-grow spinner-grow-sm text-dark" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-dark" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div class="spinner-grow spinner-grow-sm text-dark" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        {/if}
      </div>
      {#if issuenumbers.length > 1 && app.screencriteria != "Other Issues"}
        <div class="col-2 m-0 p-0 border-left border-dark text-center">
          {#each issuenav as num}
            <a
              href={null}
              role="button"
              tabindex="0"
              on:click|preventDefault={(event) => scrollToNumber(event, num)}
              on:keypress|preventDefault={(event) => scrollToNumber(event, num)}
              >{num}</a
            ><br />
          {/each}
        </div>
      {/if}
    </div>
  </div>
</div>
