<div class="row m-0 p-0 border-bottom border-dark">
  <div class="col-12 m-0 p-0 text-center nkred">
    <h4 class="title">Returns - Refunds</h4>
  </div>
</div>
<div class="row m-0 mb-1 p-1">
  <div class="col-12 m-0 mb-1 p-1">
    <p class="indent">Return any book within 21 days. No call required.</p>
    <div class="row m-0 p-0 pb-1 nkbgpaleyellow">
      <div class="col-1 m-0 p-1 nkred strong">1</div>
      <div class="col-11 m-0 p-1">
        <strong>Fill-in these blanks</strong><br />
        <input type="text" placeholder="Order Number" class="w-100 px-1" /><br
        />
        <small
          >Find it on the List enclosed with your order or in the email we sent
          when we shipped your order.</small
        ><br />
        $<input
          type="text"
          placeholder="Cost of books returned"
          class="px-1 mb-1"
          style="width:calc(100% - 1em);"
        /><br />
        $<input
          type="text"
          placeholder="Cost of return postage"
          class="px-1"
          style="width:calc(100% - 1em);"
        /><br />
        <small>If you seal box before you know cost, we’ll fill it in</small>
      </div>
    </div>
    <div class="row m-0 p-0 pb-1 nkbgpaleyellow">
      <div class="col-1 m-0 p-1 nkred strong">2</div>
      <div class="col-11 m-0 p-1">
        <strong>Choose one</strong><br />
        <label for="a"
          ><input type="radio" name="return" id="a" class="m-0 mr-1" />Send a
          NewKadia Gift Card</label
        ><br />
        <label for="b"
          ><input type="radio" name="return" id="b" class="m-0 mr-1" />Refund to
          card I paid with</label
        ><br />
        <label for="c"
          ><input type="radio" name="return" id="c" class="m-0 mr-1" />If paid
          by PayPal, send to PayPal</label
        ><br />
        <label for="d"
          ><input type="radio" name="return" id="d" class="m-0 mr-1" />Mail a
          check</label
        >
      </div>
    </div>
    <div class="row m-0 p-0 pb-1">
      <div class="col-1 m-0 p-1 nkred strong">3</div>
      <div class="col-11 m-0 p-1">
        <strong>Take a Screen Shot</strong> of the yellow area and email
        screenshot to: Returns@NewKadia.com<br />
        Type Order Number in the subject line
      </div>
    </div>
    <div class="row m-0 p-0 pb-1">
      <div class="col-1 m-0 p-1 nkred strong">4</div>
      <div class="col-11 m-0 p-1">
        <strong>Write your Order number</strong> on a piece of paper & enclose it
        with the books.
      </div>
    </div>
    <div class="row m-0 p-0 pb-1">
      <div class="col-1 m-0 p-1 nkred strong">5</div>
      <div class="col-11 m-0 p-1">
        <strong>Mail Package to</strong><br />
        NewKadia - Returns<br />
        125 Noble Street, Suite 301<br />
        Norristown, PA 19401-4470<br /><br />
        USA:
        <small>Send Media Mail. We will only reimburse the Media rate.</small
        ><br />
        Outside the USA: <small>Send regular airmail.</small><br />
        You can pack the comics in the same box it came in.
      </div>
    </div>
    <hr class="border-dark" />
    <p class="indent small">
      To prevent someone from reading books and then returning them, or to
      prevent other situations, returns are not permitted for someone who has
      returned over 100 books.
    </p>
  </div>
</div>
