export const countries = [
    { "name": "Afghanistan", "abbreviation": "AF" },
    { "name": "Albania", "abbreviation": "AL" },
    { "name": "Algeria", "abbreviation": "DZ" },
    { "name": "American Samoa", "abbreviation": "AS" },
    { "name": "Andorra", "abbreviation": "AD" },
    { "name": "Angola", "abbreviation": "AO" },
    { "name": "Anguilla", "abbreviation": "AI" },
    { "name": "Antarctica", "abbreviation": "AQ" },
    { "name": "Antigua and Barbuda", "abbreviation": "AG" },
    { "name": "Argentina", "abbreviation": "AR" },
    { "name": "Armenia", "abbreviation": "AM" },
    { "name": "Aruba", "abbreviation": "AW" },
    { "name": "Australia", "abbreviation": "AU" },
    { "name": "Austria", "abbreviation": "AT" },
    { "name": "Åland Islands", "abbreviation": "AX" },
    { "name": "Azerbaijan", "abbreviation": "AZ" },
    { "name": "Bahamas", "abbreviation": "BS" },
    { "name": "Bahrain", "abbreviation": "BH" },
    { "name": "Bangladesh", "abbreviation": "BD" },
    { "name": "Barbados", "abbreviation": "BB" },
    { "name": "Belarus", "abbreviation": "BY" },
    { "name": "Belgium", "abbreviation": "BE" },
    { "name": "Belize", "abbreviation": "BZ" },
    { "name": "Benin", "abbreviation": "BJ" },
    { "name": "Bermuda", "abbreviation": "BM" },
    { "name": "Bhutan", "abbreviation": "BT" },
    { "name": "Bolivia (Plurinational State of)", "abbreviation": "BO" },
    { "name": "Bonaire, Sint Eustatius and Saba", "abbreviation": "BQ" },
    { "name": "Bosnia and Herzegovina", "abbreviation": "BA" },
    { "name": "Botswana", "abbreviation": "BW" },
    { "name": "Bouvet Island", "abbreviation": "BV" },
    { "name": "Brazil", "abbreviation": "BR" },
    { "name": "British Indian Ocean Territory", "abbreviation": "IO" },
    { "name": "Brunei Darussalam", "abbreviation": "BN" },
    { "name": "Bulgaria", "abbreviation": "BG" },
    { "name": "Burkina Faso", "abbreviation": "BF" },
    { "name": "Burundi", "abbreviation": "BI" },
    { "name": "Cabo Verde", "abbreviation": "CV" },
    { "name": "Cambodia", "abbreviation": "KH" },
    { "name": "Cameroon", "abbreviation": "CM" },
    { "name": "Canada", "abbreviation": "CA" },
    { "name": "Cayman Islands", "abbreviation": "KY" },
    { "name": "Central African Republic", "abbreviation": "CF" },
    { "name": "Chad", "abbreviation": "TD" },
    { "name": "Chile", "abbreviation": "CL" },
    { "name": "China", "abbreviation": "CN" },
    { "name": "Christmas Island", "abbreviation": "CX" },
    { "name": "Cocos (Keeling) Islands", "abbreviation": "CC" },
    { "name": "Colombia", "abbreviation": "CO" },
    { "name": "Comoros", "abbreviation": "KM" },
    { "name": "Congo (the Democratic Republic of the)", "abbreviation": "CD" },
    { "name": "Congo", "abbreviation": "CG" },
    { "name": "Cook Islands", "abbreviation": "CK" },
    { "name": "Costa Rica", "abbreviation": "CR" },
    { "name": "Croatia", "abbreviation": "HR" },
    { "name": "Cuba", "abbreviation": "CU" },
    { "name": "Curaçao", "abbreviation": "CW" },
    { "name": "Cyprus", "abbreviation": "CY" },
    { "name": "Czechia", "abbreviation": "CZ" },
    { "name": "Côte d'Ivoire", "abbreviation": "CI" },
    { "name": "Denmark", "abbreviation": "DK" },
    { "name": "Djibouti", "abbreviation": "DJ" },
    { "name": "Dominica", "abbreviation": "DM" },
    { "name": "Dominican Republic", "abbreviation": "DO" },
    { "name": "Ecuador", "abbreviation": "EC" },
    { "name": "Egypt", "abbreviation": "EG" },
    { "name": "El Salvador", "abbreviation": "SV" },
    { "name": "Equatorial Guinea", "abbreviation": "GQ" },
    { "name": "Eritrea", "abbreviation": "ER" },
    { "name": "Estonia", "abbreviation": "EE" },
    { "name": "Eswatini", "abbreviation": "SZ" },
    { "name": "Ethiopia", "abbreviation": "ET" },
    { "name": "Falkland Islands [Malvinas]", "abbreviation": "FK" },
    { "name": "Faroe Islands", "abbreviation": "FO" },
    { "name": "Fiji", "abbreviation": "FJ" },
    { "name": "Finland", "abbreviation": "FI" },
    { "name": "France", "abbreviation": "FR" },
    { "name": "French Guiana", "abbreviation": "GF" },
    { "name": "French Polynesia", "abbreviation": "PF" },
    { "name": "French Southern Territories", "abbreviation": "TF" },
    { "name": "Gabon", "abbreviation": "GA" },
    { "name": "Gambia", "abbreviation": "GM" },
    { "name": "Georgia", "abbreviation": "GE" },
    { "name": "Germany", "abbreviation": "DE" },
    { "name": "Ghana", "abbreviation": "GH" },
    { "name": "Gibraltar", "abbreviation": "GI" },
    { "name": "Greece", "abbreviation": "GR" },
    { "name": "Greenland", "abbreviation": "GL" },
    { "name": "Grenada", "abbreviation": "GD" },
    { "name": "Guadeloupe", "abbreviation": "GP" },
    { "name": "Guam", "abbreviation": "GU" },
    { "name": "Guatemala", "abbreviation": "GT" },
    { "name": "Guernsey", "abbreviation": "GG" },
    { "name": "Guinea", "abbreviation": "GN" },
    { "name": "Guinea-Bissau", "abbreviation": "GW" },
    { "name": "Guyana", "abbreviation": "GY" },
    { "name": "Haiti", "abbreviation": "HT" },
    { "name": "Heard Island and McDonald Islands", "abbreviation": "HM" },
    { "name": "Holy See", "abbreviation": "VA" },
    { "name": "Honduras", "abbreviation": "HN" },
    { "name": "Hong Kong", "abbreviation": "HK" },
    { "name": "Hungary", "abbreviation": "HU" },
    { "name": "Iceland", "abbreviation": "IS" },
    { "name": "India", "abbreviation": "IN" },
    { "name": "Indonesia", "abbreviation": "ID" },
    { "name": "Iran (Islamic Republic of)", "abbreviation": "IR" },
    { "name": "Iraq", "abbreviation": "IQ" },
    { "name": "Ireland", "abbreviation": "IE" },
    { "name": "Isle of Man", "abbreviation": "IM" },
    { "name": "Israel", "abbreviation": "IL" },
    { "name": "Italy", "abbreviation": "IT" },
    { "name": "Jamaica", "abbreviation": "JM" },
    { "name": "Japan", "abbreviation": "JP" },
    { "name": "Jersey", "abbreviation": "JE" },
    { "name": "Jordan", "abbreviation": "JO" },
    { "name": "Kazakhstan", "abbreviation": "KZ" },
    { "name": "Kenya", "abbreviation": "KE" },
    { "name": "Kiribati", "abbreviation": "KI" },
    { "name": "Korea (the Democratic People's Republic of)", "abbreviation": "KP" },
    { "name": "Korea (the Republic of)", "abbreviation": "KR" },
    { "name": "Kuwait", "abbreviation": "KW" },
    { "name": "Kyrgyzstan", "abbreviation": "KG" },
    { "name": "Lao People's Democratic Republic", "abbreviation": "LA" },
    { "name": "Latvia", "abbreviation": "LV" },
    { "name": "Lebanon", "abbreviation": "LB" },
    { "name": "Lesotho", "abbreviation": "LS" },
    { "name": "Liberia", "abbreviation": "LR" },
    { "name": "Libya", "abbreviation": "LY" },
    { "name": "Liechtenstein", "abbreviation": "LI" },
    { "name": "Lithuania", "abbreviation": "LT" },
    { "name": "Luxembourg", "abbreviation": "LU" },
    { "name": "Macao", "abbreviation": "MO" },
    { "name": "Madagascar", "abbreviation": "MG" },
    { "name": "Malawi", "abbreviation": "MW" },
    { "name": "Malaysia", "abbreviation": "MY" },
    { "name": "Maldives", "abbreviation": "MV" },
    { "name": "Mali", "abbreviation": "ML" },
    { "name": "Malta", "abbreviation": "MT" },
    { "name": "Marshall Islands", "abbreviation": "MH" },
    { "name": "Martinique", "abbreviation": "MQ" },
    { "name": "Mauritania", "abbreviation": "MR" },
    { "name": "Mauritius", "abbreviation": "MU" },
    { "name": "Mayotte", "abbreviation": "YT" },
    { "name": "Mexico", "abbreviation": "MX" },
    { "name": "Micronesia (Federated States of)", "abbreviation": "FM" },
    { "name": "Moldova (the Republic of)", "abbreviation": "MD" },
    { "name": "Monaco", "abbreviation": "MC" },
    { "name": "Mongolia", "abbreviation": "MN" },
    { "name": "Montenegro", "abbreviation": "ME" },
    { "name": "Montserrat", "abbreviation": "MS" },
    { "name": "Morocco", "abbreviation": "MA" },
    { "name": "Mozambique", "abbreviation": "MZ" },
    { "name": "Myanmar", "abbreviation": "MM" },
    { "name": "Namibia", "abbreviation": "NA" },
    { "name": "Nauru", "abbreviation": "NR" },
    { "name": "Nepal", "abbreviation": "NP" },
    { "name": "Netherlands", "abbreviation": "NL" },
    { "name": "New Caledonia", "abbreviation": "NC" },
    { "name": "New Zealand", "abbreviation": "NZ" },
    { "name": "Nicaragua", "abbreviation": "NI" },
    { "name": "Niger", "abbreviation": "NE" },
    { "name": "Nigeria", "abbreviation": "NG" },
    { "name": "Niue", "abbreviation": "NU" },
    { "name": "Norfolk Island", "abbreviation": "NF" },
    { "name": "Northern Mariana Islands", "abbreviation": "MP" },
    { "name": "Norway", "abbreviation": "NO" },
    { "name": "Oman", "abbreviation": "OM" },
    { "name": "Pakistan", "abbreviation": "PK" },
    { "name": "Palau", "abbreviation": "PW" },
    { "name": "Palestine, State of", "abbreviation": "PS" },
    { "name": "Panama", "abbreviation": "PA" },
    { "name": "Papua New Guinea", "abbreviation": "PG" },
    { "name": "Paraguay", "abbreviation": "PY" },
    { "name": "Peru", "abbreviation": "PE" },
    { "name": "Philippines", "abbreviation": "PH" },
    { "name": "Pitcairn", "abbreviation": "PN" },
    { "name": "Poland", "abbreviation": "PL" },
    { "name": "Portugal", "abbreviation": "PT" },
    { "name": "Puerto Rico", "abbreviation": "PR" },
    { "name": "Qatar", "abbreviation": "QA" },
    { "name": "Republic of North Macedonia", "abbreviation": "MK" },
    { "name": "Romania", "abbreviation": "RO" },
    { "name": "Russian Federation", "abbreviation": "RU" },
    { "name": "Rwanda", "abbreviation": "RW" },
    { "name": "Réunion", "abbreviation": "RE" },
    { "name": "Saint Barthélemy", "abbreviation": "BL" },
    { "name": "Saint Helena, Ascension and Tristan da Cunha", "abbreviation": "SH" },
    { "name": "Saint Kitts and Nevis", "abbreviation": "KN" },
    { "name": "Saint Lucia", "abbreviation": "LC" },
    { "name": "Saint Martin (French part)", "abbreviation": "MF" },
    { "name": "Saint Pierre and Miquelon", "abbreviation": "PM" },
    { "name": "Saint Vincent and the Grenadines", "abbreviation": "VC" },
    { "name": "Samoa", "abbreviation": "WS" },
    { "name": "San Marino", "abbreviation": "SM" },
    { "name": "Sao Tome and Principe", "abbreviation": "ST" },
    { "name": "Saudi Arabia", "abbreviation": "SA" },
    { "name": "Senegal", "abbreviation": "SN" },
    { "name": "Serbia", "abbreviation": "RS" },
    { "name": "Seychelles", "abbreviation": "SC" },
    { "name": "Sierra Leone", "abbreviation": "SL" },
    { "name": "Singapore", "abbreviation": "SG" },
    { "name": "Sint Maarten (Dutch part)", "abbreviation": "SX" },
    { "name": "Slovakia", "abbreviation": "SK" },
    { "name": "Slovenia", "abbreviation": "SI" },
    { "name": "Solomon Islands", "abbreviation": "SB" },
    { "name": "Somalia", "abbreviation": "SO" },
    { "name": "South Africa", "abbreviation": "ZA" },
    { "name": "South Georgia and the South Sandwich Islands", "abbreviation": "GS" },
    { "name": "South Sudan", "abbreviation": "SS" },
    { "name": "Spain", "abbreviation": "ES" },
    { "name": "Sri Lanka", "abbreviation": "LK" },
    { "name": "Sudan", "abbreviation": "SD" },
    { "name": "Suriname", "abbreviation": "SR" },
    { "name": "Svalbard and Jan Mayen", "abbreviation": "SJ" },
    { "name": "Sweden", "abbreviation": "SE" },
    { "name": "Switzerland", "abbreviation": "CH" },
    { "name": "Syrian Arab Republic", "abbreviation": "SY" },
    { "name": "Taiwan (Province of China)", "abbreviation": "TW" },
    { "name": "Tajikistan", "abbreviation": "TJ" },
    { "name": "Tanzania, United Republic of", "abbreviation": "TZ" },
    { "name": "Thailand", "abbreviation": "TH" },
    { "name": "Timor-Leste", "abbreviation": "TL" },
    { "name": "Togo", "abbreviation": "TG" },
    { "name": "Tokelau", "abbreviation": "TK" },
    { "name": "Tonga", "abbreviation": "TO" },
    { "name": "Trinidad and Tobago", "abbreviation": "TT" },
    { "name": "Tunisia", "abbreviation": "TN" },
    { "name": "Turkey", "abbreviation": "TR" },
    { "name": "Turkmenistan", "abbreviation": "TM" },
    { "name": "Turks and Caicos Islands", "abbreviation": "TC" },
    { "name": "Tuvalu", "abbreviation": "TV" },
    { "name": "Uganda", "abbreviation": "UG" },
    { "name": "Ukraine", "abbreviation": "UA" },
    { "name": "United Arab Emirates", "abbreviation": "AE" },
    { "name": "United Kingdom of Great Britain and Northern Ireland", "abbreviation": "GB" },
    { "name": "United States Minor Outlying Islands", "abbreviation": "UM" },
    { "name": "United States of America", "abbreviation": "US" },
    { "name": "Uruguay", "abbreviation": "UY" },
    { "name": "Uzbekistan", "abbreviation": "UZ" },
    { "name": "Vanuatu", "abbreviation": "VU" },
    { "name": "Venezuela (Bolivarian Republic of)", "abbreviation": "VE" },
    { "name": "Viet Nam", "abbreviation": "VN" },
    { "name": "Virgin Islands (British)", "abbreviation": "VG" },
    { "name": "Virgin Islands (U.S.)", "abbreviation": "VI" },
    { "name": "Wallis and Futuna", "abbreviation": "WF" },
    { "name": "Western Sahara", "abbreviation": "EH" },
    { "name": "Yemen", "abbreviation": "YE" },
    { "name": "Zambia", "abbreviation": "ZM" },
    { "name": "Zimbabwe", "abbreviation": "ZW" }
];

export const usStates = [
    { "name": "Alabama", "abbreviation": "AL" },
    { "name": "Alaska", "abbreviation": "AK" },
    { "name": "American Samoa", "abbreviation": "AS" },
    { "name": "Arizona", "abbreviation": "AZ" },
    { "name": "Arkansas", "abbreviation": "AR" },
    { "name": "California", "abbreviation": "CA" },
    { "name": "Colorado", "abbreviation": "CO" },
    { "name": "Connecticut", "abbreviation": "CT" },
    { "name": "Delaware", "abbreviation": "DE" },
    { "name": "District Of Columbia", "abbreviation": "DC" },
    { "name": "Federated States Of Micronesia", "abbreviation": "FM" },
    { "name": "Florida", "abbreviation": "FL" },
    { "name": "Georgia", "abbreviation": "GA" },
    { "name": "Guam", "abbreviation": "GU" },
    { "name": "Hawaii", "abbreviation": "HI" },
    { "name": "Idaho", "abbreviation": "ID" },
    { "name": "Illinois", "abbreviation": "IL" },
    { "name": "Indiana", "abbreviation": "IN" },
    { "name": "Iowa", "abbreviation": "IA" },
    { "name": "Kansas", "abbreviation": "KS" },
    { "name": "Kentucky", "abbreviation": "KY" },
    { "name": "Louisiana", "abbreviation": "LA" },
    { "name": "Maine", "abbreviation": "ME" },
    { "name": "Marshall Islands", "abbreviation": "MH" },
    { "name": "Maryland", "abbreviation": "MD" },
    { "name": "Massachusetts", "abbreviation": "MA" },
    { "name": "Michigan", "abbreviation": "MI" },
    { "name": "Minnesota", "abbreviation": "MN" },
    { "name": "Mississippi", "abbreviation": "MS" },
    { "name": "Missouri", "abbreviation": "MO" },
    { "name": "Montana", "abbreviation": "MT" },
    { "name": "Nebraska", "abbreviation": "NE" },
    { "name": "Nevada", "abbreviation": "NV" },
    { "name": "New Hampshire", "abbreviation": "NH" },
    { "name": "New Jersey", "abbreviation": "NJ" },
    { "name": "New Mexico", "abbreviation": "NM" },
    { "name": "New York", "abbreviation": "NY" },
    { "name": "North Carolina", "abbreviation": "NC" },
    { "name": "North Dakota", "abbreviation": "ND" },
    { "name": "Northern Mariana Islands", "abbreviation": "MP" },
    { "name": "Ohio", "abbreviation": "OH" },
    { "name": "Oklahoma", "abbreviation": "OK" },
    { "name": "Oregon", "abbreviation": "OR" },
    { "name": "Palau", "abbreviation": "PW" },
    { "name": "Pennsylvania", "abbreviation": "PA" },
    { "name": "Puerto Rico", "abbreviation": "PR" },
    { "name": "Rhode Island", "abbreviation": "RI" },
    { "name": "South Carolina", "abbreviation": "SC" },
    { "name": "South Dakota", "abbreviation": "SD" },
    { "name": "Tennessee", "abbreviation": "TN" },
    { "name": "Texas", "abbreviation": "TX" },
    { "name": "Utah", "abbreviation": "UT" },
    { "name": "Vermont", "abbreviation": "VT" },
    { "name": "Virgin Islands", "abbreviation": "VI" },
    { "name": "Virginia", "abbreviation": "VA" },
    { "name": "Washington", "abbreviation": "WA" },
    { "name": "West Virginia", "abbreviation": "WV" },
    { "name": "Wisconsin", "abbreviation": "WI" },
    { "name": "Wyoming", "abbreviation": "WY" }
];

export const auStates = [
    { "name":"New South Wales", "abbreviation":"NSW" },
    { "name":"Victoria", "abbreviation":"VIC" },
    { "name":"Queensland", "abbreviation":"QLD" },
    { "name":"Tasmania", "abbreviation":"TAS" },
    { "name":"South Australia", "abbreviation":"SA" },
    { "name":"Western Australia", "abbreviation":"WA" },
    { "name":"Northern Territory", "abbreviation":"NT" },
    { "name":"Australian Capital Territory", "abbreviation":"ACT" }
];

export const esStates = [
    { "name": "La Coruña", "abbreviation": "C" },
    { "name": "Lugo", "abbreviation": "LU" },
    { "name": "Vizcaya", "abbreviation": "BI" },
    { "name": "Guipúzcoa", "abbreviation": "SS" },
    { "name": "Huesca", "abbreviation": "HU" },
    { "name": "Lérida", "abbreviation": "L" },
    { "name": "Gerona", "abbreviation": "GI" },
    { "name": "Barcelona", "abbreviation": "B" },
    { "name": "Tarragona", "abbreviation": "T" },
    { "name": "Castellón", "abbreviation": "CS" },
    { "name": "Valencia", "abbreviation": "V" },
    { "name": "Alicante", "abbreviation": "A" },
    { "name": "Murcia", "abbreviation": "MU" },
    { "name": "Zaragoza", "abbreviation": "Z" },
    { "name": "Teruel", "abbreviation": "TE" },
    { "name": "Cuenca", "abbreviation": "CU" },
    { "name": "Albacete", "abbreviation": "AB" },
    { "name": "Almeria", "abbreviation": "AL" },
    { "name": "Granada", "abbreviation": "GR" },
    { "name": "Málaga", "abbreviation": "MA" },
    { "name": "Tenerife", "abbreviation": "TF" },
    { "name": "Cádiz", "abbreviation": "CA" },
    { "name": "Sevilla", "abbreviation": "SE" },
    { "name": "Huelva", "abbreviation": "H" },
    { "name": "Las Palmas", "abbreviation": "GC" },
    { "name": "Madrid", "abbreviation": "M" },
    { "name": "Badajoz", "abbreviation": "BA" },
    { "name": "Cáceres", "abbreviation": "CC" },
    { "name": "Toledo", "abbreviation": "TO" },
    { "name": "Ciudad Real", "abbreviation": "CR" },
    { "name": "Salamanca", "abbreviation": "SA" },
    { "name": "Córdoba", "abbreviation": "CO" },
    { "name": "Jaén", "abbreviation": "J" },
    { "name": "Ávila", "abbreviation": "AV" },
    { "name": "Valladolid", "abbreviation": "VA" },
    { "name": "Zamora", "abbreviation": "ZA" },
    { "name": "Álava", "abbreviation": "VI" },
    { "name": "Segovia", "abbreviation": "SG" },
    { "name": "Burgos", "abbreviation": "BU" },
    { "name": "Pontevedra", "abbreviation": "PO" },
    { "name": "León", "abbreviation": "LE" },
    { "name": "Orense", "abbreviation": "OU" },
    { "name": "Palencia", "abbreviation": "P" },
    { "name": "La Rioja", "abbreviation": "LO" },
    { "name": "Soria", "abbreviation": "SO" },
    { "name": "Guadalajara", "abbreviation": "GU" }
];

export const gbStates = [
    { "name": "Avon", "region": "England" },
    { "name": "Bedfordshire", "region": "England" },
    { "name": "Berkshire", "region": "England" },
    { "name": "Borders", "region": "England" },
    { "name": "Bristol", "region": "England" },
    { "name": "Buckinghamshire", "region": "England" },
    { "name": "Cambridgeshire", "region": "England" },
    { "name": "Channel Islands", "region": "England" },
    { "name": "Cheshire", "region": "England" },
    { "name": "Cleveland", "region": "England" },
    { "name": "Cornwall", "region": "England" },
    { "name": "Cumbria", "region": "England" },
    { "name": "Derbyshire", "region": "England" },
    { "name": "Devon", "region": "England" },
    { "name": "Dorset", "region": "England" },
    { "name": "Durham", "region": "England" },
    { "name": "East Riding of Yorkshire", "region": "England" },
    { "name": "East Sussex", "region": "England" },
    { "name": "Essex", "region": "England" },
    { "name": "Gloucestershire", "region": "England" },
    { "name": "Greater Manchester", "region": "England" },
    { "name": "Hampshire", "region": "England" },
    { "name": "Herefordshire", "region": "England" },
    { "name": "Hertfordshire", "region": "England" },
    { "name": "Humberside", "region": "England" },
    { "name": "Isle of Man", "region": "England" },
    { "name": "Isle of Wight", "region": "England" },
    { "name": "Isles of Scilly", "region": "England" },
    { "name": "Kent", "region": "England" },
    { "name": "Lancashire", "region": "England" },
    { "name": "Leicestershire", "region": "England" },
    { "name": "Lincolnshire", "region": "England" },
    { "name": "London", "region": "England" },
    { "name": "Merseyside", "region": "England" },
    { "name": "Middlesex", "region": "England" },
    { "name": "Norfolk", "region": "England" },
    { "name": "North Yorkshire", "region": "England" },
    { "name": "Northamptonshire", "region": "England" },
    { "name": "Northumberland", "region": "England" },
    { "name": "Nottinghamshire", "region": "England" },
    { "name": "Oxfordshire", "region": "England" },
    { "name": "Rutland", "region": "England" },
    { "name": "Shropshire", "region": "England" },
    { "name": "Somerset", "region": "England" },
    { "name": "South Yorkshire", "region": "England" },
    { "name": "Staffordshire", "region": "England" },
    { "name": "Suffolk", "region": "England" },
    { "name": "Surrey", "region": "England" },
    { "name": "Tyne and Wear", "region": "England" },
    { "name": "Warwickshire", "region": "England" },
    { "name": "West Midlands", "region": "England" },
    { "name": "West Sussex", "region": "England" },
    { "name": "West Yorkshire", "region": "England" },
    { "name": "Wiltshire", "region": "England" },
    { "name": "Worcestershire", "region": "England" },
    { "name": "Antrim", "region": "Northern Ireland" },
    { "name": "Down", "region": "Northern Ireland" },
    { "name": "Fermanagh", "region": "Northern Ireland" },
    { "name": "Londonderry", "region": "Northern Ireland" },
    { "name": "Tyrone", "region": "Northern Ireland" },
    { "name": "Aberdeen City", "region": "Scotland" },
    { "name": "Aberdeenshire", "region": "Scotland" },
    { "name": "Angus", "region": "Scotland" },
    { "name": "Argyll and Bute", "region": "Scotland" },
    { "name": "Armagh", "region": "Scotland" },
    { "name": "Carmarthenshire", "region": "Scotland" },
    { "name": "Clackmannan", "region": "Scotland" },
    { "name": "Dumfries and Galloway", "region": "Scotland" },
    { "name": "East Ayrshire", "region": "Scotland" },
    { "name": "East Dunbartonshire", "region": "Scotland" },
    { "name": "East Lothian", "region": "Scotland" },
    { "name": "East Renfrewshire", "region": "Scotland" },
    { "name": "Edinburgh City", "region": "Scotland" },
    { "name": "Falkirk", "region": "Scotland" },
    { "name": "Fife", "region": "Scotland" },
    { "name": "Glasgow", "region": "Scotland" },
    { "name": "Highland", "region": "Scotland" },
    { "name": "Inverclyde", "region": "Scotland" },
    { "name": "Midlothian", "region": "Scotland" },
    { "name": "Moray", "region": "Scotland" },
    { "name": "North Ayrshire", "region": "Scotland" },
    { "name": "North Lanarkshire", "region": "Scotland" },
    { "name": "Orkney", "region": "Scotland" },
    { "name": "Perthshire and Kinross", "region": "Scotland" },
    { "name": "Renfrewshire", "region": "Scotland" },
    { "name": "Roxburghshire", "region": "Scotland" },
    { "name": "Shetland", "region": "Scotland" },
    { "name": "South Ayrshire", "region": "Scotland" },
    { "name": "South Lanarkshire", "region": "Scotland" },
    { "name": "Stirling", "region": "Scotland" },
    { "name": "West Dunbartonshire", "region": "Scotland" },
    { "name": "West Lothian", "region": "Scotland" },
    { "name": "Western Isles", "region": "Scotland" },
    { "name": "Blaenau Gwent", "region": "Wales" },
    { "name": "Bridgend", "region": "Wales" },
    { "name": "Caerphilly", "region": "Wales" },
    { "name": "Cardiff", "region": "Wales" },
    { "name": "Ceredigion", "region": "Wales" },
    { "name": "Conwy", "region": "Wales" },
    { "name": "Denbighshire", "region": "Wales" },
    { "name": "Flintshire", "region": "Wales" },
    { "name": "Gwynedd", "region": "Wales" },
    { "name": "Isle of Anglesey", "region": "Wales" },
    { "name": "Merthyr Tydfil", "region": "Wales" },
    { "name": "Monmouthshire", "region": "Wales" },
    { "name": "Neath Port Talbot", "region": "Wales" },
    { "name": "Newport", "region": "Wales" },
    { "name": "Pembrokeshire", "region": "Wales" },
    { "name": "Powys", "region": "Wales" },
    { "name": "Rhondda Cynon Taff", "region": "Wales" },
    { "name": "Swansea", "region": "Wales" },
    { "name": "The Vale of Glamorgan", "region": "Wales" },
    { "name": "Torfaen", "region": "Wales" },
    { "name": "Wrexham", "region": "Wales" }
];

export const jpStates = [
    { "name": "Aichi" },
    { "name": "Akita" },
    { "name": "Aomori" },
    { "name": "Chiba" },
    { "name": "Ehime" },
    { "name": "Fukui" },
    { "name": "Fukuoka" },
    { "name": "Fukushima" },
    { "name": "Gifu" },
    { "name": "Gunma" },
    { "name": "Hiroshima" },
    { "name": "Hokkaidō" },
    { "name": "Hyōgo" },
    { "name": "Ibaraki" },
    { "name": "Ishikawa" },
    { "name": "Iwate" },
    { "name": "Kagawa" },
    { "name": "Kagoshima" },
    { "name": "Kanagawa" },
    { "name": "Kōchi" },
    { "name": "Kumamoto" },
    { "name": "Kyōto" },
    { "name": "Mie" },
    { "name": "Miyagi" },
    { "name": "Miyazaki" },
    { "name": "Nagano" },
    { "name": "Nagasaki" },
    { "name": "Nara" },
    { "name": "Niigata" },
    { "name": "Ōita" },
    { "name": "Okayama" },
    { "name": "Okinawa" },
    { "name": "Ōsaka" },
    { "name": "Saga" },
    { "name": "Saitama" },
    { "name": "Shiga" },
    { "name": "Shimane" },
    { "name": "Shizuoka" },
    { "name": "Tochigi" },
    { "name": "Tokushima" },
    { "name": "Tōkyō" },
    { "name": "Tottori" },
    { "name": "Toyama" },
    { "name": "Wakayama" },
    { "name": "Yamagata" },
    { "name": "Yamaguchi" },
    { "name": "Yamanashi" }
];

export const caStates = [
    {  "abbreviation": "AB", "name": "Alberta" },
    {  "abbreviation": "BC", "name": "British Columbia" },
    {  "abbreviation": "MB", "name": "Manitoba" },
    {  "abbreviation": "NB", "name": "New Brunswick" },
    {  "abbreviation": "NL", "name": "Newfoundland and Labrador" },
    {  "abbreviation": "NS", "name": "Nova Scotia" },
    {  "abbreviation": "NU", "name": "Nunavut" },
    {  "abbreviation": "NT", "name": "Northwest Territories" },
    {  "abbreviation": "ON", "name": "Ontario" },
    {  "abbreviation": "PE", "name": "Prince Edward Island" },
    {  "abbreviation": "QC", "name": "Quebec" },
    {  "abbreviation": "SK", "name": "Saskatchewan" },
    {  "abbreviation": "YT", "name": "Yukon" }
];
