export const paymentTypes = {
    'GC': 'Gift Certificate',
    'RGC': 'Refunded',
    'C': 'Check',
    'MO': 'Money Order',
    'PP': 'PayPal',
    'GG': 'Google Checkout',
    'V': 'Visa',
    'MC': 'MasterCard',
    'D': 'Discover',
    'Amex': 'Amex',
    'O': 'Optima',
    'NKC': 'NK Credit',
    'CCM': 'Credit Card',
    'Cash': 'Cash'
};

export const paymentCodes = {
    'RGC': 60,
    'C': 100,
    'MO': 105,
    'PP': 110,
    'GG': 155,
    'V': 115,
    'MC': 120,
    'D': 125,
    'Amex': 130,
    'O': 135,
    'GC' : 150,
    'NKC': 200,
    'CCM': 250,
    'Cash': 255
};
