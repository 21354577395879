<script>
  import { onMount } from "svelte";
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let crt;
  let primeCarted = 0;
  const cartUnsubscribe = cart.subscribe((value) => {
    crt = value;
    primeCarted = crt.filter((item) => item.Typ == 70 || item.Typ == 75).length;
  });

  function formatMoney(number, decPlaces, decSep, thouSep) {
    (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
      (decSep = typeof decSep === "undefined" ? "." : decSep);
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    if (sess.currency == "USD") sign = "$" + sign;
    var i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : "") +
      (sess.currency != "USD" ? "<small>" + sess.currency + "</small>" : "")
    );
  }

  let isWorking = true;

  async function addPrime(whichPrime) {
    if (!isWorking) {
      isWorking = true;
      document.getElementById("dinger").volume = 0;
      document.getElementById("dinger").play();
      var options = {
        method: "POST",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currency: sess.currency,
          sessionid: sess.id,
          whichPrime: whichPrime,
        }),
      };
      await fetch(app.apiUrl + "cart/addprime", options)
        .then((res) => res.json())
        .then((res) => {
          res.map((element) => {
            if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
              element.Ref = JSON.parse(element.Ref);
            else if (
              element.Ref != "" &&
              element.Ref.indexOf("?Comics=") > -1
            ) {
              var tmp = element.Ref.split("=");
              tmp = tmp[1].split("#");
              tmp = tmp[0].split("|");
              element.Ref = [tmp[0], "", tmp[1], "", ""];
            }
          });
          cart.update((n) => {
            return res;
          });
          if (
            navigator.vibrate ||
            navigator.webkitVibrate ||
            navigator.mozVibrate ||
            navigator.msVibrate
          )
            navigator.vibrate(200);
          else {
            document.getElementById("dinger").volume = 1;
            document.getElementById("dinger").play();
          }
          isWorking = false;
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    }
  }

  let primeDetails = {};
  let primeUntil = null;
  let monthsOfYear = [
    ["January", "Jan"],
    ["February", "Feb"],
    ["March", "Mar"],
    ["April", "Apr"],
    ["May", "May"],
    ["June", "Jun"],
    ["July", "Jul"],
    ["August", "Aug"],
    ["September", "Sep"],
    ["October", "Oct"],
    ["November", "Nov"],
    ["December", "Dec"],
  ];
  async function getPrime() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "adm_options/get/prime", options)
      .then((res) => res.json())
      .then((res) => {
        primeDetails = res;
        primeUntil =
          new Date().getTime() + 86400000 * primeDetails.primeLengthDays;
        primeUntil = new Date(primeUntil);
        isWorking = false;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  let priorityCost = 0.0;
  let standardCost = 0.0;
  async function getShippingOptions() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    var url =
      app.apiUrl +
      "newcust_shipping/" +
      sess.country +
      "/" +
      (sess.state && sess.state != "" ? sess.state : "0") +
      "/" +
      sess.currency +
      "/0/1/0.01";
    if (usr && usr.Orders_Placed && usr.Orders_Placed > 0)
      url =
        app.apiUrl +
        "shipping/" +
        sess.country +
        "/" +
        (sess.state && sess.state != "" ? sess.state : "0") +
        "/" +
        sess.currency +
        "/0/1/0.01";
    await fetch(url, options)
      .then((res) => res.json())
      .then((res) => {
        priorityCost = 0.0;
        standardCost = 0.0;
        for (var i = 0; i < res.length; i++) {
          if (res[i].Ship_Code == "Priority") priorityCost = res[i].Base_Cost;
          if (res[i].Ship_Code == "Standard") standardCost = res[i].Base_Cost;
        }
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  onMount(async () => {
    await getShippingOptions();
    await getPrime();
  });
</script>

<div class="row m-0 mb-3 p-0">
  <div class="col-12 m-0 p-0" style="position:relative;">
    {#if isWorking}
      <div class="subshade" />
      <div class="subshadeloader">
        <div class="container my-1 py-1 text-center">
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    {/if}
    <div class="row m-0 p-0 border-bottom border-dark">
      <div class="col-12 m-0 p-0 text-center nkred">
        <h4 class="title">NewKadia Prime</h4>
      </div>
    </div>
    <div class="row m-0 mb-1 p-1">
      <div class="col-12 m-0 mb-1 p-1">
        <h5 style="text-align:left;">Free USA shipping for 6 months</h5>
        <ul>
          <li>
            {@html formatMoney(primeDetails.primeStandard)} for Prime Standard, or
          </li>
          <li>
            {@html formatMoney(primeDetails.primePriority)} for Prime Priority (2-day)
          </li>
        </ul>
        <h5 style="text-align:left;">Order 2 times and you save</h5>
        <div
          class="p-0 mb-0 mt-1 mr-auto ml-auto border-top border-red border-top-4"
          style="width:95%;"
        >
          <div
            class="row m-0 p-0 border-left border-right border-bottom border-lightgrey"
          >
            <div
              class="col-4 m-0 p-1 border-right border-lightgrey text-center"
            >
              <strong>Shipping cost for...</strong>
            </div>
            <div
              class="col-4 m-0 p-1 border-right border-lightgrey text-center"
            >
              Without Prime
            </div>
            <div class="col-4 m-0 p-1 text-center">
              With Prime <strong>Standard</strong>
            </div>
          </div>
          <div class="row m-0 p-0 border-right border-bottom border-lightgrey">
            <div class="col-8 m-0 p-0">
              <div
                class="row m-0 p-0 border-left border-bottom border-lightgrey"
              >
                <div
                  class="col-6 m-0 p-1 border-right border-lightgrey text-center"
                >
                  1 order
                </div>
                <div
                  class="col-6 m-0 p-1 border-right border-lightgrey text-right"
                >
                  {@html formatMoney(standardCost)}
                </div>
              </div>
              <div
                class="row m-0 p-0 border-left border-bottom border-lightgrey"
              >
                <div
                  class="col-6 m-0 p-1 border-right border-lightgrey text-center"
                >
                  2 orders
                </div>
                <div
                  class="col-6 m-0 p-1 border-right border-lightgrey text-right"
                >
                  {@html formatMoney(standardCost * 2)}
                </div>
              </div>
              <div
                class="row m-0 p-0 border-left border-bottom border-lightgrey"
              >
                <div
                  class="col-6 m-0 p-1 border-right border-lightgrey text-center"
                >
                  5 orders
                </div>
                <div
                  class="col-6 m-0 p-1 border-right border-lightgrey text-right"
                >
                  {@html formatMoney(standardCost * 5)}
                </div>
              </div>
              <div class="row m-0 p-0 border-left border-lightgrey">
                <div
                  class="col-6 m-0 p-1 border-right border-lightgrey text-center"
                >
                  25 orders
                </div>
                <div
                  class="col-6 m-0 p-1 border-right border-lightgrey text-right"
                >
                  {@html formatMoney(standardCost * 25)}
                </div>
              </div>
            </div>
            <div class="col-4 m-0 p-1 text-center" style="position:relative;">
              <div
                style="display:inline-block;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);"
              >
                {@html formatMoney(primeDetails.primeStandard)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {#if primeDetails && primeUntil && (!usr || !usr.prime) && primeCarted == 0}
      <div class="row m-0 p-0">
        <div class="col-12 m-0 p-0 text-center">
          <h5>Get Free Shipping</h5>
        </div>
      </div>
      <div class="row m-0 p-0 border-bottom border-info">
        <div class="col-4 py-1">
          <img
            src="https://www.imagesnk.com/freeshippingMONO77x128.jpg"
            alt="Prime Standard - Free Shipping"
            style="max-width:100%;"
          />
        </div>
        <div class="col-8 py-1">
          Optional: <span class="nkdarkred"
            ><strong>Free USA Standard Shipping</strong></span
          >
          for 6 months for {@html formatMoney(primeDetails.primeStandard)}<br />
          <small
            ><strong>Save {@html formatMoney(standardCost)}</strong> today and
            <strong>Standard is FREE</strong>
            until {monthsOfYear[primeUntil.getMonth()][1]}. {primeUntil.getDate()}</small
          ><br />
          <button
            class="btn btn-primary btn-sm border-0 nkbgdarkred white"
            on:click|preventDefault={() => addPrime(2)}
            >Add Prime Standard</button
          >
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-4 py-1">
          <img
            src="https://www.imagesnk.com/freeshipping77x128.jpg"
            alt="Prime Priority - Free Shipping"
            style="max-width:100%;"
          />
        </div>
        <div class="col-8 py-1">
          Optional: <span class="nkred"
            ><strong>Free USA Priority Shipping</strong></span
          >
          for 6 months for {@html formatMoney(primeDetails.primePriority)}<br />
          <small
            ><strong>Save {@html formatMoney(priorityCost)}</strong> today and
            <strong>Priority is FREE</strong>
            until {monthsOfYear[primeUntil.getMonth()][1]}. {primeUntil.getDate()}</small
          ><br />
          <button
            class="btn btn-primary btn-sm border-0 nkbgred white"
            on:click|preventDefault={() => addPrime(1)}
            >Add Prime Priority</button
          >
        </div>
      </div>
    {/if}
  </div>
</div>
