export const orderStatuses = {
    '0': '(On-Hold)',
    '1': 'Awaiting Payment',
    '3': 'Paid - Ready to ship',
    '5': 'Packed & Shipped',
    'N': 'New Order',
    'O': 'On-Hold',
    'P': 'Packed',
    'R': 'Refund Pending',
    'S': 'Shipped',
    '~': 'Shipped',
    'X': 'Cancelled',
    '?': 'Unknown'
};
