<script>
  import { onMount } from "svelte";
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import { faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function goToSubCriteria(s, sm, sc, ssc) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: sm,
        screencriteria: sc,
        screensubcriteria: ssc,
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  let currencies = [];

  $: {
    const t = sess.token;
    updateCurrencies();
  }

  async function getCart() {
    var options = {
      method: "GET",
      cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "cart/" + sess.currency + "/" + sess.id, options)
      .then((res) => res.json())
      .then((res) => {
        res.map((element) => {
          if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
            element.Ref = JSON.parse(element.Ref);
          else if (element.Ref != "" && element.Ref.indexOf("?Comics=") > -1) {
            var tmp = element.Ref.split("=");
            tmp = tmp[1].split("#");
            tmp = tmp[0].split("|");
            element.Ref = [tmp[0], "", tmp[1], "", ""];
          }
        });
        cart.update((n) => {
          return res;
        });
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  async function updateCurrencies() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "CountryCurrency/getdropdown", options)
      .then((res) => res.json())
      .then((res) => {
        currencies = res;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  function setCurrency(ev) {
    session.update((n) => {
      return { ...n, currency: ev.target.value };
    });
    getCart();
  }

  let salesImages = [];
  async function getSaleImages() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "mobile_sale_images", options)
      .then((res) => res.json())
      .then((res) => {
        salesImages = res;
        console.log(salesImages);
      })
      .catch((err) => {
        /*console.log(err);*/
      });
  }

  onMount(async () => {
    await getSaleImages();
    await updateCurrencies();
  });

  const today = new Date();
  const msgStartDate = new Date("03/13/2024 00:01:00");
  const msgEndDate = new Date("03/17/2024 23:59:59");
  const showMsg =
    msgStartDate.getTime() <= today.getTime() &&
    today.getTime() <= msgEndDate.getTime();
  // const msgStartDate2 = new Date("12/26/2023 03:00:00");
  // const msgEndDate2 = new Date("01/02/2024 03:00:00");
  // const showMsg2 =
  //   msgStartDate2.getTime() <= today.getTime() &&
  //   today.getTime() <= msgEndDate2.getTime();
</script>

<div class="row m-1 p-1">
  <div class="col-12 m-0 p-0">
    <h1 class="h1_15 homeBanner">Web's Biggest Comic Store</h1>
  </div>
</div>
<div class="d-block m-0 p-2 text-center">
  <div
    class="d-inline-block m-1 p-0 text-center align-top"
    role="button"
    tabindex="0"
    on:click|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "Marvel", "A")}
    on:keypress|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "Marvel", "A")}
  >
    <img
      class="smallCoverImg d-block mx-auto"
      src="{app.imgUrl}/Covers/L/A/Amazing+Spider-Man+2015+series/amazingspider-man2015series1issueis16.jpg"
      alt="Marvel Comics"
    />
    <div class="tag nkbgred white text-center">Marvel</div>
  </div>
  <div
    class="d-inline-block m-1 p-0 text-center align-top"
    role="button"
    tabindex="0"
    on:click|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "DC", "A")}
    on:keypress|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "DC", "A")}
  >
    <img
      class="smallCoverImg d-block mx-auto"
      src="{app.imgUrl}/Covers/S/B/Batman+1940+series/batman1.jpg"
      alt="DC Comics"
    />
    <div class="tag nkbgred white text-center">DC</div>
  </div>
  <!-- svelte-ignore a11y-img-redundant-alt -->
  <div
    class="d-none d-sm-inline-block m-1 p-0 text-center align-top"
    role="button"
    tabindex="0"
    on:click|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "Image", "A")}
    on:keypress|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "Image", "A")}
  >
    <img
      class="smallCoverImg d-block mx-auto"
      src="{app.imgUrl}/Covers/S/S/Spawn/spawn1.jpg"
      alt="Image Comics"
    />
    <div class="tag nkbgred white text-center">Image</div>
  </div>
  <div
    class="d-none d-md-inline-block m-1 p-0 text-center align-top"
    role="button"
    tabindex="0"
    on:click|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "Dark Horse", "A")}
    on:keypress|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "Dark Horse", "A")}
  >
    <img
      class="smallCoverImg d-block mx-auto"
      src="{app.imgUrl}/Covers/S/S/Star+Wars+2013+series/starwars2013series3.jpg"
      alt="Dark Horse Comics"
    />
    <div class="tag nkbgred white text-center">Dark Horse</div>
  </div>
  <div
    class="d-none d-lg-inline-block m-1 p-0 text-center align-top"
    role="button"
    tabindex="0"
    on:click|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "IDW", "A")}
    on:keypress|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "IDW", "A")}
  >
    <img
      class="smallCoverImg d-block mx-auto"
      src="{app.imgUrl}/Covers/S/T/30+Days+of+Night+2011+series/30daysofnight2011series5.jpg"
      alt="IDW Comics"
    />
    <div class="tag nkbgred white text-center">IDW</div>
  </div>
  <div
    class="d-inline-block m-1 p-0 text-center align-top"
    role="button"
    tabindex="0"
    on:click|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "", "")}
    on:keypress|preventDefault={() =>
      goToSubCriteria("titles", "publishers", "", "")}
  >
    <img
      class="smallCoverImg d-block mx-auto"
      src="{app.imgUrl}/Covers/L/M/Mickey+Mouse+1941+series/mickeymouse40.jpg"
      alt="Comics by Other Publishers"
    />
    <div class="tag nkbgred white text-center">Others</div>
  </div>
</div>
<div class="row m-1 p-1">
  <div class="col-12 m-0 p-0 text-center">
    {#if showMsg}
      <img
        src="//www.newkadia.com/B/2024-03-16.png"
        style="max-width:95%;margin-left:auto;margin-right:auto;margin-bottom:1em;"
      />
    {/if}
    {#each salesImages as img}
      <img
        src={img.url}
        style="max-width:95%;margin-left:auto;margin-right:auto;margin-bottom:1em;"
      />
    {/each}
    <p class="mt-0 mb-4 larger strong">
      Orders placed now<br />ship the next business day
    </p>
  </div>
</div>
<div class="row m-1 p-1 border-top border-dark">
  <div
    class="col-12 m-0 py-0 px-2 clearfix"
    role="button"
    tabindex="0"
    on:click|preventDefault={() => goTo("shipping")}
    on:keypress|preventDefault={() => goTo("shipping")}
  >
    Worldwide shipping info
    <FontAwesomeIcon
      icon={faArrowCircleRight}
      class="float-right navarrow"
      style="display:inline-block;"
    />
  </div>
</div>
<div class="row m-1 p-1 border-top border-dark">
  <div
    class="col-12 m-0 py-0 px-2"
    role="button"
    tabindex="0"
    on:click|preventDefault={() => goTo("sales")}
    on:keypress|preventDefault={() => goTo("sales")}
  >
    Today's discounts &amp; sales
    <FontAwesomeIcon
      icon={faArrowCircleRight}
      class="float-right navarrow"
      style="display:inline-block;"
    />
  </div>
</div>
<div class="row m-1 p-1 border-top border-dark">
  <div
    class="col-12 m-0 py-0 px-2"
    role="button"
    tabindex="0"
    on:click|preventDefault={() => goTo("ninetypercent")}
    on:keypress|preventDefault={() => goTo("ninetypercent")}
  >
    90% off clearance <FontAwesomeIcon
      icon={faArrowCircleRight}
      class="float-right navarrow"
      style="display:inline-block;"
    />
  </div>
</div>
<div class="row m-1 p-1 border-top border-dark">
  <div class="col-12 m-0 py-0 px-2">
    Show prices in
    <select on:change={(event) => setCurrency(event)}>
      {#each currencies as c}
        {#if c.currency_alphabetic_code == sess.currency}
          <option value={c.currency_alphabetic_code} selected
            >{@html c.currency_display_name}</option
          >
        {:else}
          <option value={c.currency_alphabetic_code}
            >{@html c.currency_display_name}</option
          >
        {/if}
      {/each}
    </select>
  </div>
</div>
<div class="row m-1 mb-2 p-1 border-top border-dark">
  <div class="col-12 m-0 py-3 px-6 text-center">
    <img
      src="https://www.newkadia.com/A/NewKadia24-Small.png"
      alt="NewKadia.com - 24 years online, 2000-2024"
      style="max-width:90%;"
    />
  </div>
</div>
