<script>
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import {
    faDoorOpen,
    faSearch,
    faShoppingCart,
    faTimesCircle,
    faUserCircle,
  } from "@fortawesome/pro-solid-svg-icons";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let cartCount = 0;
  const cartUnsubscribe = cart.subscribe((value) => {
    cartCount = 0;
    if (value && Array.isArray(value))
      value.forEach((element) => {
        if ([0, 50, 55, 60].indexOf(element.Typ) > -1 && element.Status != "X")
          cartCount += element.Qty;
      });
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let menuOpened = false;
  function menuToggle() {
    if (menuOpened) menuOpened = false;
    else menuOpened = true;
  }

  function goTo(s) {
    menuOpened = false;
    text = "";
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function goToIssues(tskey) {
    menuOpened = false;
    application.update((n) => {
      return {
        ...n,
        screen: "issues",
        screenmode: tskey,
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function goToTitles(s) {
    menuOpened = false;
    text = "";
    if (s == "alpha") {
      application.update((n) => {
        return {
          ...n,
          screen: "titles",
          screenmode: s,
          screencriteria: "A",
          screensubcriteria: "",
          letternav: "",
        };
      });
    } else if (s == "publishers") {
      application.update((n) => {
        return {
          ...n,
          screen: "titles",
          screenmode: s,
          screencriteria: "",
          screensubcriteria: "",
          letternav: "",
        };
      });
    } else if (s == "categories") {
      application.update((n) => {
        return {
          ...n,
          screen: "titles",
          screenmode: s,
          screencriteria: "",
          screensubcriteria: "",
          letternav: "",
        };
      });
    } else if (s == "heroes") {
      application.update((n) => {
        return {
          ...n,
          screen: "titles",
          screenmode: s,
          screencriteria: "",
          screensubcriteria: "",
          letternav: "",
        };
      });
    } else if (s == "era") {
      application.update((n) => {
        return {
          ...n,
          screen: "titles",
          screenmode: s,
          screencriteria: "",
          screensubcriteria: "",
          letternav: "",
        };
      });
    }
    animateScroll.scrollToTop();
  }

  let data = [];
  let value = null;
  let text = "";
  let searchOpened = false;
  let highlightIndex = 0;
  let input, list;

  $: {
    const f = text ? text.toLowerCase() : "";
    if (f != "") getData(f);
    else data = [];
  }

  async function getData(qry) {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "titles/search/" + qry, options)
      .then((res) => res.json())
      .then((res) => {
        data = res;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  function selectItem() {
    if (data[highlightIndex]) {
      value = data[highlightIndex];
      text = value.Title;
      if (value.Series != "") text += " (" + value.Series + ")";
      goToIssues(value.TS_Key);
      document.getElementById("topNavSearch").blur();
      searchClose();
    }
  }

  function up() {
    searchOpen();
    if (highlightIndex > 0) highlightIndex--;
    highlight();
  }

  function down() {
    searchOpen();
    if (highlightIndex < data.length - 1) highlightIndex++;
    highlight();
  }

  function highlight() {
    const el = list.querySelector(".selected");
    if (el) el.scrollIntoViewIfNeeded();
  }

  function onclick(item) {
    value = item;
    text = item.Title;
    if (item.Series != "") text += " (" + item.Series + ")";
    goToIssues(item.TS_Key);
    document.getElementById("topNavSearch").blur();
    searchClose();
  }

  function onDocumentClick(e) {
    if (
      !e.target.closest(".autocomplete") &&
      !e.target.closest(".clear-icon") &&
      !e.target.closest(".search-icon")
    )
      searchClose();
    if (
      !e.target.closest(".menu") &&
      !e.target.closest(".navbar-toggler") &&
      !e.target.closest(".navToggler")
    )
      menuOpened = false;
  }

  function onkeydown(e) {
    let key = e.key;
    if (key === "Tab" && e.shiftKey) key = "ShiftTab";
    const fnmap = {
      Tab: searchOpened ? down.bind(this) : null,
      ShiftTab: searchOpened ? up.bind(this) : null,
      ArrowDown: down.bind(this),
      ArrowUp: up.bind(this),
      Escape: onEsc.bind(this),
    };
    const fn = fnmap[key];
    if (typeof fn === "function") {
      e.preventDefault();
      fn(e);
    }
  }

  function oninput(e) {
    text = e.target.value;
    searchOpen();
    highlightIndex = 0;
  }

  function onkeypress(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      selectItem();
    }
  }

  function onEsc(e) {
    if (text) return clear();
    e.stopPropagation();
    if (searchOpened) {
      input.focus();
      searchClose();
    }
  }

  function clear() {
    text = "";
    setTimeout(() => input.focus());
  }

  function searchOpen() {
    searchOpened = true;
  }

  function searchClose() {
    searchOpened = false;
  }

  function doLogout() {
    user.update((n) => {
      return {};
    });
    session.update((n) => {
      return {
        ...n,
        token: app.token,
        shippingSelected: null,
        selectedAddress: null,
        paymentType: null,
        lastOrderNum: null,
      };
    });
  }
</script>

<svelte:window on:click={onDocumentClick} />

<nav class="navbar fixed-top navbar-dark m-0 p-1 nkbgred">
  <button
    class="navbar-toggler border-0 p-0 m-0 mr-1"
    aria-expanded="false"
    aria-label="Toggle navigation"
    on:click|preventDefault={menuToggle}
  >
    <span class="navbar-toggler-icon" />
  </button>
  <div
    class="navbar-brand mr-auto"
    role="button"
    tabindex="0"
    on:click|preventDefault={() => goTo("home")}
    on:keypress|preventDefault={() => goTo("home")}
  >
    <img src="img/svg/logo.svg" alt="NewKadia.com Logo" />
  </div>
  <div class="navbar-nav flex-row">
    {#if usr && usr.Cust_ID && usr.Cust_ID > 0}
      <div class="nav-item">
        <div
          class="nav-link p-1"
          role="button"
          tabindex="0"
          on:click|preventDefault={doLogout}
          on:keypress|preventDefault={doLogout}
        >
          <FontAwesomeIcon icon={faDoorOpen} size="2x" />
        </div>
      </div>
    {/if}
    <div class="nav-item">
      <div
        class="nav-link p-1{app.screen == 'account' ? ' active' : ''}"
        role="button"
        tabindex="0"
        on:click|preventDefault={() =>
          goTo(usr && usr.Cust_ID && usr.Cust_ID > 0 ? "account" : "login")}
        on:keypress|preventDefault={() =>
          goTo(usr && usr.Cust_ID && usr.Cust_ID > 0 ? "account" : "login")}
      >
        <FontAwesomeIcon icon={faUserCircle} size="2x" />
      </div>
    </div>
    <div class="nav-item" style="position:relative;">
      <div
        class="nav-link p-1 pr-2{app.screen == 'cart' ? ' active' : ''}"
        role="button"
        tabindex="0"
        on:click|preventDefault={() => goTo("cart")}
        on:keypress|preventDefault={() => goTo("cart")}
      >
        <FontAwesomeIcon icon={faShoppingCart} size="2x" />
        {#if cartCount > 0}<div class="badge">{cartCount}</div>{/if}
      </div>
    </div>
  </div>
  <div class="flex-row w-100 m-0 p-0">
    <div class="col-12 m-0 p-1" style="position:relative;">
      <div
        class="search-icon"
        role="button"
        tabindex="0"
        on:click|preventDefault={input.focus()}
        on:keypress|preventDefault={input.focus()}
      >
        <FontAwesomeIcon icon={faSearch} />
      </div>
      <input
        type="text"
        class="searchCriteria"
        id="topNavSearch"
        placeholder="Search by Comic's Name"
        autocomplete="off"
        bind:this={input}
        bind:value={text}
        on:input={oninput}
        on:focus={searchOpen}
        on:keydown={onkeydown}
        on:keypress={onkeypress}
      />{#if searchOpened}<div
          class="nklightgrey clear-icon"
          role="button"
          tabindex="0"
          on:click|preventDefault={clear}
          on:keypress|preventDefault={clear}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>{/if}
      <div
        class="autocomplete-list{searchOpened ? '' : ' hidden'}"
        bind:this={list}
      >
        {#if data.length == 0}
          {#if text == ""}
            <p class="text-center"><em>Type to start search</em></p>
          {:else}
            <p class="text-center"><em>No results found</em></p>
          {/if}
        {:else}
          {#each data as item, i}
            <div
              class="autocomplete-list-item {i === highlightIndex
                ? 'selected'
                : ''}"
              role="button"
              tabindex="0"
              on:click={() => onclick(item)}
              on:keydown={() => onclick(item)}
            >
              {@html item.Title}
              {#if item.Series && item.Series != ""}
                ({@html item.Series})
              {/if}
            </div>
          {/each}
        {/if}
      </div>
    </div>
  </div>
  <div class="navbar-nav flex-row w-100">
    <div class="nav-item col-3 text-center">
      <div
        class="nav-link p-1{app.screen == 'titles' && app.screenmode == 'alpha'
          ? ' nkyellow'
          : ''}"
        role="button"
        tabindex="0"
        on:click|preventDefault={() => goToTitles("alpha")}
        on:keypress|preventDefault={() => goToTitles("alpha")}
      >
        A to Z
      </div>
    </div>
    <div class="nav-item col-3 text-center">
      <div
        class="nav-link p-1{app.screen == 'titles' &&
        (app.screenmode == 'publishers' || app.screenmode == 'otherpublishers')
          ? ' nkyellow'
          : ''}"
        role="button"
        tabindex="0"
        on:click|preventDefault={() => goToTitles("publishers")}
        on:keypress|preventDefault={() => goToTitles("publishers")}
      >
        Publishers
      </div>
    </div>
    <div class="nav-item col-3 text-center">
      <div
        class="nav-link p-1{app.screen == 'titles' &&
        app.screenmode == 'categories'
          ? ' nkyellow'
          : ''}"
        role="button"
        tabindex="0"
        on:click|preventDefault={() => goToTitles("categories")}
        on:keypress|preventDefault={() => goToTitles("categories")}
      >
        Categories
      </div>
    </div>
    <div class="nav-item col-3 text-center">
      <div
        class="nav-link p-1{app.screen == 'titles' && app.screenmode == 'heroes'
          ? ' nkyellow'
          : ''}"
        role="button"
        tabindex="0"
        on:click|preventDefault={() => goToTitles("heroes")}
        on:keypress|preventDefault={() => goToTitles("heroes")}
      >
        Heroes
      </div>
    </div>
  </div>
  <div
    class="m-0 p-0 border border-dark bgwhite black menu{menuOpened
      ? ''
      : ' hidden'}"
    style="top:100%;left:0;"
  >
    <div
      class="d-block p-2 m-0 border-bottom border-dark"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("home")}
      on:keypress|preventDefault={() => goTo("home")}
    >
      Home
    </div>
    <div
      class="d-block p-2 m-0 clearfix"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goToTitles("alpha")}
      on:keypress|preventDefault={() => goToTitles("alpha")}
    >
      Search Alphabetically by Title
    </div>
    <div
      class="d-block p-2 m-0 clearfix"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goToTitles("publishers")}
      on:keypress|preventDefault={() => goToTitles("publishers")}
    >
      By Publisher
    </div>
    <div
      class="d-block p-2 m-0 clearfix"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goToTitles("categories")}
      on:keypress|preventDefault={() => goToTitles("categories")}
    >
      By Category
    </div>
    <div
      class="d-block p-2 m-0 clearfix"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goToTitles("heroes")}
      on:keypress|preventDefault={() => goToTitles("heroes")}
    >
      By Hero
    </div>
    <div
      class="d-block p-2 m-0 clearfix border-bottom border-dark"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goToTitles("era")}
      on:keypress|preventDefault={() => goToTitles("era")}
    >
      By Era
    </div>
    <div
      class="d-block p-2 m-0"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("orders")}
      on:keypress|preventDefault={() => goTo("orders")}
    >
      Your Orders
    </div>
    <div
      class="d-block p-2 m-0"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("account")}
      on:keypress|preventDefault={() => goTo("account")}
    >
      Your Account
    </div>
    <div
      class="d-block p-2 m-0"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("giftcards")}
      on:keypress|preventDefault={() => goTo("giftcards")}
    >
      Gift Cards
    </div>
    <div
      class="d-block p-2 m-0"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("customerservice")}
      on:keypress|preventDefault={() => goTo("customerservice")}
    >
      Customer Service
    </div>
  </div>
</nav>
