export const conditions = {
	"Poor" : "Poor",
	"Fair -" : "Fair-",
	"Fair" : "Fair",
	"Fair +" : "Fair+",
	"Good -" : "Good-",
	"Good-" : "Good-",
	"Good" : "Good",
	"Good +" : "Good+",
	"Very Good -" : "VG-",
	"Very Good" : "VG",
	"Very Good-" : "VG-",
	"Very Good +" : "VG+",
	"Fine -" : "Fine-",
	"Fine" : "Fine",
	"Fine +" : "Fine+",
	"Very Fine -" : "VF-",
	"Very Fine" : "VF",
	"Very Fine +" : "VF+",
	"Near Mint -" : "NM-",
	"Near Mint" : "NM",
	"Near Mint +" : "NM+",
	"Mint -" : "Mint-",
	"Mint" : "Mint",
	"Mint +" : "Mint+"
};
