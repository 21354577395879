import { writable } from "svelte/store";

export const application = writable({
  apiUrl: "API_URL",
  imgUrl: "https://s3.amazonaws.com/www.covernk.com",
  coverUrl: "https://www.covernk.com/Covers/Scans/",
  token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiIsImtpZCI6Im5ld2sifQ.eyJpc3MiOiJOZXdLYWRpYSBXZWJzaXRlIiwiaWF0IjoxNTM0OTE5OTQxLCJleHAiOjk5OTk5OTk5OTksImF1ZCI6Im5ld2thZGlhLmNvbSIsInN1YiI6IndlYm1hc3RlckBuZXdrYWRpYS5jb20iLCJzY29wZSI6WyJwdWJsaWMiXX0.1s0YOx6MJHAqgCBPTjwpcslk57VXuPd7X83HQjxOymFxFIslQTNplsAAQtXGAvN7QZ873Wqw4LENCc4fgZgW0Q",
  screen: "home",
  screenmode: "",
  screencriteria: "",
  screensubcriteria: "",
  letternav: "",
});

export const history = writable([]);

export const session = writable(
  JSON.parse(localStorage.getItem("session")) || {
    id: "",
    token:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiIsImtpZCI6Im5ld2sifQ.eyJpc3MiOiJOZXdLYWRpYSBXZWJzaXRlIiwiaWF0IjoxNTM0OTE5OTQxLCJleHAiOjk5OTk5OTk5OTksImF1ZCI6Im5ld2thZGlhLmNvbSIsInN1YiI6IndlYm1hc3RlckBuZXdrYWRpYS5jb20iLCJzY29wZSI6WyJwdWJsaWMiXX0.1s0YOx6MJHAqgCBPTjwpcslk57VXuPd7X83HQjxOymFxFIslQTNplsAAQtXGAvN7QZ873Wqw4LENCc4fgZgW0Q",
    country: "US",
    countryLoaded: false,
    currency: "USD",
    email: "",
    lastSubNavLetter: "",
  }
);

export const user = writable({});

export const cart = writable({});
