<script>
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import DatePicker from "./DatePicker.svelte";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let gcTypes = [
    [55, "Email"],
    [50, "U.S. Mail"],
  ];
  let gcType = gcTypes[0][0];
  let emailAddress = "";
  let amount = 0.0;
  let emailDate = new Date();
  emailDate =
    emailDate.getMonth() +
    1 +
    "/" +
    emailDate.getDate() +
    "/" +
    emailDate.getFullYear();
  let message = "";

  let errArr = {};
  let isAdding = false;
  async function addToCart(event) {
    event.target.blur();
    if (!isAdding) {
      isAdding = true;
      errArr = {};
      if (amount == "" || amount <= 0)
        errArr["amount"] = "Please enter an amount";
      if (gcType == 55 && emailDate == "")
        errArr["emailDate"] = "Please select a date";
      if (gcType == 55 && emailAddress == "")
        errArr["emailAddress"] = "Please enter an email address";
      if (message == "")
        errArr["message"] = "Please enter a message for the gift card";
      if (Object.keys(errArr).length <= 0) {
        document.getElementById("dinger").volume = 0;
        document.getElementById("dinger").play();
        var options = {
          method: "POST",
          cache: "no-cache",
          headers: {
            Authorization: "Bearer " + sess.token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currency: sess.currency,
            sessionid: sess.id,
            giftcard: {
              type: gcType,
              amount: amount,
              date: emailDate,
              email: emailAddress,
              message: message,
            },
          }),
        };
        await fetch(app.apiUrl + "cart/addgiftcard", options)
          .then((res) => res.json())
          .then((res) => {
            res.map((element) => {
              if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
                element.Ref = JSON.parse(element.Ref);
              else if (
                element.Ref != "" &&
                element.Ref.indexOf("?Comics=") > -1
              ) {
                var tmp = element.Ref.split("=");
                tmp = tmp[1].split("#");
                tmp = tmp[0].split("|");
                element.Ref = [tmp[0], "", tmp[1], "", ""];
              }
            });
            cart.update((n) => {
              return res;
            });
            amount = 0;
            emailDate = new Date();
            emailDate =
              emailDate.getMonth() +
              1 +
              "/" +
              emailDate.getDate() +
              "/" +
              emailDate.getFullYear();
            emailAddress = "";
            message = "";
            if (
              navigator.vibrate ||
              navigator.webkitVibrate ||
              navigator.mozVibrate ||
              navigator.msVibrate
            )
              navigator.vibrate(200);
            else {
              document.getElementById("dinger").volume = 1;
              document.getElementById("dinger").play();
            }
            isAdding = false;
          })
          .catch((err) => {
            /*console.log(err)*/
          });
      } else isAdding = false;
    }
  }
</script>

<div class="row m-0 p-0 border-bottom border-dark">
  <div class="col-12 m-0 p-0 text-center nkred">
    <h4 class="title">Gift Cards</h4>
  </div>
</div>
<div class="row m-0 px-0 py-1 pt-2">
  <div class="col-12">
    <p>
      <strong>Email Gift Cards &ndash;</strong> Email to recipient or yourself. We
      will email today, or you can pick a date. A copy of the gift card will also
      be emailed to you.
    </p>
    <p>
      <strong>Mailed Gift Cards &ndash;</strong> Sent 1st Class. Priority, 1 or
      2-day available for an extra charge. If also ordering books, books and
      card are shipped together. An email will be sent to you when we mail the
      card.
      <em
        >To buy more than 1 shipped to different addresses - Add 1 to your Cart
        and Checkout. Then, for each additional Card, repeat the process.</em
      >
    </p>
  </div>
</div>
<div class="row m-0 px-0 py-1">
  <div class="col-4"><strong>Delivery:</strong></div>
  <div class="col-8">
    <select class="pad25_2" bind:value={gcType}>
      {#each gcTypes as t}<option value={t[0]}>{t[1]}</option>{/each}
    </select>
  </div>
</div>
<div class="row m-0 px-0 py-1">
  <div class="col-4"><strong>Amount $</strong></div>
  <div class="col-8">
    <input
      type="number"
      max="9999.00"
      min="1.00"
      step="1.00"
      class="pad12"
      bind:value={amount}
    /> <em>USD</em>
    {#if errArr.amount}<br /><span class="small nkred">{errArr.amount}</span
      >{/if}
  </div>
</div>
{#if gcType == 55}
  <div class="row m-0 px-0 py-1">
    <div class="col-4"><strong>Send on:</strong></div>
    <div class="col-8">
      <DatePicker dateValue={emailDate} start={new Date()} />
      {#if errArr.emailDate}<br /><span class="small nkred"
          >{errArr.emailDate}</span
        >{/if}
    </div>
  </div>
  <div class="row m-0 px-0 py-1">
    <div class="col-4"><strong>Send to Email:</strong></div>
    <div class="col-8">
      <input
        type="text"
        class="pad12"
        style="width:100%;"
        bind:value={emailAddress}
      />
      {#if errArr.emailAddress}<br /><span class="small nkred"
          >{errArr.emailAddress}</span
        >{/if}
    </div>
  </div>
{/if}
<div class="row m-0 px-0 py-1">
  <div class="col-4"><strong>Message:</strong></div>
  <div class="col-8">
    <textarea bind:value={message} rows="5" style="width:100%;" />
    {#if errArr.message}<br /><span class="small nkred">{errArr.message}</span
      >{/if}
  </div>
</div>
<div class="row m-0 px-0 py-1">
  <div class="col-1" />
  <div class="col-10 text-center">
    <button
      class="btn btn-primary border-0 nkbgred white d-block my-2 mr-auto ml-auto px-5{isAdding
        ? ' btn-pulse-red'
        : ''}"
      on:click|preventDefault={(event) => addToCart(event)}>Add to Cart</button
    >
  </div>
  <div class="col-1" />
</div>
