<script>
  import { application, session, cart, user } from "../stores/stores.js";
  import Login from "./Login.svelte";
  import Addresses from "./Addresses.svelte";
  import * as animateScroll from "svelte-scrollto";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import { faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  let thisUser = null;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
    if (value.Cust_ID && value.Cust_ID > 0 && thisUser == null) getUser();
  });

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  let emailErr = "";
  let passErr = "";
  let passConfirmErr = "";

  let emailEdit = false;
  let passEdit = false;
  function toggle(val) {
    if (val) return false;
    else return true;
  }

  let password = "";
  let passConfirm = "";
  async function updatePassword(ev) {
    if (password == "") passErr = "Please enter your new password";
    else if (passConfirm == "")
      passConfirmErr = "Please confirm your new password";
    else if (password != passConfirm) passConfirmErr = "Does not match";
    if (passErr == "" && passConfirmErr == "") {
      thisUser.Password = password;
      await updateUser(ev);
      delete thisUser.Password;
    }
  }

  async function updateUser(ev) {
    if (ev.target.querySelector("div").style.display != "inline-block") {
      emailErr = "";
      passErr = "";
      passConfirmErr = "";
      var options = {
        method: "PUT",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(thisUser),
      };
      ev.target.querySelector("div").style.display = "inline-block";
      await fetch(app.apiUrl + "active_customers/" + thisUser.Cust_ID, options)
        .then((res) => res.json())
        .then((res) => {
          ev.target.querySelector("div").style.display = "none";
          if (typeof res.errors != "undefined") {
            if (typeof res.errors.Cust_E_Mail != "undefined")
              emailErr = res.errors.Cust_E_Mail;
          } else {
            user.update((n) => {
              return {
                ...n,
                Cust_Nik: thisUser.Cust_Nik,
                Cust_E_Mail: thisUser.Cust_E_Mail,
                Phone: thisUser.Phone,
              };
            });
            session.update((n) => {
              return { ...n, email: thisUser.Cust_E_Mail };
            });
            if (ev.target.classList.contains("emailEdit")) emailEdit = false;
            else if (ev.target.classList.contains("passEdit")) passEdit = false;
          }
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    }
  }

  async function getUser() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "active_customers/" + usr.Cust_ID, options)
      .then((res) => res.json())
      .then((res) => {
        thisUser = res;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  let addresses = [];
  function handleAddressMessage(event) {
    addresses = event.detail.data;
  }
</script>

{#if usr && usr.Cust_ID && usr.Cust_ID > 0}
  <div class="row m-1 p-0 border-bottom border-dark">
    <div class="col-12 m-0 py-0 px-2 nkred text-center">
      <h4 class="title">Account #{usr.Cust_ID}</h4>
    </div>
  </div>
  <div class="row m-1 p-1 border-bottom border-dark">
    <div
      class="col-12 m-0 py-0 px-2 clearfix"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => goTo("orders")}
      on:keypress|preventDefault={() => goTo("orders")}
    >
      <h5 class="d-inline">Past Orders</h5>
      <div class="float-right navarrow" style="display:inline-block;">
        <FontAwesomeIcon icon={faArrowCircleRight} />
      </div>
    </div>
  </div>
  <div class="row m-1 mb-3 p-1 border-bottom border-dark">
    <div class="col-12 m-0 py-0 px-2">
      <h5>Edit Account Settings</h5>
      <div class="row m-1 p-1 border-bottom border-grey">
        <div class="col-12 m-0 p-0 clearfix">
          <strong>Email</strong><br />
          {#if emailEdit}
            <input
              type="text"
              class="w-100"
              bind:value={thisUser.Cust_E_Mail}
            />
            <div
              class="invalid-feedback"
              style={emailErr != "" ? "display:block;" : ""}
            >
              {emailErr}
            </div>
            <button
              class="btn btn-primary btn-sm m-1 float-right emailEdit"
              on:click|preventDefault={(event) => updateUser(event)}
              >Save
              <div
                class="spinner-grow spinner-grow-sm m-0 ml-1 p-0 align-middle"
                style="display:none;"
              /></button
            >
            <button
              class="btn btn-secondary btn-sm m-1 float-right"
              on:click|preventDefault={() => {
                emailEdit = toggle(emailEdit);
              }}>Cancel</button
            >
          {:else}
            <div class="container clearfix">
              {thisUser ? thisUser.Cust_E_Mail : ""}
              <button
                class="btn btn-secondary btn-sm float-right"
                on:click|preventDefault={() => {
                  emailEdit = toggle(emailEdit);
                }}>Edit</button
              >
            </div>
          {/if}
        </div>
      </div>
      <div class="row m-1 p-1 border-bottom border-grey">
        <div class="col-12 m-0 p-0">
          <strong>Password</strong><br />
          {#if passEdit}
            <input
              type="password"
              class="w-100"
              bind:value={password}
              data-openreplay-obscured
            />
            <div
              class="invalid-feedback"
              style={passErr != "" ? "display:block;" : ""}
            >
              {passErr}
            </div>
            <strong>Confirm</strong><br />
            <input
              type="password"
              class="w-100"
              bind:value={passConfirm}
              data-openreplay-obscured
            />
            <div
              class="invalid-feedback"
              style={passConfirmErr != "" ? "display:block;" : ""}
            >
              {passConfirmErr}
            </div>
            <button
              class="btn btn-primary btn-sm m-1 float-right passEdit"
              on:click|preventDefault={(event) => updatePassword(event)}
              >Save
              <div
                class="spinner-grow spinner-grow-sm m-0 ml-1 p-0 align-middle"
                style="display:none;"
              /></button
            >
            <button
              class="btn btn-secondary btn-sm m-1 float-right"
              on:click|preventDefault={() => {
                passEdit = toggle(passEdit);
              }}>Cancel</button
            >
          {:else}
            <div class="container clearfix">
              ***** <button
                class="btn btn-secondary btn-sm float-right"
                on:click|preventDefault={() => {
                  passEdit = toggle(passEdit);
                }}>Edit</button
              >
            </div>
          {/if}
        </div>
      </div>
      <div class="row m-1 p-1 border-bottom border-grey">
        <div class="col-12 m-0 p-0">
          <strong>Addresses</strong><br />
          <Addresses on:message={handleAddressMessage} />
        </div>
      </div>
      {#if thisUser && thisUser.Consignor_ID != ""}
        <div class="row m-1 p-1 border-bottom border-grey">
          <div class="col-12 m-0 p-0">
            <strong>Selling Comics?</strong><br />
            <div
              class="container clearfix w-75 mw-75 ml-5 pl-5"
              role="button"
              tabindex="0"
              on:click|preventDefault={() => goTo("sellcomics")}
              on:keypress|preventDefault={() => goTo("sellcomics")}
            >
              Get info
              <div
                class="float-right pt-1 nkblue"
                style="display:inline-block;"
              >
                <FontAwesomeIcon icon={faArrowCircleRight} />
              </div>
            </div>
          </div>
        </div>
      {/if}
      <div class="row m-1 p-1">
        <div class="col-12 m-0 p-0">
          <strong>Promotional Emails</strong><br />
          <small>Click the link at the bottom of any email to unsubscribe</small
          >
        </div>
      </div>
    </div>
  </div>
{:else}
  <Login />
{/if}
