<script>
  import { onMount } from "svelte";
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { conditions } from "../shared/Conditions.js";
  import {
    countries,
    usStates,
    auStates,
    esStates,
    gbStates,
    jpStates,
    caStates,
  } from "../shared/CountriesStates.js";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import { faMoneyCheckEditAlt } from "@fortawesome/pro-solid-svg-icons";
  import { faMoneyCheckAlt } from "@fortawesome/pro-regular-svg-icons";
  import { faCcPaypal } from "@fortawesome/free-brands-svg-icons";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let crt;
  const cartUnsubscribe = cart.subscribe((value) => {
    crt = value;
  });

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function goToIssues(tskey, subseries, issuenumber) {
    application.update((n) => {
      return {
        ...n,
        screen: "issues",
        screenmode: tskey,
        screencriteria: subseries,
        screensubcriteria: issuenumber,
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  let isWorking = true;

  let totalItems = 0;
  let bookCount = 0;
  let framedCount = 0;
  let regulars = 0;
  let regularsTotal = 0.0;
  let cheaps = 0;
  let cheapsTotal = 0;
  let freebies = 0;
  let primeTotal = 0;
  let giftCerts = 0;
  let pGiftCerts = 0;
  let pGiftCertsTotal = 0.0;
  let eGiftCerts = 0;
  let eGiftCertsTotal = 0.0;
  let coupons = 0;
  let couponType = "";
  let totalCouponDisc = 0.0;
  let couponDiscPercent = 0.0;
  let couponDiscSubTotal = 0.0;
  let totalDollarCouponDisc = 0.0;
  let dollarCouponDiscPercent = 0.0;
  let dollarCouponDiscSubTotal = 0.0;
  let subTotalTaxable = 0.0;
  let subTotalNonTaxable = 0.0;
  let subTotal = 0.0;
  let totalDisc = 0.0;
  let discPercent = 0.0;
  let discSubTotal = 0.0;
  let giftCreditsAmount = 0.0;
  let tax = "TBD";
  let shipping = "TBD";
  let total = "TBD";
  function reCalculateCart() {
    totalItems = 0;
    bookCount = 0;
    framedCount = 0;
    regulars = 0;
    regularsTotal = 0.0;
    cheaps = 0;
    cheapsTotal = 0;
    freebies = 0;
    primeTotal = 0;
    giftCerts = 0;
    pGiftCerts = 0;
    pGiftCertsTotal = 0.0;
    eGiftCerts = 0;
    eGiftCertsTotal = 0.0;
    coupons = 0;
    couponType = "";
    totalCouponDisc = 0.0;
    couponDiscPercent = 0.0;
    couponDiscSubTotal = 0.0;
    totalDollarCouponDisc = 0.0;
    dollarCouponDiscPercent = 0.0;
    dollarCouponDiscSubTotal = 0.0;
    subTotalTaxable = 0.0;
    subTotalNonTaxable = 0.0;
    subTotal = 0.0;
    totalDisc = 0.0;
    discPercent = 0.0;
    discSubTotal = 0.0;
    giftCreditsAmount = 0.0;
    tax = "TBD";
    shipping = "TBD";
    total = "TBD";
    if (crt && Array.isArray(crt)) {
      crt.forEach((element) => {
        if (element.Status != "X") {
          if (element.Typ == 0) {
            // regular comics
            regulars++;
            regularsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 10) {
            // bulk/shirts
            cheaps++;
            cheapsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 20) {
            // free comics
            freebies++;
          } else if (element.Typ == 50) {
            // paper gift certs
            pGiftCerts++;
            pGiftCertsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 55) {
            // email gift certs
            eGiftCerts++;
            eGiftCertsTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 70 || element.Typ == 75) {
            // prime
            primeTotal += parseFloat(element.Quoted_Price);
          } else if (element.Typ == 140) {
            // $ discount coupons
            coupons++;
            couponType = "$";
            totalCouponDisc =
              parseFloat(element.Unit_Price) <= 0
                ? parseFloat(element.Unit_Price)
                : parseFloat(element.Unit_Price) * -1;
            totalDisc += totalCouponDisc;
          } else if (element.Typ == 141) {
            // $ off entire order coupons
            coupons++;
            couponType = "$off";
            totalDollarCouponDisc =
              parseFloat(element.Quoted_Price) <= 0
                ? parseFloat(element.Quoted_Price)
                : parseFloat(element.Quoted_Price) * -1;
            totalDisc += totalDollarCouponDisc;
          } else if (element.Typ == 145) {
            // % discount coupons
            coupons++;
            couponType = "%";
            totalCouponDisc =
              parseFloat(element.Quoted_Price) <= 0
                ? parseFloat(element.Quoted_Price)
                : parseFloat(element.Quoted_Price) * -1;
            totalDisc += totalCouponDisc;
          } else if (element.Typ == 150) {
            // gift credits
            giftCreditsAmount += element.Unit_Price;
          }
        }
        if (/framed and matted/gi.test(element.Comic_Name)) framedCount++;
      });
      bookCount = regulars + cheaps + freebies;
      giftCerts = pGiftCerts + eGiftCerts;
      totalItems = bookCount + giftCerts + (primeTotal > 0 ? 1 : 0);
      subTotalTaxable = regularsTotal + cheapsTotal;
      subTotalNonTaxable = pGiftCertsTotal + eGiftCertsTotal + primeTotal;
      subTotal = subTotalTaxable + subTotalNonTaxable;
      if (bookCount == 0 && pGiftCerts == 0 && eGiftCerts > 0) shipping = 0.0;
      else if (sess.shippingSelected)
        shipping = parseFloat(sess.shippingSelected.Shipping_Chrg);
      couponDiscSubTotal = subTotal + totalCouponDisc;
      if (subTotal > 0)
        couponDiscPercent =
          1 -
          (couponDiscSubTotal - eGiftCertsTotal) / (subTotal - eGiftCertsTotal);
      dollarCouponDiscSubTotal = subTotal + totalDollarCouponDisc;
      if (subTotal > 0)
        dollarCouponDiscPercent = 1 - dollarCouponDiscSubTotal / subTotal;
      discSubTotal = subTotal + totalDisc;
      if (subTotal > 0)
        discPercent =
          1 - (discSubTotal - eGiftCertsTotal) / (subTotal - eGiftCertsTotal);
      if (discSubTotal < 0) {
        if (totalDisc + subTotal + shipping <= 0)
          totalDisc = -1 * (subTotal + shipping);
        discSubTotal = 0;
        discPercent = 1.0;
      }
      if (taxKey != "" && taxable[taxKey] && subTotalTaxable > 0) {
        if (subTotalNonTaxable > 0)
          tax = subTotalTaxable * (1 - discPercent) * taxable[taxKey];
        else tax = discSubTotal * taxable[taxKey];
      } else tax = 0.0;
      total =
        subTotal +
        (sess.shippingSelected ? shipping : 0) +
        (tax != "TBD" ? tax : 0) +
        totalDisc;
      if (giftCreditsAmount > 0)
        total = total - giftCreditsAmount > 0 ? total - giftCreditsAmount : 0;
    }
  }

  function formatMoney(number, decPlaces, decSep, thouSep) {
    (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
      (decSep = typeof decSep === "undefined" ? "." : decSep);
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    if (sess.currency == "USD") sign = "$" + sign;
    var i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : "") +
      (sess.currency != "USD" ? "<small>" + sess.currency + "</small>" : "")
    );
  }

  let taxable = {};
  let taxKey = "";
  async function getTaxes() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "adm_options/taxes", options)
      .then((res) => res.json())
      .then((res) => {
        taxable = res;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }
  function updateTaxKey() {
    if (sess.country && sess.country != "") {
      taxKey = sess.country;
      if (["US", "AU", "ES", "CA"].indexOf(sess.country) > -1)
        taxKey += sess.state;
      else if (sess.state && sess.state != "")
        taxKey += sess.state.replaceAll(" ", "");
      reCalculateCart();
    }
  }

  let orderId;
  let addressCountry = sess.country;
  let payPalResponse = "";
  async function loadScript(src, token) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.dataset.clientToken = token;
      document.body.appendChild(script);
      script.addEventListener("load", () => resolve(script));
      script.addEventListener("error", () => reject(script));
    });
  }
  async function getPayPalToken() {
    const options = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + sess.token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await fetch(app.apiUrl + "cust_payments/token", options)
      .then((res) => res.json())
      .then((res) => {
        loadScript(
          "https://www.paypal.com/sdk/js?components=hosted-fields&client-id=" +
            res.clientid,
          res.token
        ).then(() => {
          let element = document.getElementById("card-number");
          while (!element) {
            element = document.getElementById("card-number");
          }
          paypal.HostedFields.render({
            createOrder: () => {
              return fetch(app.apiUrl + "cust_payments/createorder", {
                method: "POST",
                cache: "no-cache",
                headers: {
                  Authorization: "Bearer " + sess.token,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  purchaseAmount: total,
                }),
              })
                .then((res) => res.json())
                .then((orderData) => {
                  orderId = orderData.id;
                  return orderData.id;
                });
            },
            styles: {
              ".valid": {
                color: "green",
              },
              ".invalid": {
                color: "red",
              },
            },
            fields: {
              number: {
                selector: "#card-number",
                placeholder: "4111 1111 1111 1111",
              },
              cvv: {
                selector: "#cvv",
                placeholder: "123",
              },
              expirationDate: {
                selector: "#expiration-date",
                placeholder: "MM/YY",
              },
            },
          }).then((cardFields) => {
            document
              .querySelector("#btnPlaceOrder")
              .addEventListener("click", (event) => {
                isWorking = true;
                event.preventDefault();
                let errMsg = "";
                if (document.getElementById("card-holder-name").value == "")
                  errMsg += "Please enter credit card holder's name\n";
                if (
                  document.getElementById("card-billing-address-street")
                    .value == ""
                )
                  errMsg += "Please enter billing street address\n";
                if (
                  document.getElementById("card-billing-address-state").value ==
                  ""
                )
                  errMsg += "Please enter billing state / province\n";
                if (
                  document.getElementById("card-billing-address-city").value ==
                  ""
                )
                  errMsg += "Please enter billing city\n";
                if (
                  document.getElementById("card-billing-address-zip").value ==
                  ""
                )
                  errMsg += "Please enter billing zip / postal code\n";
                if (
                  document.getElementById("card-billing-address-country")
                    .value == ""
                )
                  errMsg += "Please enter billing country\n";
                if (errMsg != "") {
                  isWorking = false;
                  return alert(errMsg);
                }
                cardFields
                  .submit({
                    cardholderName:
                      document.getElementById("card-holder-name").value,
                    billingAddress: {
                      streetAddress: document.getElementById(
                        "card-billing-address-street"
                      ).value,
                      extendedAddress: document.getElementById(
                        "card-billing-address-unit"
                      ).value,
                      region: document.getElementById(
                        "card-billing-address-state"
                      ).value,
                      locality: document.getElementById(
                        "card-billing-address-city"
                      ).value,
                      postalCode: document.getElementById(
                        "card-billing-address-zip"
                      ).value,
                      countryCodeAlpha2: document.getElementById(
                        "card-billing-address-country"
                      ).value,
                    },
                  })
                  .then(() => {
                    fetch(app.apiUrl + "cust_payments/captureorder", {
                      method: "POST",
                      cache: "no-cache",
                      headers: {
                        Authorization: "Bearer " + sess.token,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify({
                        orderId: orderId,
                      }),
                    })
                      .then((res) => res.json())
                      .then((orderData) => {
                        isWorking = false;
                        payPalResponse = JSON.stringify(orderData);
                        if (
                          orderData &&
                          orderData.purchase_units &&
                          orderData.purchase_units[0] &&
                          orderData.purchase_units[0].payments &&
                          orderData.purchase_units[0].payments.captures &&
                          orderData.purchase_units[0].payments.captures[0] &&
                          orderData.purchase_units[0].payments.captures[0]
                            .status &&
                          orderData.purchase_units[0].payments.captures[0]
                            .status === "COMPLETED"
                        ) {
                          completeOrder();
                        } else {
                          alert("Card was declined");
                        }
                      });
                  })
                  .catch((err) => {
                    isWorking = false;
                    let msg = "";
                    if (err.details && Array.isArray(err.details)) {
                      const alreadySeen = [];
                      for (let i = 0; i < err.details.length; i++) {
                        const e = err.details[i];
                        switch (e.field) {
                          case "/payment_source/card/number":
                            if (
                              alreadySeen.indexOf(
                                "/payment_source/card/number"
                              ) == -1
                            ) {
                              alreadySeen.push("/payment_source/card/number");
                              msg += "Please check the credit card number\n";
                            }
                            break;
                          case "/payment_source/card/expiry":
                            if (
                              alreadySeen.indexOf(
                                "/payment_source/card/expiry"
                              ) == -1
                            ) {
                              alreadySeen.push("/payment_source/card/expiry");
                              msg +=
                                "Please check the credit card expiration date, confirm it's in MM/YY format\n";
                            }
                            break;
                          case "/payment_source/card/billing_address/country_code":
                            if (
                              alreadySeen.indexOf(
                                "/payment_source/card/billing_address/country_code"
                              ) == -1
                            ) {
                              alreadySeen.push(
                                "/payment_source/card/billing_address/country_code"
                              );
                              msg += "Please check the billing country\n";
                            }
                            break;
                          default:
                            if (alreadySeen.indexOf("default") == -1) {
                              alreadySeen.push("default");
                              msg +=
                                "There was an error processing your credit card, please double check your entries\n";
                            }
                            break;
                        }
                      }
                    }
                    return alert(msg);
                  });
              });
          });
          isWorking = false;
        });
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  onMount(async () => {
    if (crt && crt.length) {
      await getTaxes();
      updateTaxKey();
      if (sess.paymentType == "cc") {
        await getPayPalToken();
      }
    }
    isWorking = false;
  });

  async function completeOrder() {
    if (!isWorking) {
      isWorking = true;
      var options = {
        method: "POST",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          currency: sess.currency,
          sessionid: sess.id,
          payPalResponse: payPalResponse,
          Addr_ID:
            typeof sess.selectedAddress.Addr_ID != "undefined"
              ? sess.selectedAddress.Addr_ID
              : 0,
          Pymnt_Type:
            typeof sess.paymentType != "undefined" ? sess.paymentType : "",
          giftMessage:
            typeof sess.giftMessage != "undefined" ? sess.giftMessage : "",
          shipBy:
            typeof sess.shippingSelected.ship_id != "undefined"
              ? sess.shippingSelected.ship_id
              : 0,
        }),
      };
      await fetch(app.apiUrl + "order_headers/makeorder", options)
        .then((res) => res.json())
        .then((res) => {
          session.update((n) => {
            return { ...n, lastOrderNum: res.orderNum };
          });
          if (sess.paymentType == "pp" && res.url != "") {
            window.location.href = res.url;
          } else {
            // if (res.errors.length > 0) {
            //   console.log(res);
            //   console.log(res.errors);
            // } else {
            if (res.totalDue > 0) goTo("invoice");
            else goTo("receipt");
            animateScroll.scrollToTop();
            // }
            cart.update((n) => {
              return {};
            });
            isWorking = false;
          }
        })
        .catch((err) => {
          alert(
            "There was an error processing this order, Email us at Support@NewKadia.com"
          );
          /*console.log(err);*/
        });
    }
  }
</script>

<div class="row m-0 p-0">
  <div class="col-12 m-0 p-0" style="position:relative;">
    {#if isWorking}
      <div class="subshade" />
      <div class="subshadeloader">
        <div class="container my-1 py-1 text-center">
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    {/if}
    <div class="row m-0 p-0 border-bottom border-dark">
      <div class="col-12 m-0 p-0 text-center nkred">
        <h4 class="title">Review Order</h4>
      </div>
    </div>
    {#if crt.length > 0}
      <div class="row m-0 px-0 py-1 small nkbglightergrey">
        <div class="col-7"><strong>Item</strong></div>
        <div class="col-2"><strong>Cond</strong></div>
        <div class="col-2"><strong>Price</strong></div>
      </div>
      {#each crt as row, ind}
        {#if row.Typ == 0 || row.Typ == 50 || row.Typ == 55 || row.Typ == 70 || row.Typ == 75}
          <div
            class="row m-0 px-0 py-1 small{row.Status == 'X'
              ? ' NKc_oosGrey'
              : ind % 2 == 0
              ? ' NKc_FBlue'
              : ' NKc_MBlue'}"
          >
            <div
              class="col-8 nkblue{row.Status == 'X' ? ' strike' : ''}"
              role="button"
              tabindex="0"
              on:click|preventDefault={() =>
                goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
              on:keypress|preventDefault={() =>
                goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
            >
              {@html row.Comic_Name}
            </div>
            <div class="col-2{row.Status == 'X' ? ' strike' : ''}">
              {row.Condition == "" ? "" : conditions[row.Condition]}
            </div>
            <div class="col-2 text-right{row.Status == 'X' ? ' strike' : ''}">
              {@html formatMoney(row.Quoted_Price)}
            </div>
          </div>
        {:else if row.Typ == 10}
          <div class="row m-0 px-0 py-1 small NKc_FGold">
            <div
              class="col-8 nkblue{row.Status == 'X' ? ' strike' : ''}"
              role="button"
              tabindex="0"
              on:click|preventDefault={() =>
                goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
              on:keypress|preventDefault={() =>
                goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
            >
              {row.Comic_Name}
            </div>
            <div class="col-2{row.Status == 'X' ? ' strike' : ''}">
              {row.Condition == "" ? "" : conditions[row.Condition]}
            </div>
            <div class="col-2 text-right{row.Status == 'X' ? ' strike' : ''}">
              {@html formatMoney(row.Quoted_Price)}
            </div>
          </div>
        {:else if row.Typ == 20}
          <div class="row m-0 px-0 py-1 small NKc_NGreen">
            <div
              class="col-8 nkblue{row.Status == 'X' ? ' strike' : ''}"
              role="button"
              tabindex="0"
              on:click|preventDefault={() =>
                goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
              on:keypress|preventDefault={() =>
                goToIssues(row.Ref[0], row.Ref[1], row.Ref[2])}
            >
              {row.Comic_Name}
            </div>
            <div class="col-2{row.Status == 'X' ? ' strike' : ''}">
              {row.Condition == "Free" ? "" : conditions[row.Condition]}
            </div>
            <div class="col-2 text-right{row.Status == 'X' ? ' strike' : ''}">
              {@html row.Quoted_Price > 0
                ? formatMoney(row.Quoted_Price)
                : "Free"}
            </div>
          </div>
        {:else if row.Typ == 140 || row.Typ == 141 || row.Typ == 145 || row.Typ == 150 || row.Typ == 200}
          <div class="row m-0 px-0 py-1 small NKc_LYellow">
            <div class="col-10">
              {@html row.Comic_Name}<br />{@html row.Condition}
            </div>
            <div class="col-2 text-right">
              {@html formatMoney(row.Quoted_Price)}
            </div>
          </div>
        {/if}
      {/each}
      {#if subTotal > 0}
        <div
          class="row m-0 p-0{crt.length % 2 == 0 ? ' NKc_FBlue' : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right">
            <strong>Product Total:</strong>
          </div>
          <div class="col-3 py-1 text-right">{@html formatMoney(subTotal)}</div>
        </div>
        {#if totalDisc < 0}
          <div
            class="row m-0 p-0{(crt.length + 1) % 2 == 0
              ? ' NKc_FBlue'
              : ' NKc_MBlue'}"
          >
            <div class="col-9 py-1 text-right"><strong>Discount:</strong></div>
            <div class="col-3 py-1 text-right">
              {@html formatMoney(totalDisc)}
            </div>
          </div>
        {/if}
        <div
          class="row m-0 p-0{(crt.length + (totalDisc < 0 ? 2 : 1)) % 2 == 0
            ? ' NKc_FBlue'
            : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right"><strong>Sales Tax:</strong></div>
          <div class="col-3 py-1 text-right">
            {@html isNaN(tax) ? tax : formatMoney(tax)}
          </div>
        </div>
        <div
          class="row m-0 p-0{(crt.length + (totalDisc < 0 ? 3 : 2)) % 2 == 0
            ? ' NKc_FBlue'
            : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right"><strong>Shipping:</strong></div>
          <div class="col-3 py-1 text-right">
            {@html isNaN(shipping) ? shipping : formatMoney(shipping)}
          </div>
        </div>
        <div
          class="row m-0 p-0{(crt.length + (totalDisc < 0 ? 4 : 3)) % 2 == 0
            ? ' NKc_FBlue'
            : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right"><strong>Insurance:</strong></div>
          <div class="col-3 py-1 text-right">Free</div>
        </div>
        <div
          class="row m-0 p-0{(crt.length + (totalDisc < 0 ? 5 : 4)) % 2 == 0
            ? ' NKc_FBlue'
            : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right">
            <strong>Comics bags/boards:</strong>
          </div>
          <div class="col-3 py-1 text-right">Free</div>
        </div>
        <div
          class="row m-0 p-0{(crt.length + (totalDisc < 0 ? 6 : 5)) % 2 == 0
            ? ' NKc_FBlue'
            : ' NKc_MBlue'}"
        >
          <div class="col-9 py-1 text-right"><strong>Total:</strong></div>
          <div class="col-3 py-1 text-right">
            {@html isNaN(total) ? total : formatMoney(total)}
          </div>
        </div>
      {/if}
    {/if}
    <div class="row m-0 p-0">
      <div class="col-12 py-1 nkbglightergrey">
        <div class="d-inline-block align-middle">
          <strong>Shipping To</strong>
        </div>
      </div>
    </div>
    {#if bookCount > 0 || pGiftCerts > 0}
      <div class="row m-0 p-0">
        <div class="col-12 py-1">
          {#if sess.selectedAddress && sess.selectedAddress.id > 0}
            {sess.selectedAddress.Name}<br />
            {#if sess.selectedAddress.Company && sess.selectedAddress.Company != ""}{sess
                .selectedAddress.Company}<br />{/if}
            {sess.selectedAddress.Addr_1}<br />
            {#if sess.selectedAddress.Suite && sess.selectedAddress.Suite != ""}{sess
                .selectedAddress.Suite}<br />{/if}
            {sess.selectedAddress.City}, {sess.selectedAddress.State}
            {sess.selectedAddress.ZIP_1}
            {sess.selectedAddress.Country}
          {/if}
        </div>
      </div>
    {/if}
    {#if eGiftCerts > 0}
      <div class="row m-0 p-0">
        <div class="col-12 py-1 pb-2 text-center italic">
          Email gift cards will be sent immediately upon payment
        </div>
      </div>
    {/if}
    <div class="row m-0 p-0">
      <div class="col-12 py-1 nkbglightergrey">
        <div class="d-inline-block align-middle"><strong>Payment</strong></div>
      </div>
    </div>
    {#if total > 0}
      <div class="row m-0 p-0">
        <div class="col-12 py-1">
          {#if sess.paymentType && sess.paymentType == "cc"}
            <div class="card_container">
              <input
                type="text"
                class="w-100 mb-1 px-1 border-red"
                placeholder="Card holder name"
                id="card-holder-name"
              />
              <label for="card-number">Card Number</label>
              <div
                id="card-number"
                class="card_field"
                data-openreplay-obscured
              />
              <div style="display: flex; flex-direction: row;">
                <div>
                  <label for="expiration-date">Expiration Date</label>
                  <div id="expiration-date" class="card_field" />
                </div>
                <div style="margin-left: 10px;">
                  <label for="cvv">CVV</label>
                  <div id="cvv" class="card_field" />
                </div>
              </div>
              <label for="card-billing-address-street">Billing Address</label>
              <input
                type="text"
                class="w-100 mb-1 px-1 border-red"
                placeholder="Street or P.O. Box"
                id="card-billing-address-street"
              />
              <input
                type="text"
                class="w-100 mb-1 px-1"
                placeholder="Apt, Suite, Unit or Bldg"
                id="card-billing-address-unit"
              />
              <input
                type="text"
                class="w-100 mb-1 px-1 border-red"
                placeholder="City"
                id="card-billing-address-city"
              />
              {#if addressCountry == "US"}
                <select
                  class="w-100 mb-1 px-1 border-red border-width-2"
                  id="card-billing-address-state"
                >
                  <option value="">- Select State -</option>
                  {#each usStates as st}
                    <option value={st.abbreviation}>{st.name}</option>
                  {/each}
                </select>
              {:else if addressCountry == "AU"}
                <select
                  class="w-100 mb-1 px-1 border-red border-width-2"
                  id="card-billing-address-state"
                >
                  <option value="">- Select State -</option>
                  {#each auStates as st}
                    <option value={st.abbreviation}>{st.name}</option>
                  {/each}
                </select>
              {:else if addressCountry == "ES"}
                <select
                  class="w-100 mb-1 px-1 border-red border-width-2"
                  id="card-billing-address-state"
                >
                  <option value="">- Select Province -</option>
                  {#each esStates as st}
                    <option value={st.abbreviation}>{st.name}</option>
                  {/each}
                </select>
              {:else if addressCountry == "GB"}
                <select
                  class="w-100 mb-1 px-1 border-red border-width-2"
                  id="card-billing-address-state"
                >
                  <option value="">- Select County -</option>
                  {#each gbStates as st}
                    <option value={st.name}>{st.region} - {st.name}</option>
                  {/each}
                </select>
              {:else if addressCountry == "JP"}
                <select
                  class="w-100 mb-1 px-1 border-red border-width-2"
                  id="card-billing-address-state"
                >
                  <option value="">- Select County -</option>
                  {#each jpStates as st}
                    <option value={st.name}>{st.name}</option>
                  {/each}
                </select>
              {:else if addressCountry == "CA"}
                <select
                  class="w-100 mb-1 px-1 border-red border-width-2"
                  id="card-billing-address-state"
                >
                  <option value="">- Select Province -</option>
                  {#each caStates as st}
                    <option value={st.abbreviation}>{st.name}</option>
                  {/each}
                </select>
              {:else}
                <input
                  type="text"
                  class="w-100 mb-1 px-1 border-red"
                  placeholder="State / Province"
                  id="card-billing-address-state"
                />
              {/if}
              <input
                type="text"
                class="w-100 mb-1 px-1 border-red"
                placeholder={addressCountry == "US"
                  ? "5-Digit Zip Code"
                  : "Postal Code"}
                id="card-billing-address-zip"
              />
              <select
                class="w-100 mb-1 px-1 border-red border-width-2"
                id="card-billing-address-country"
                bind:value={addressCountry}
              >
                {#each countries as country}
                  <option value={country.abbreviation}>{country.name}</option>
                {/each}
              </select>
            </div>
          {:else if sess.paymentType && sess.paymentType == "pp"}
            <FontAwesomeIcon icon={faCcPaypal} style="font-size:1.25em;" /> PayPal
          {:else if sess.paymentType && sess.paymentType == "ck"}
            <FontAwesomeIcon
              icon={faMoneyCheckEditAlt}
              style="font-size:1.25em;"
            /> Check
          {:else if sess.paymentType && sess.paymentType == "mo"}
            <FontAwesomeIcon icon={faMoneyCheckAlt} style="font-size:1.25em;" />
            Money Order
          {/if}
        </div>
      </div>
    {:else}
      <div class="row m-0 p-0">
        <div class="col-12 py-1 pb-2 text-center italic">
          No additional payment required
        </div>
      </div>
    {/if}
    <div class="row m-0 p-0">
      <div class="col-12 m-0 p-2 pt-3 text-center">
        {#if sess.paymentType != "cc"}
          <button
            class="btn btn-primary border-0 nkbgred white"
            style="width:calc(100% - 1.5rem);"
            on:click|preventDefault={completeOrder}>Place Your Order</button
          >
        {:else}
          <button
            class="btn btn-primary border-0 nkbgred white"
            style="width:calc(100% - 1.5rem);"
            id="btnPlaceOrder">Place Your Order</button
          >
        {/if}
      </div>
    </div>
  </div>
</div>
