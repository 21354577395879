<script>
  import { onMount } from "svelte";
  import * as animateScroll from "svelte-scrollto";
  import {
    application,
    history,
    session,
    cart,
    user,
  } from "./stores/stores.js";
  import Navbar from "./components/Navbar.svelte";
  import Home from "./components/Home.svelte";
  import Login from "./components/Login.svelte";
  import Account from "./components/Account.svelte";
  import Orders from "./components/Orders.svelte";
  import Titles from "./components/Titles.svelte";
  import Issues from "./components/Issues.svelte";
  import NewArrivals from "./components/NewArrivals.svelte";
  import NinetyPercent from "./components/NinetyPercent.svelte";
  import Recommendations from "./components/Recommendations.svelte";
  import GiftCards from "./components/GiftCards.svelte";
  import Cart from "./components/Cart.svelte";
  import Checkout from "./components/Checkout.svelte";
  import OrderReview from "./components/OrderReview.svelte";
  import Footer from "./components/Footer.svelte";
  import Privacy from "./components/Privacy.svelte";
  import FreeShipping from "./components/FreeShipping.svelte";
  import Shipping from "./components/Shipping.svelte";
  import Returns from "./components/Returns.svelte";
  import WinGiftCard from "./components/WinGiftCard.svelte";
  import SellComics from "./components/SellComics.svelte";
  import Sales from "./components/Sales.svelte";
  import CustomerService from "./components/CustomerService.svelte";
  import Invoice from "./components/Invoice.svelte";
  import Receipt from "./components/Receipt.svelte";

  import Tracker from "@openreplay/tracker";
  const tracker = new Tracker({
    projectKey: "7GKATC4io96vrEdbGM4m",
    ingestPoint: "https://observability.newkadia.com/ingest",
  });

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
    localStorage.setItem("session", JSON.stringify(value));
    tracker.setMetadata("sessionId", value.id);
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
    tracker.setUserID(value.Cust_E_Mail);
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
    history.update((h) => {
      h.push({
        screen: value.screen,
        screenmode: value.screenmode,
        screencriteria: value.screencriteria,
        screensubcriteria: value.screensubcriteria,
      });
      return h.slice(Math.max(h.length - 10, 0));
    });
  });

  async function getCart() {
    var options = {
      method: "GET",
      cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "cart/" + sess.currency + "/" + sess.id, options)
      .then((res) => res.json())
      .then((res) => {
        res.map((element) => {
          if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
            element.Ref = JSON.parse(element.Ref);
          else if (element.Ref != "" && element.Ref.indexOf("?Comics=") > -1) {
            var tmp = element.Ref.split("=");
            tmp = tmp[1].split("#");
            tmp = tmp[0].split("|");
            element.Ref = [tmp[0], "", tmp[1], "", ""];
          }
        });
        cart.update((n) => {
          return res;
        });
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  onMount(async () => {
    tracker.start();
    animateScroll.scrollToTop();
    var loading = document.getElementById("pageLoading");
    loading.parentNode.removeChild(loading);
    if (window.location.pathname.includes("/receipt")) {
      window.history.pushState(null, null, "/#");
      application.update((n) => {
        return {
          ...n,
          screen: "receipt",
          screenmode: "",
          screencriteria: "",
          screensubcriteria: "",
          letternav: "",
        };
      });
    }
    var options = {
      method: "POST",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + sess.token,
        Accept: "application/json",
      },
    };
    await fetch(app.apiUrl + "login", options)
      .then((res) => res.json())
      .then((res) => {
        if (typeof res.errors == "undefined") {
          session.update((n) => {
            return { ...n, token: res.token };
          });
          user.update((n) => {
            return res.user;
          });
        } else
          session.update((n) => {
            return { ...n, token: app.token };
          });
      })
      .catch((err) => {
        //console.log(err);
        session.update((n) => {
          return { ...n, token: app.token };
        });
      });
    if (typeof sess.id == "undefined" || sess.id == "") {
      options.headers["Authorization"] = "Bearer " + app.token;
      await fetch(app.apiUrl + "newsession", options)
        .then((res) => res.json())
        .then((res) => {
          session.update((n) => {
            return { ...n, id: res };
          });
          getCart();
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    } else {
      tracker.setMetadata("sessionId", sess.id);
      getCart();
    }
    if (!sess.countryLoaded) {
      await fetch("https://ipapi.co/json/")
        .then((res) => res.json())
        .then((res) => {
          session.update((n) => {
            return {
              ...n,
              countryLoaded: true,
              country: res.country,
              currency: res.currency,
            };
          });
        })
        .catch((err) => {
          /* console.log(err); */
        });
    }
  });
</script>

<Navbar />
<div style="display:block;margin:0;padding:0;height:128px;" />
{#if app.screen == "titles"}
  <Titles />
{:else if app.screen == "issues"}
  <Issues />
{:else if app.screen == "newarrivals"}
  <NewArrivals />
{:else if app.screen == "ninetypercent"}
  <NinetyPercent />
{:else if app.screen == "recommendations"}
  <Recommendations />
{:else if app.screen == "giftcards"}
  <GiftCards />
{:else if app.screen == "cart"}
  <Cart />
{:else if app.screen == "checkout"}
  <Checkout />
{:else if app.screen == "orderreview"}
  <OrderReview />
{:else if app.screen == "account"}
  <Account />
{:else if app.screen == "orders"}
  <Orders />
{:else if app.screen == "privacy"}
  <Privacy />
{:else if app.screen == "freeshipping"}
  <FreeShipping />
{:else if app.screen == "shipping"}
  <Shipping />
{:else if app.screen == "returns"}
  <Returns />
{:else if app.screen == "wingiftcard"}
  <WinGiftCard />
{:else if app.screen == "sellcomics"}
  <SellComics />
{:else if app.screen == "sales"}
  <Sales />
{:else if app.screen == "customerservice"}
  <CustomerService />
{:else if app.screen == "invoice"}
  <Invoice />
{:else if app.screen == "receipt"}
  <Receipt />
{:else if app.screen == "login"}
  <Login goBack="true" />
{:else}
  <Home />
{/if}
<audio preload="metadata" id="dinger"><source src="ding.aac" /></audio>
<Footer />
