<script>
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { conditions } from "../shared/Conditions.js";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let discounts = [
    ["95% - 76%", 0.7599, 1],
    ["75% - 66%", 0.6599, 0.7599],
    ["65% - 56%", 0.5599, 0.6599],
    ["55% - 51%", 0.5099, 0.5599],
    ["50% - 47%", 0.5099, 0.4699],
    ["46% - 45%", 0.4499, 0.4699],
  ];
  let discount = discounts[0];

  function formatMoney(number, decPlaces, decSep, thouSep) {
    (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
      (decSep = typeof decSep === "undefined" ? "." : decSep);
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    if (sess.currency == "USD") sign = "$" + sign;
    var i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : "") +
      (sess.currency != "USD" ? "<small>" + sess.currency + "</small>" : "")
    );
  }

  function goToIssues(tskey, subseries, issuenumber) {
    application.update((n) => {
      return {
        ...n,
        screen: "issues",
        screenmode: tskey,
        screencriteria: subseries,
        screensubcriteria: issuenumber,
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  let isLoading = true;
  let issues = [];

  function getIssues() {
    issues = [];
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    fetch(
      app.apiUrl +
        "discounted/" +
        sess.currency +
        "/" +
        discount[1] +
        "/" +
        discount[2],
      options
    )
      .then((res) => res.json())
      .then((res) => {
        issues = res;
        isLoading = false;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  $: {
    const d = discount;
    isLoading = true;
    getIssues();
  }

  let addError = "";
  let isAdding = false;
  async function addToCart(ev) {
    ev.target.blur();
    if (!isAdding) {
      isAdding = true;
      var stockNumbers = [];
      Array.from(document.querySelectorAll("input.issue:checked")).map(
        (element) => stockNumbers.push(element.value)
      );
      Array.from(document.querySelectorAll("input.issue")).map(
        (element) => (element.disabled = true)
      );
      if (stockNumbers.length) {
        document.getElementById("dinger").volume = 0;
        document.getElementById("dinger").play();
        var options = {
          method: "POST",
          cache: "no-cache",
          headers: {
            Authorization: "Bearer " + sess.token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currency: sess.currency,
            sessionid: sess.id,
            stocknumbers: stockNumbers,
          }),
        };
        await fetch(app.apiUrl + "cart/add", options)
          .then((res) => res.json())
          .then((res) => {
            res.cart.map((element) => {
              if (element.Ref != "" && element.Ref.indexOf("?Comics=") == -1)
                element.Ref = JSON.parse(element.Ref);
              else if (
                element.Ref != "" &&
                element.Ref.indexOf("?Comics=") > -1
              ) {
                var tmp = element.Ref.split("=");
                tmp = tmp[1].split("#");
                tmp = tmp[0].split("|");
                element.Ref = [tmp[0], "", tmp[1], "", ""];
              }
            });
            cart.update((n) => {
              return res.cart;
            });
            Array.from(document.querySelectorAll("input.issue")).map(
              (element) => (element.disabled = false)
            );
            Array.from(document.querySelectorAll("input.issue:checked")).map(
              (element) => (element.checked = false)
            );
            for (var i = 0; i < res.cart.length; i++) {
              for (var j = 0; j < issues.length; j++) {
                if (issues[j].Stock_Number == res.cart[i].Stock_Number) {
                  issues.splice(j, 1);
                  break;
                }
              }
            }
            var msg = "";
            Object.keys(res.notcarted).forEach(function (key) {
              for (var j = 0; j < issues.length; j++) {
                if (issues[j].Stock_Number == key) {
                  issues.splice(j, 1);
                  msg += res.notcarted[key] + "<br/>";
                  break;
                }
              }
            });
            if (msg != "") {
              if (res.notcarted.length > 1)
                addError =
                  "Sorry, these copies were bought seconds ago:<br/>" + msg;
              else
                addError =
                  "Sorry, this copy was bought seconds ago:<br/>" + msg;
            }
            issues = issues;
            if (
              navigator.vibrate ||
              navigator.webkitVibrate ||
              navigator.mozVibrate ||
              navigator.msVibrate
            )
              navigator.vibrate(200);
            else {
              document.getElementById("dinger").volume = 1;
              document.getElementById("dinger").play();
            }
            isAdding = false;
          })
          .catch((err) => {
            /*console.log(err)*/
          });
      } else {
        isAdding = false;
        addError = "Please select some comics first";
      }
    }
  }
</script>

{#if addError != ""}
  <div class="modalNotification">
    <button
      type="button"
      class="close"
      aria-label="Close"
      style="margin-top:-0.5em;"
      on:click|preventDefault={() => {
        addError = "";
      }}><span aria-hidden="true">&times;</span></button
    >
    {@html addError}
  </div>
{/if}
<div class="row m-0 p-0 border-bottom border-dark">
  <div class="col-12 m-0 px-1 pb-1 pt-0 text-center nkred">
    <h4 class="title d-inline align-middle">Today's Best Buys</h4>
    <select class="d-inline align-middle" bind:value={discount}>
      {#each discounts as d}
        <option value={d}>{d[0]} off</option>
      {/each}
    </select>
  </div>
</div>
<div class="row m-0 p-0">
  <div class="col-12">
    {#if isLoading}
      <div class="container my-1 py-1 text-center">
        <div class="spinner-grow spinner-grow-sm text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    {/if}
    {#each issues as comic, i}
      {#if i % 10 == 0}
        <div class="row nkbglightergrey">
          <div class="col-3 m-0 pl-1 pr-0 py-1">
            <button
              class="btn btn-primary btn-sm border-0 nkbgred white{isAdding
                ? ' btn-pulse-red'
                : ''}"
              on:click|preventDefault={(event) => addToCart(event)}
              >Add to Cart</button
            >
          </div>
          <div class="col-3 pt-2 pb-1 text-center">
            <strong>Condition</strong>
          </div>
          <div class="col-2 pt-2 pb-1 text-center"><strong>Value</strong></div>
          <div class="col-2 pt-2 pb-1 text-center nkred">
            <strong>Save</strong>
          </div>
          <div class="col-2 pt-2 pb-1 text-center"><strong>Price</strong></div>
        </div>
      {/if}
      <div class="row {i % 2 == 0 ? 'bgwhite' : 'nkbglightblue'}">
        <div class="col-1">
          <input type="checkbox" class="issue" value={comic.Stock_Number} />
        </div>
        <div
          class="col-11 nkblue"
          role="button"
          tabindex="0"
          on:click|preventDefault={() =>
            goToIssues(comic.TS_Key, comic.Sub_Series, comic.Issue_Num)}
          on:keypress|preventDefault={() =>
            goToIssues(comic.TS_Key, comic.Sub_Series, comic.Issue_Num)}
        >
          {comic.Title}
          {comic.Series && comic.Series != "" ? " (" + comic.Series + ")" : ""}
          {comic.Sub_Series && comic.Sub_Series != ""
            ? " " + comic.Sub_Series
            : ""}
          #{comic.Issue_Num}
          {comic.Issue_Txt && comic.Issue_Txt != ""
            ? " " + comic.Issue_Txt
            : ""}
          {comic.Printing && comic.Printing != "" ? " " + comic.Printing : ""}
        </div>
      </div>
      <div class="row {i % 2 == 0 ? 'bgwhite' : 'nkbglightblue'}">
        <div class="col-3" />
        <div class="col-3">{conditions[comic.Rating_Text]}</div>
        <div class="col-2">{@html formatMoney(comic.Initial_NK_Price)}</div>
        <div class="col-2">
          {Math.round(comic.Current_NK_Disc_Percent * 100)}%
        </div>
        <div class="col-2">{@html formatMoney(comic.Current_NK_Price)}</div>
      </div>
    {/each}
  </div>
</div>
