<script>
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import {
    faChevronRight,
    faChevronLeft,
  } from "@fortawesome/pro-light-svg-icons";
  import { faArrowCircleRight } from "@fortawesome/pro-solid-svg-icons";

  let app;
  let bInitialScroll = false;
  let subnavEnabled = false;
  let subnav = 1;
  let sidenavEnabled = false;
  let sidenav = [];

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  const unsubscribe = application.subscribe((value) => {
    if (app && app.screenmode != value.screenmode) subnav = 1;
    app = value;
    subnavEnabled =
      app.screenmode == "alpha" ||
      (app.screenmode == "publishers" && app.screencriteria != "") ||
      (app.screenmode == "otherpublishers" && app.screencriteria != "") ||
      (app.screenmode == "era" && app.screencriteria != "") ||
      (app.screenmode == "categories" && app.screencriteria != "");
    sidenavEnabled = subnavEnabled;
  });

  function goToCriteria(s) {
    application.update((n) => {
      return { ...n, screencriteria: s, screensubcriteria: "", letternav: "" };
    });
    animateScroll.scrollToTop();
  }

  function goToSubCriteria(s, ss) {
    application.update((n) => {
      return { ...n, screencriteria: s, screensubcriteria: ss, letternav: "" };
    });
    animateScroll.scrollToTop();
    if (
      ["O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"].indexOf(ss) >
      -1
    )
      subnav = 2;
    else subnav = 1;
  }

  function goToOhterCriteria(m, s) {
    application.update((n) => {
      return {
        ...n,
        screenmode: m,
        screencriteria: s,
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function goToOhterSubCriteria(m, s, ss) {
    application.update((n) => {
      return {
        ...n,
        screenmode: m,
        screencriteria: s,
        screensubcriteria: ss,
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
    if (
      ["O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"].indexOf(ss) >
      -1
    )
      subnav = 2;
    else subnav = 1;
  }

  function goToIssues(tskey) {
    application.update((n) => {
      return {
        ...n,
        screen: "issues",
        screenmode: tskey,
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function toggleSubnav() {
    if (subnav == 1) var timeout = setTimeout(() => (subnav = 2), 250);
    else var timeout = setTimeout(() => (subnav = 1), 250);
    subnav = -1;
  }

  function firstTwoLetters(s) {
    s = s.toLowerCase().replace(/[^a-z0-9]/gim, "");
    var first = s.substring(0, 1).toUpperCase();
    var second = s.length > 1 ? s.substring(1, 2).toLowerCase() : "";
    var third = s.length > 2 ? s.substring(2, 3).toLowerCase() : "";
    switch (first) {
      case "0":
        first = "Z";
        second = "e";
        break;
      case "1":
        if (second != "" && !isNaN(second)) {
          switch (second) {
            case "1":
              first = "E";
              second = "l";
              break;
            case "2":
              first = "T";
              second = "w";
              break;
            case "3":
              first = "T";
              second = "h";
              break;
            case "4":
              first = "F";
              second = "o";
              break;
            case "5":
              first = "F";
              second = "i";
              break;
            case "6":
              first = "S";
              second = "i";
              break;
            case "7":
              first = "S";
              second = "e";
              break;
            case "8":
              first = "E";
              second = "i";
              break;
            case "9":
              first = "N";
              second = "i";
              break;
            case "0":
              if (third != "" && !isNaN(third)) {
                first = "O";
                second = "n";
              } else {
                first = "T";
                second = "e";
              }
              break;
          }
        } else {
          first = "O";
          second = "n";
        }
        break;
      case "2":
        first = "T";
        second = "w";
        break;
      case "3":
        first = "T";
        second = "h";
        break;
      case "4":
        first = "F";
        second = "o";
        break;
      case "5":
        first = "F";
        second = "i";
        break;
      case "6":
        first = "S";
        second = "i";
        break;
      case "7":
        first = "S";
        second = "e";
        break;
      case "8":
        first = "E";
        second = "i";
        break;
      case "9":
        first = "N";
        second = "i";
        break;
    }
    return first + second;
  }

  function scrollToLetters(ev, l) {
    if (document.querySelectorAll("li." + l).length) {
      session.update((n) => {
        return { ...n, lastSubNavLetter: l };
      });
      animateScroll.scrollTo({
        container: "div#titlesList",
        element: "li." + l,
      });
    } else if (
      ev &&
      ev.target &&
      ev.target.nextSibling &&
      ev.target.nextSibling.nextSibling &&
      ev.target.nextSibling.nextSibling.nextSibling &&
      typeof ev.target.nextSibling.nextSibling.nextSibling != "undefined"
    ) {
      ev.target.nextSibling.nextSibling.nextSibling.click();
    }
  }

  let data = [];

  $: {
    const f = app.screenmode + app.screencriteria;
    if (app.screencriteria != "") loadData();
    else data = [];
  }

  function sortFunction(a, b) {
    if (a[0] === b[0]) return 0;
    else return a[0] < b[0] ? -1 : 1;
  }

  async function loadData() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    data = [];
    sidenav = [];
    var url = app.apiUrl + "titles/";
    if (app.screenmode == "publishers") url += "bypublisher/";
    else if (app.screenmode == "otherpublishers") url += "getpublishers/";
    else if (app.screenmode == "categories") url += "bycategory/";
    else if (app.screenmode == "heroes") url += "byhero/";
    else if (app.screenmode == "era") url += "byera/";
    else url += "byletter/";
    var doFetch = false;
    if (app.screensubcriteria != "") {
      doFetch = true;
      url += app.screencriteria + "/" + app.screensubcriteria;
    } else if (app.screencriteria != "") {
      doFetch = true;
      url += app.screencriteria;
    }
    if (doFetch) {
      await fetch(url, options)
        .then((res) => res.json())
        .then((res) => {
          data = res;
          if (sidenavEnabled) {
            if (app.screenmode == "otherpublishers") {
              var newdata = [];
              for (var i = 0; i < data.length; i++) {
                var letters = firstTwoLetters(data[i]);
                newdata.push([letters, data[i]]);
                if (sidenav.indexOf(letters) == -1) sidenav.push(letters);
              }
              newdata.sort(sortFunction);
              data = newdata;
              sidenav.sort();
              sidenav = sidenav;
            } else {
              for (var i = 0; i < data.length; i++) {
                var letters =
                  data[i].Srch_Key.toUpperCase() +
                  (data[i].Srch_Key_Sub
                    ? data[i].Srch_Key_Sub.toLowerCase()
                    : "");
                if (sidenav.indexOf(letters) == -1) sidenav.push(letters);
              }
              sidenav = sidenav;
            }
            if (app.letternav != "" && !bInitialScroll) {
              bInitialScroll = true;
              setTimeout(function () {
                animateScroll.scrollTo({
                  container: "div#titlesList",
                  element: "li." + app.letternav,
                });
              }, 500);
            } else if (app.letternav == "")
              session.update((n) => {
                return { ...n, lastSubNavLetter: "" };
              });
          }
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    }
  }

  onMount(async () => {
    if (app.screencriteria != "" || app.screenmode == "publishers")
      await loadData();
    else data = [];
  });
</script>

<div class="row m-0 p-0">
  <div class="{sidenavEnabled ? 'col-10' : 'col-12'} m-0 p-2">
    <div class="row m-0 p-0">
      <div
        class="col-12 m-0 px-0 pt-0 pb-2 border-bottom border-dark text-center nkred"
        style="font-weight:bold;"
      >
        {#if app.screenmode == "publishers"}
          {#if app.screensubcriteria != ""}
            {app.screencriteria} Comics starting with
          {:else}
            Publishers
          {/if}
        {:else if app.screenmode == "otherpublishers"}
          Publishers starting with
        {:else if app.screenmode == "categories"}
          {#if app.screencriteria != ""}
            {app.screencriteria} Comics starting with
          {:else}
            Categories
          {/if}
        {:else if app.screenmode == "heroes"}
          {#if app.screencriteria != ""}
            {app.screencriteria} Comics
          {:else}
            Heroes
          {/if}
        {:else if app.screenmode == "era"}
          {#if app.screencriteria == "5"}
            Comics from Last 5 years starting with
          {:else if app.screencriteria == "M"}
            Modern Comics starting with
          {:else if app.screencriteria == "C"}
            Copper Era Comics starting with
          {:else if app.screencriteria == "B"}
            Bronze Era Comics starting with
          {:else if app.screencriteria == "S"}
            Silver Era Comics starting with
          {:else if app.screencriteria == "G"}
            Golde Era Comics starting with
          {:else}
            Eras
          {/if}
        {:else}
          Comics starting with
        {/if}
      </div>
    </div>
    {#if subnavEnabled}
      <div class="row m-0 p-0" style="height:2.25em;">
        {#if subnav == 1}
          <div
            class="col-12 m-0 p-0 overflow-hidden zero-whitespace"
            style="height:2.25em;"
            in:fade={{ duration: 250 }}
            out:fade={{ duration: 250 }}
          >
            {#each ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"] as letter, ind}
              {#if app.screensubcriteria != ""}
                <a
                  href={null}
                  class="letter {letter.substring()}{ind == 0
                    ? ' first'
                    : ''}{letter == app.screensubcriteria ? ' bold nkred' : ''}"
                  on:click|preventDefault={() =>
                    goToSubCriteria(app.screencriteria, letter)}>{letter}</a
                >
              {:else}
                <a
                  href={null}
                  class="letter {letter.substring()}{ind == 0
                    ? ' first'
                    : ''}{letter == app.screencriteria ? ' bold nkred' : ''}"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => goToCriteria(letter)}
                  on:keypress|preventDefault={() => goToCriteria(letter)}
                  >{letter}</a
                >
              {/if}
            {/each}
            <a
              href={null}
              class="letter chev last"
              role="button"
              tabindex="0"
              on:click|preventDefault={toggleSubnav}
              on:keypress|preventDefault={toggleSubnav}
              ><FontAwesomeIcon icon={faChevronRight} /></a
            >
          </div>
        {:else if subnav == 2}
          <div
            class="col-12 m-0 p-0 overflow-hidden zero-whitespace"
            style="height:2.25em;"
            in:fade={{ duration: 250 }}
            out:fade={{ duration: 250 }}
          >
            <a
              href={null}
              class="letter chev first"
              role="button"
              tabindex="0"
              on:click|preventDefault={toggleSubnav}
              on:keypress|preventDefault={toggleSubnav}
              ><FontAwesomeIcon icon={faChevronLeft} /></a
            >
            {#each ["N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"] as letter, ind}
              {#if app.screensubcriteria != ""}
                <a
                  href={null}
                  class="letter {letter.substring()}{ind == 12
                    ? ' last'
                    : ''}{letter == app.screensubcriteria ? ' bold nkred' : ''}"
                  on:click|preventDefault={() =>
                    goToSubCriteria(app.screencriteria, letter)}>{letter}</a
                >
              {:else}
                <a
                  href={null}
                  class="letter {letter.substring()}{ind == 12
                    ? ' last'
                    : ''}{letter == app.screencriteria ? ' bold nkred' : ''}"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => goToCriteria(letter)}
                  on:keypress|preventDefault={() => goToCriteria(letter)}
                  >{letter}</a
                >
              {/if}
            {/each}
          </div>
        {/if}
      </div>
    {/if}
    {#if app.screenmode == "publishers" && app.screencriteria == ""}
      <div class="row mx-0 mb-0 mt-1 p-0">
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("DC", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("DC", "A")}
        >
          DC <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
        <div class="col-1 m-0 p-0" />
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Archie", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Archie", "A")}
        >
          Archie <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Marvel", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Marvel", "A")}
        >
          Marvel <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
        <div class="col-1 m-0 p-0" />
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Charlton", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Charlton", "A")}
        >
          Charlton <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Boom!", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Boom!", "A")}
        >
          Boom! <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
        <div class="col-1 m-0 p-0" />
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Dell", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Dell", "A")}
        >
          Dell <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Dark Horse", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Dark Horse", "A")}
        >
          Dark Horse <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
        <div class="col-1 m-0 p-0" />
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Gold Key", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Gold Key", "A")}
        >
          Gold Key <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("IDW", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("IDW", "A")}
        >
          IDW <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
        <div class="col-1 m-0 p-0" />
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Valiant", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Valiant", "A")}
        >
          Valiant <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-5 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Image", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Image", "A")}
        >
          Image <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row mx-0 mb-0 mt-3 p-0">
        <div class="col-12 m-0 p-0 text-center" style="font-weight:bold;">
          Other Publishers
        </div>
      </div>
      <div class="row mx-0 mt-0 mb-1 p-0" style="height:2.25em;">
        {#if subnav == 1}
          <div
            class="col-12 m-0 p-0 overflow-hidden text-center"
            style="height:2.25em;"
            in:fade={{ duration: 250 }}
            out:fade={{ duration: 250 }}
          >
            {#each ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M"] as letter}
              <a
                href={null}
                class="letter"
                on:click|preventDefault={() =>
                  goToOhterCriteria("otherpublishers", letter)}>{letter}</a
              >
            {/each}
            <a
              href={null}
              class="letter"
              role="button"
              tabindex="0"
              on:click|preventDefault={toggleSubnav}
              on:keypress|preventDefault={toggleSubnav}>&gt;</a
            >
          </div>
        {:else if subnav == 2}
          <div
            class="col-12 m-0 p-0 overflow-hidden text-center"
            style="height:2.25em;"
            in:fade={{ duration: 250 }}
            out:fade={{ duration: 250 }}
          >
            <a
              href={null}
              class="letter"
              role="button"
              tabindex="0"
              on:click|preventDefault={toggleSubnav}
              on:keypress|preventDefault={toggleSubnav}>&lt;</a
            >
            {#each ["N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"] as letter}
              <a
                href={null}
                class="letter"
                on:click|preventDefault={() =>
                  goToOhterCriteria("otherpublishers", letter)}>{letter}</a
              >
            {/each}
          </div>
        {/if}
      </div>
    {:else if app.screenmode == "era" && app.screencriteria == ""}
      <div class="row mx-0 mb-0 mt-1 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("5", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("5", "A")}
        >
          Last 5 years <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("M", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("M", "A")}
        >
          Modern <small>1992-now</small>
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("C", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("C", "A")}
        >
          Copper <small>1984-1991</small>
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("B", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("B", "A")}
        >
          Bronze <small>1970-1983</small>
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("S", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("S", "A")}
        >
          Silver <small>1955-1969</small>
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row mx-0 mt-0 mb-1 p-0">
        <div
          class="col-11 m-1 p-1"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("G", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("G", "A")}
        >
          Golden <small>1939-1955</small>
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
    {:else if app.screenmode == "heroes" && app.screencriteria == ""}
      <div class="row mx-0 mb-0 mt-1 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Avengers")}
          on:keypress|preventDefault={() => goToCriteria("Avengers")}
        >
          Avengers <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Batman")}
          on:keypress|preventDefault={() => goToCriteria("Batman")}
        >
          Batman <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Captain America")}
          on:keypress|preventDefault={() => goToCriteria("Captain America")}
        >
          Captain America <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Daredevil")}
          on:keypress|preventDefault={() => goToCriteria("Daredevil")}
        >
          Daredevil <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Fantastic Four")}
          on:keypress|preventDefault={() => goToCriteria("Fantastic Four")}
        >
          Fantastic Four <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Flash")}
          on:keypress|preventDefault={() => goToCriteria("Flash")}
        >
          Flash <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Green Lantern")}
          on:keypress|preventDefault={() => goToCriteria("Green Lantern")}
        >
          Green Lantern <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Hulk")}
          on:keypress|preventDefault={() => goToCriteria("Hulk")}
        >
          Hulk <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Iron Man")}
          on:keypress|preventDefault={() => goToCriteria("Iron Man")}
        >
          Iron Man <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Justice League")}
          on:keypress|preventDefault={() => goToCriteria("Justice League")}
        >
          Justice League <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Spider-Man")}
          on:keypress|preventDefault={() => goToCriteria("Spider-Man")}
        >
          Spider-Man <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Superman")}
          on:keypress|preventDefault={() => goToCriteria("Superman")}
        >
          Superman <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Thor")}
          on:keypress|preventDefault={() => goToCriteria("Thor")}
        >
          Thor <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Wolverine")}
          on:keypress|preventDefault={() => goToCriteria("Wolverine")}
        >
          Wolverine <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("Wonder Woman")}
          on:keypress|preventDefault={() => goToCriteria("Wonder Woman")}
        >
          Wonder Woman <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToCriteria("X-Men")}
          on:keypress|preventDefault={() => goToCriteria("X-Men")}
        >
          X-Men <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row mx-0 mt-0 mb-1 p-0">
        <div class="col-11 m-1 p-1 text-center">
          <small
            >For all others, please use the search box at the top of the screen</small
          >
        </div>
      </div>
    {:else if app.screenmode == "categories" && app.screencriteria == ""}
      <div class="row mx-0 mb-0 mt-1 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Ages 3-6", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Ages 3-6", "A")}
        >
          Ages 3-6 <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Ages 7-12", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Ages 7-12", "A")}
        >
          Ages 7-12 <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Disney", "W")}
          on:keypress|preventDefault={() => goToSubCriteria("Disney", "W")}
        >
          Disney <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Female Heroes", "A")}
          on:keypress|preventDefault={() =>
            goToSubCriteria("Female Heroes", "A")}
        >
          Female Heroes <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Ghosts", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Ghosts", "A")}
        >
          Ghosts <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Love", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Love", "A")}
        >
          Love <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Movies", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Movies", "A")}
        >
          Movies <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Star Wars", "S")}
          on:keypress|preventDefault={() => goToSubCriteria("Star Wars", "S")}
        >
          Star Wars <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("TV - animated", "A")}
          on:keypress|preventDefault={() =>
            goToSubCriteria("TV - animated", "A")}
        >
          TV - animated <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("TV - comedy", "I")}
          on:keypress|preventDefault={() => goToSubCriteria("TV - comedy", "I")}
        >
          TV - comedy <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("TV - drama", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("TV - drama", "A")}
        >
          TV - drama <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div
          class="col-11 m-1 p-1 border-bottom border-dark"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("War", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("War", "A")}
        >
          War <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
      <div class="row mx-0 mt-0 mb-1 p-0">
        <div
          class="col-11 m-1 p-1"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToSubCriteria("Western", "A")}
          on:keypress|preventDefault={() => goToSubCriteria("Western", "A")}
        >
          Western <FontAwesomeIcon
            icon={faArrowCircleRight}
            class="nkblue float-right pt-1"
          />
        </div>
      </div>
    {:else if data.length}
      <div
        class="row m-0 p-0 overflow-auto"
        style="max-height:650px;"
        id="titlesList"
      >
        <div class="col-12 m-0 p-0">
          <ul class="list-unstyled m-0 mt-1 p-0">
            {#each data as item, i}
              {#if app.screenmode == "otherpublishers"}
                <li
                  class="border-top border-dark m-0 px-1 py-2 titleli {item[0]}"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() =>
                    goToOhterSubCriteria("publishers", item[1], "A")}
                  on:keypress|preventDefault={() =>
                    goToOhterSubCriteria("publishers", item[1], "A")}
                >
                  <div>
                    {item[1]}
                    <FontAwesomeIcon
                      icon={faArrowCircleRight}
                      class="nkblue float-right pt-1"
                    />
                  </div>
                </li>
              {:else}
                <li
                  class="{app.screenmode != 'heroes' || i > 0
                    ? 'border-top border-dark '
                    : ''}m-0 px-1 py-2 titleli {item.Srch_Key.toUpperCase()}{item.Srch_Key_Sub
                    ? item.Srch_Key_Sub.toLowerCase()
                    : ''}"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => goToIssues(item.TS_Key)}
                  on:keypress|preventDefault={() => goToIssues(item.TS_Key)}
                >
                  <div>
                    {item.Title}
                    {#if item.Series != ""}
                      <small>{item.Series}</small>
                    {/if}
                  </div>
                </li>
              {/if}
            {/each}
          </ul>
        </div>
      </div>
    {/if}
  </div>
  {#if sidenavEnabled}
    <div class="col-2 pt-2">
      {#each sidenav as letter}
        <a
          href={null}
          role="button"
          tabindex="0"
          on:click|preventDefault={(event) => scrollToLetters(event, letter)}
          on:keypress|preventDefault={(event) => scrollToLetters(event, letter)}
          >{letter}</a
        ><br />
      {/each}
    </div>
  {/if}
</div>
