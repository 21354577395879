<script>
  import { onMount } from "svelte";
  import { application, session, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { conditions } from "../shared/Conditions.js";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function formatMoney(number, decPlaces, decSep, thouSep) {
    (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
      (decSep = typeof decSep === "undefined" ? "." : decSep);
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    if (sess.currency == "USD") sign = "$" + sign;
    var i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : "") +
      (sess.currency != "USD" ? "<small>" + sess.currency + "</small>" : "")
    );
  }

  function formatDate(dt) {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var output =
      months[dt.getMonth()] +
      " " +
      dt.getDate() +
      ", " +
      dt.getFullYear() +
      ", ";
    var hr = dt.getHours();
    if (dt.getHours() == 0)
      output += "12:" + dt.getMinutes() + " am Eastern USA Time";
    else if (dt.getHours() > 11)
      output +=
        dt.getHours() - 11 + ":" + dt.getMinutes() + " pm Eastern USA Time";
    else
      output += dt.getHours() + ":" + dt.getMinutes() + " am Eastern USA Time";
    return output;
  }

  let isWorking = true;
  let orderRecord = {};
  let orderFound = true;
  let orderDate = new Date();
  onMount(async () => {
    if (sess.lastOrderNum && sess.lastOrderNum > 0) {
      var options = {
        method: "GET",
        //cache: "no-cache",
        headers: { Authorization: "Bearer " + sess.token },
      };
      fetch(
        app.apiUrl +
          "order_headers/pastorder/" +
          sess.currency +
          "/" +
          sess.lastOrderNum,
        options
      )
        .then((res) => res.json())
        .then((res) => {
          orderRecord = res;
          orderDate = new Date(res.header.Order_DT);
          if (
            Object.prototype.toString.call(orderDate) != "[object Date]" ||
            isNaN(orderDate.getTime())
          )
            orderDate = new Date();
          isWorking = false;
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    } else {
      orderFound = false;
      isWorking = false;
    }
  });
</script>

<div class="row m-0 p-0">
  <div class="col-12 m-0 p-0" style="position:relative;">
    {#if isWorking}
      <div class="subshade" />
      <div class="subshadeloader">
        <div class="container my-1 py-1 text-center">
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    {/if}
    {#if orderFound && orderRecord && orderRecord.header}
      <div class="row m-0 p-0">
        <div class="col-12 pt-2">
          <div class="dashed" id="invoice">
            <div class="row m-0 p-0 pb-2 nkbgyellow">
              <div class="col-12">
                <p class="noindent strong nkred largest text-center my-0">
                  Invoice
                </p>
                <p class="noindent strong text-center my-0">
                  Order Number: {(
                    usr.Cust_ID * 1000 +
                    orderRecord.header.Order_Num
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
                <p class="noindent small text-center my-0">
                  Ordered {formatDate(orderDate)}
                </p>
                <hr class="my-1" style="width:90%;border-color:#000;" />
                <p class="noindent strong mb-0">
                  <span class="nkred mr-3">1</span>Mail {@html formatMoney(
                    parseFloat(orderRecord.header.Total_Due) +
                      parseFloat(orderRecord.header.Total_Paid)
                  )} within 72 hours to:
                </p>
                <p class="noindent mt-0" style="padding-left:2em;">
                  NewKadia<br />125 Noble St, Suite 301<br />Norristown, PA
                  19401-4423
                </p>
                <p class="noindent strong mb-0">
                  <span class="nkred mr-2">2a</span>Write Order Number:
                </p>
                <p class="noindent mt-0" style="padding-left:2em;">
                  {(usr.Cust_ID * 1000 + sess.lastOrderNum)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} on your {orderRecord &&
                  orderRecord.header &&
                  orderRecord.header.Pymnt_Type == "C"
                    ? "Check"
                    : "Money Order"}; or
                </p>
                <p class="noindent" style="margin-left:2em;text-indent:-2em;">
                  <span class="nkred strong mr-2">2b</span><strong
                    >Print this invoice NOW</strong
                  >
                  and include it with your {orderRecord &&
                  orderRecord.header &&
                  orderRecord.header.Pymnt_Type == "C"
                    ? "Check"
                    : "Money Order"}.
                </p>
              </div>
            </div>
          </div>
          <p class="noindent strong mt-2">Make payable to 'NewKadia'.</p>
          <p class="noindent">
            <strong>Paying for 2 or more orders?</strong><br />You can pay with
            one check, write all invoice numbers on it.
          </p>
          <p class="noindent">
            <strong>After we get your check</strong><br />We'll email your
            order's tracking number.
          </p>
          <p class="noindent strong mb-0">Your order will be shipped to:</p>
          <p class="noindent mt-0" style="padding-left:1em;">
            {orderRecord.address.Name}<br />
            {orderRecord.address.Addr_1}<br />
            {#if orderRecord.address.Suite && orderRecord.address.Suite != ""}{orderRecord
                .address.Suite}<br />{/if}
            {orderRecord.address.City}, {orderRecord.address.State}
            {orderRecord.address.ZIP_1}<br />
            {#if orderRecord.address.Country != "US"}{orderRecord.address
                .Country}{/if}
          </p>
          <img
            src="img/svg/logo.svg"
            alt="NewKadia.com Logo"
            style="display:block;margin:0 auto 2em auto;max-width:50%"
            class="pad25_5"
          />
        </div>
      </div>
    {:else}
      <div class="row m-0 p-0 border-bottom border-dark">
        <div class="col-12 m-0 p-0 text-center nkred">
          <h4 class="title">Order Invoice</h4>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-12 text-center">
          <p class="indent italic">Order not found</p>
          <p class="indent">Please contact customer service for assistance</p>
        </div>
      </div>
    {/if}
  </div>
</div>
