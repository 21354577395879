<script>
  import { onMount } from "svelte";
  import { application, session, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { conditions } from "../shared/Conditions.js";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function formatMoney(number, decPlaces, decSep, thouSep) {
    (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
      (decSep = typeof decSep === "undefined" ? "." : decSep);
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    if (sess.currency == "USD") sign = "$" + sign;
    var i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : "") +
      (sess.currency != "USD" ? "<small>" + sess.currency + "</small>" : "")
    );
  }

  function formatDate(dt) {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var output =
      months[dt.getMonth()] + " " + dt.getDate() + ", " + dt.getFullYear();
    return output;
  }

  let isWorking = true;
  let orderRecord = {};
  let orderFound = true;
  let orderDate = new Date();
  onMount(async () => {
    if (sess.lastOrderNum && sess.lastOrderNum > 0) {
      var options = {
        method: "GET",
        //cache: "no-cache",
        headers: { Authorization: "Bearer " + sess.token },
      };
      fetch(
        app.apiUrl +
          "order_headers/pastorder/" +
          sess.currency +
          "/" +
          sess.lastOrderNum,
        options
      )
        .then((res) => res.json())
        .then((res) => {
          orderRecord = res;
          orderDate = new Date(res.header.Order_DT);
          if (
            Object.prototype.toString.call(orderDate) != "[object Date]" ||
            isNaN(orderDate.getTime())
          )
            orderDate = new Date();
          isWorking = false;
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    } else {
      orderFound = false;
      isWorking = false;
    }
  });
</script>

<div class="row m-0 p-0">
  <div class="col-12 m-0 p-0" style="position:relative;">
    {#if isWorking}
      <div class="subshade" />
      <div class="subshadeloader">
        <div class="container my-1 py-1 text-center">
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    {/if}
    {#if orderFound && orderRecord && orderRecord.header}
      <div class="row m-0 p-0">
        <div class="col-12 m-0 p-0 text-center">
          <h4 class="title nkred m-0">Receipt</h4>
          <p class="larger125 noindent m-0">
            Order Number: {(usr.Cust_ID * 1000 + sess.lastOrderNum)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
          <p class="noindent m-0">Ordered {formatDate(orderDate)}</p>
          <p class="noindent mt-0">
            You'll get an email when we ship your order.
          </p>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-12">
          <p class="noindent strong mb-0">Shipping to</p>
          <p class="noindent mx-3">
            {orderRecord.address.Name}<br />
            {#if orderRecord.address.Company && orderRecord.address.Company != ""}{orderRecord
                .address.Company}<br />{/if}
            {orderRecord.address.Addr_1}<br />
            {#if orderRecord.address.Suite && orderRecord.address.Suite != ""}{orderRecord
                .address.Suite}<br />{/if}
            {orderRecord.address.City}, {orderRecord.address.State}
            {orderRecord.address.ZIP_1}<br />
            {#if orderRecord.address.Country && orderRecord.address.Country != "US"}{orderRecord
                .address.Country}{/if}
          </p>
          <p class="noindent mx-3">
            If this address is wrong, send an email now to <a
              href="mailto:Help@NewKadia.com?subject=URGENT%20-%20Change%20Delivery%20Address%20for%20Order%20#%20{(
                usr.Cust_ID * 1000 +
                sess.lastOrderNum
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}">Help@NewKadia.com</a
            > and type:
          </p>
          <ol>
            <li>
              Your Order Number: {(usr.Cust_ID * 1000 + sess.lastOrderNum)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} and
            </li>
            <li>Your correct address</li>
          </ol>
          <hr class="border-dark" />
          <p class="text-center noindent">
            For your shipping status, go to <span
              class="nkblue"
              role="button"
              tabindex="0"
              on:click|preventDefault={() => goTo("orders")}
              on:keypress|preventDefault={() => goTo("orders")}
              >Your Orders</span
            >
          </p>
          <p class="indent">
            Orders received by 5 p.m. EST weekdays are shipped the same day.
            Order later, or on weekends, and we ship the next weekday.
          </p>
          {#if orderRecord && orderRecord.header && orderRecord.header.Pymnt_Type == "PP"}
            <p class="indent">
              If you used your PayPal balance to pay for the order, we’re
              shipping your order. If you used an eCheck, we will ship when
              PayPal changes the payment status from &quot;Uncleared&quot; to
              &quot;Cleared&quot;.
            </p>
          {/if}
          <p class="noindent strong mb-0">Order Summary</p>
          <p class="indent">
            Go to <span
              class="nkblue"
              role="button"
              tabindex="0"
              on:click|preventDefault={() => goTo("orders")}
              on:keypress|preventDefault={() => goTo("orders")}
              >Your Orders</span
            >
          </p>
          <p class="noindent strong mb-0">
            Get custom recommendations just for you
          </p>
          <p class="indent">
            Go to the <span
              class="nkblue"
              role="button"
              tabindex="0"
              on:click|preventDefault={() => goTo("recommendations")}
              on:keypress|preventDefault={() => goTo("recommendations")}
              >Comic Book Mind Reader</span
            >
          </p>
          <p class="noindent strong mb-0">Thank you</p>
          <p class="indent">
            <img
              src="https://img.newkadia.com/A/jimdrucker.jpg"
              alt="Jim Drucker"
              align="left"
              style="width:35%;"
              class="pad25_5"
            />Hello, I’m Jim Drucker, The CEO of NewKadia. Thank you for your
            order.
          </p>
          <p class="indent">
            When you get your books, if you have any concern, phone or email. My
            number and email are listed at the bottom of this page. Thanks for
            your order. Enjoy your books.
          </p>
          <img
            src="img/svg/logo.svg"
            alt="NewKadia.com Logo"
            style="display:block;max-width:60%;margin:2em auto;"
            class="pad25_5"
          />
        </div>
      </div>
    {:else}
      <div class="row m-0 p-0 border-bottom border-dark">
        <div class="col-12 m-0 p-0 text-center nkred">
          <h4 class="title">Order Receipt</h4>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-12 text-center">
          <p class="indent italic">Order not found</p>
          <p class="indent">Please contact customer service for assistance</p>
        </div>
      </div>
    {/if}
  </div>
</div>
