<script>
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { onMount } from "svelte";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function formatMoney(number, decPlaces, decSep, thouSep) {
    (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
      (decSep = typeof decSep === "undefined" ? "." : decSep);
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    if (sess.currency == "USD") sign = "$" + sign;
    var i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : "") +
      (sess.currency != "USD" ? "<small>" + sess.currency + "</small>" : "")
    );
  }

  let freeComicThreshold = 9.0;
  async function convertCurrency() {
    await fetch("https://api.exchangerate-api.com/v4/latest/USD")
      .then((res) => res.json())
      .then((res) => {
        const rate = res.rates[sess.currency];
        freeComicThreshold = (freeComicThreshold * rate).toFixed(2);
      });
  }

  onMount(async () => {
    await convertCurrency();
  });
</script>

<div class="row m-0 p-0 border-bottom border-dark">
  <div class="col-12 m-0 p-0 text-center nkred">
    <h4 class="title">Customer Service</h4>
  </div>
</div>
<div class="row m-0 mb-1 p-1">
  <div class="col-12 m-0 mb-1 p-1">
    <p class="larger125 strong noindent m-0 pb-1" style="line-height:110%;">
      <img
        src="https://img.newkadia.com/A/jimdrucker.jpg"
        alt="Jim Drucker"
        align="left"
        style="width:35%;"
        class="pad25_5"
      />We want to be the Amazon for Comics<span class="larger125 italic"
        >!</span
      >
    </p>
    <p class="noindent m-0 pb-1">Jim Drucker, CEO</p>
    <hr style="width:65%;" class="mr-0 ml-auto my-1" />
    <p class="noindent">
      <a href="mailto:Help@NewKadia.com">Help@NewKadia.com</a><br />
      610-277-3000<br />
      125 Noble St. Suite 301<br />
      Norristown, PA 19401
    </p>
    <hr />
    <p class="indent strong text-center">
      Just like Amazon we strive to give you
    </p>
    <ul class="pis125">
      <li>Fast delivery</li>
      {#if (sess.country == "US" || sess.country == "PR") && (!usr || !user.prime)}
        <li>
          Free Shipping with <span
            class="nkblue strong"
            role="button"
            tabindex="0"
            on:click|preventDefault={() => goTo("freeshipping")}
            on:keypress|preventDefault={() => goTo("freeshipping")}
            >NewKadia Prime</span
          >
        </li>
      {/if}
      <li>Lowest Prices anywhere</li>
      <li>
        <span
          class="nkblue strong"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goTo("shipping")}
          on:keypress|preventDefault={() => goTo("shipping")}
          >Secure Packaging</span
        >
      </li>
      <li>Biggest selection</li>
      <li>Great customer service</li>
    </ul>
    <p class="indent">
      We’ll treat you the way we like to be treated. We’ve done this for 20
      years and we’ll keep doing that, and more:
    </p>
    <img
      src="https://www.newkadia.com/A/NewKadia20-200x366.png"
      alt="NewKadia.com - 20 years online, 2000-2020"
      style="display:block;margin:1em auto;width:80%;"
      class="pad25_5"
    />
    <p class="strong noindent">1. Contact us easily.</p>
    <p class="indent">
      For fastest service, email us at <a href="mailto:Help@NewKadia.com"
        >Help@NewKadia.com</a
      >. We’ll reply within 5 business hours (weekdays 10 a.m. to 6 p.m. Eastern
      USA Time)
    </p>
    <p class="strong noindent">2. We email your tracking number</p>
    <p class="strong noindent">3. Money-Back Guarantee</p>
    <p class="indent">
      <img
        src="https://www.imagesnk.com/MONEYBACK120x120.gif"
        alt="Money Back Guarantee"
        class="pad25_5 sm"
        style="max-height:75px;"
        align="left"
      />Return any book for a full refund. We’ll pay return shipping. Get
      details
      <span
        class="nkblue strong"
        role="button"
        tabindex="0"
        on:click|preventDefault={() => goTo("returns")}
        on:keypress|preventDefault={() => goTo("returns")}>here</span
      >
    </p>
    <p class="strong noindent">4. Same Day Shipping</p>
    <p class="indent">
      Order weekdays by 5 p.m. Eastern USA time, and we ship that day. Order
      later, we ship the next weekday morning.
    </p>
    <p class="strong noindent">5. Safe Packing</p>
    <p class="indent">
      <img
        src="https://img.newkadia.com/A/CustomBoxes171hx250w.jpg"
        alt="Secure Packaging"
        class="pad25_5 sm"
        align="right"
      />We use strong boxes. The top one is for 15-30 comics. It has 3 walls of
      cardboard protection.
    </p>
    <p class="indent">
      The front one, for smaller orders, has extra corner protection.
    </p>
    <p class="indent">
      Both have easy opening tear strips. You won’t need a knife, scissors or
      keys to open them.
    </p>
    <p class="strong noindent">
      6. Free shipping insurance and free comic bags and boards.
    </p>
    <p class="strong noindent">7. Free comics</p>
    <p class="indent">
      You get free comics with any order over {@html formatMoney(
        freeComicThreshold
      )}. For the list of freebies, add {@html formatMoney(freeComicThreshold)} of
      books to your Cart and click the Free Comic List in the
      <span class="NKc_NGreen">green area</span> of the Cart page.
    </p>
    <p class="strong noindent">8. Low Prices</p>
    <p class="indent">
      Every comic is priced below its Overstreet Price Guide Value. And we run 5
      big <span
        class="nkblue strong"
        role="button"
        tabindex="0"
        on:click|preventDefault={() => goTo("sales")}
        on:keypress|preventDefault={() => goTo("sales")}>sales</span
      > every day
    </p>
  </div>
</div>
