<script>
  import { fade } from "svelte/transition";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import {
    faCalendarAlt,
    faChevronLeft,
    faChevronRight,
  } from "@fortawesome/pro-solid-svg-icons";

  export let dateValue = "";
  export let start = new Date(2023, 0, 1);
  export let end = new Date(2060, 11, 31);

  let daysOfWeek = [
    ["Sunday", "Sun"],
    ["Monday", "Mon"],
    ["Tuesday", "Tue"],
    ["Wednesday", "Wed"],
    ["Thursday", "Thu"],
    ["Friday", "Fri"],
    ["Saturday", "Sat"],
  ];

  let monthsOfYear = [
    ["January", "Jan"],
    ["February", "Feb"],
    ["March", "Mar"],
    ["April", "Apr"],
    ["May", "May"],
    ["June", "Jun"],
    ["July", "Jul"],
    ["August", "Aug"],
    ["September", "Sep"],
    ["October", "Oct"],
    ["November", "Nov"],
    ["December", "Dec"],
  ];

  const getCalendarPage = (month, year) => {
    let date = new Date(year, month, 1);
    date.setDate(date.getDate() - date.getDay());
    let nextMonth = month === 11 ? 0 : month + 1;
    let weeks = [];
    while (
      date.getMonth() !== nextMonth ||
      date.getDay() !== 0 ||
      weeks.length !== 6
    ) {
      if (date.getDay() === 0) weeks.unshift({ weekOfMonth: false, days: [] });
      const updated = Object.assign({
        partOfMonth: date.getMonth() === month,
        date: new Date(date),
      });
      if (updated.partOfMonth == true) weeks[0].weekOfMonth = true;
      weeks[0].days.push(updated);
      date.setDate(date.getDate() + 1);
    }
    weeks.reverse();
    return { month, year, weeks };
  };

  function getMonths(start, end) {
    start.setHours(0, 0, 0, 0);
    end.setHours(0, 0, 0, 0);
    let months = [];
    let date = new Date(start.getFullYear(), start.getMonth(), 1);
    let endDate = new Date(end.getFullYear(), end.getMonth() + 1, 1);
    while (date < endDate) {
      months.push(getCalendarPage(date.getMonth(), date.getFullYear()));
      date.setMonth(date.getMonth() + 1);
    }
    return months;
  }

  const areDatesEquivalent = (a, b) =>
    a.getDate() === b.getDate() &&
    a.getMonth() === b.getMonth() &&
    a.getFullYear() === b.getFullYear();

  let dtOpen = false;
  function dateOpen() {
    dtOpen = true;
  }
  function dateClose() {
    dtOpen = false;
  }
  function onDocumentClick(e) {
    if (!e.target.closest(".cal")) dateClose();
  }

  const today = dateValue == "" ? new Date() : new Date(dateValue);
  today.setHours(0, 0, 0, 0);
  let selected = today;
  let month = selected.getMonth();
  let year = selected.getFullYear();
  $: {
    selected = dateValue == "" ? selected : new Date(dateValue);
    selected.setHours(0, 0, 0, 0);
    month = selected.getMonth();
    year = selected.getFullYear();
  }

  let months = getMonths(start, end);
  let monthIndex = 0;
  let visibleMonth = {};
  $: {
    for (let i = 0; i < months.length; i += 1) {
      if (months[i].month === month && months[i].year === year) monthIndex = i;
    }
    visibleMonth = months[monthIndex];
  }

  $: canIncrementMonth = monthIndex < months.length - 1;
  $: canDecrementMonth = monthIndex > 0;
  function incrementMonth(direction, date) {
    if (direction === 1 && !canIncrementMonth) return;
    if (direction === -1 && !canDecrementMonth) return;
    let current = new Date(year, month, 1);
    current.setMonth(current.getMonth() + direction);
    month = current.getMonth();
    year = current.getFullYear();
    selected = new Date(year, month, date || 1);
    dateValue =
      pad(selected.getMonth() + 1, 2) +
      "/" +
      pad(selected.getDate(), 2) +
      "/" +
      selected.getFullYear();
  }

  function pad(num, size) {
    var s = num + "";
    //while (s.length < size) s = "0" + s;
    return s;
  }

  function selectDate(dt) {
    selected = dt;
    dateValue =
      pad(dt.getMonth() + 1, 2) +
      "/" +
      pad(dt.getDate(), 2) +
      "/" +
      dt.getFullYear();
    dateClose();
  }
</script>

<svelte:window on:click={onDocumentClick} />

<div class="main{dtOpen ? ' active' : ''}">
  <input
    bind:value={dateValue}
    on:focus={dateOpen}
    placeholder="MM/DD/YYYY"
    size="20"
    type="text"
    class="cal datepicker"
  />
  <div class="grey{dtOpen ? ' active' : ''}">
    <FontAwesomeIcon
      icon={faCalendarAlt}
      style="padding: calc(0.3em - 1px) 0.5em;"
    />
  </div>
  {#if dtOpen}
    <div class="cal" transition:fade={{ duration: 150 }}>
      <div class="row m-0 p-0">
        <div class="col-1 m-0 px-0 py-1 text-center">
          {#if canDecrementMonth}<div
              role="button"
              tabindex="0"
              on:click|preventDefault={() =>
                incrementMonth(-1, selected.getDate())}
              on:keypress|preventDefault={() =>
                incrementMonth(-1, selected.getDate())}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>{/if}
        </div>
        <div class="col-10 m-0 px-0 py-1 text-center">
          {monthsOfYear[month][0]}
          {year}
        </div>
        <div class="col-1 m-0 px-0 py-1 text-center">
          {#if canIncrementMonth}<div
              role="button"
              tabindex="0"
              on:click|preventDefault={() =>
                incrementMonth(1, selected.getDate())}
              on:keypress|preventDefault={() =>
                incrementMonth(1, selected.getDate())}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>{/if}
        </div>
      </div>
      <div class="row m-0 p-0">
        {#each daysOfWeek as d}
          <div class="text-center small" style="width:14.285%;">{d[1]}</div>
        {/each}
      </div>
      {#if visibleMonth.weeks}
        {#each visibleMonth.weeks as wk}
          {#if wk.weekOfMonth}
            <div class="row m-0 p-0">
              {#each wk.days as d}
                <div
                  class="text-center{d.partOfMonth
                    ? ''
                    : ' nklightgrey'}{areDatesEquivalent(d.date, selected)
                    ? ' nkred nkbgyellow'
                    : ''}"
                  style="width:14.285%;"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() => selectDate(d.date)}
                  on:keypress|preventDefault={() => selectDate(d.date)}
                >
                  {d.date.getDate()}
                </div>
              {/each}
            </div>
          {/if}
        {/each}
      {/if}
    </div>
  {/if}
</div>
