<script>
  import { onMount } from "svelte";
  import { application, session, cart, user } from "../stores/stores.js";
  import { orderStatuses } from "../shared/OrderStatuses.js";
  import { paymentTypes, paymentCodes } from "../shared/Payments.js";
  import { itemTypes } from "../shared/ItemTypes.js";
  import Login from "./Login.svelte";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let orders = [];
  let selectedOrder = null;

  async function getOrders() {
    if (usr && usr.Cust_ID && usr.Cust_ID > 0) {
      var options = {
        method: "GET",
        //cache: "no-cache",
        headers: { Authorization: "Bearer " + sess.token },
      };
      await fetch(app.apiUrl + "order_headers/bycust/" + usr.Cust_ID, options)
        .then((res) => res.json())
        .then((res) => {
          orders = res;
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    } else orders = [];
  }

  function formatDate(dt) {
    let thisDate = new Date(dt.replace(/-/g, "/"));
    return thisDate.toLocaleDateString();
  }

  function formatMoney(number, decPlaces, decSep, thouSep) {
    (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
      (decSep = typeof decSep === "undefined" ? "." : decSep);
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "$-" : "$&nbsp;";
    var i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : "")
    );
  }

  async function selectOrder(id, index) {
    if (selectedOrder == id) selectedOrder = null;
    else {
      selectedOrder = id;
      var options = {
        method: "GET",
        //cache: "no-cache",
        headers: { Authorization: "Bearer " + sess.token },
      };
      if (
        orders[index].Addr_ID > 0 &&
        typeof orders[index].address == "undefined"
      ) {
        fetch(
          app.apiUrl + "cust_addr/" + usr.Cust_ID + "/" + orders[index].Addr_ID,
          options
        )
          .then((res) => res.json())
          .then((res) => {
            orders[index].address = res;
          })
          .catch((err) => {
            /*console.log(err)*/
          });
      }
      // if (
      //   orders[index].Pymnt_ID > 0 &&
      //   typeof orders[index].payment == "undefined"
      // ) {
      //   fetch(
      //     app.apiUrl +
      //       "cust_payments/" +
      //       usr.Cust_ID +
      //       "/" +
      //       orders[index].Pymnt_ID,
      //     options
      //   )
      //     .then((res) => res.json())
      //     .then((res) => {
      //       orders[index].payment = res;
      //     })
      //     .catch((err) => {
      //       /*console.log(err)*/
      //     });
      // }
      if (typeof orders[index].details == "undefined") {
        fetch(
          app.apiUrl +
            "order_details/" +
            usr.Cust_ID +
            "/" +
            orders[index].Order_Num,
          options
        )
          .then((res) => res.json())
          .then((res) => {
            orders[index].details = res;
            if (orders[index].details.length == 0)
              orders[index].details.push({ Order_Num: num, noresults: true });
          })
          .catch((err) => {
            /*console.log(err)*/
          });
      }
    }
  }

  onMount(async () => {
    await getOrders();
  });
</script>

{#if usr && usr.Cust_ID && usr.Cust_ID > 0}
  <div class="row m-1 p-0 border-bottom border-dark">
    <div class="col-12 m-0 py-0 px-2 nkred text-center">
      <h5><strong>Past Orders &ndash; Acct #{usr.Cust_ID}</strong></h5>
    </div>
  </div>
  {#each orders as order, index}
    <div
      class="row m-0 p-1{index % 2 == 0 ? ' nkbglightblue' : ''}"
      role="button"
      tabindex="0"
      on:click|preventDefault={() => selectOrder(order.id, index)}
      on:keypress|preventDefault={() => selectOrder(order.id, index)}
    >
      <div class="col-6">
        <strong>#{order.Cust_ID * 1000 + order.Order_Num}</strong><br />
        <em>{orderStatuses[order.Status]}</em>
      </div>
      <div class="col-6 text-right">
        <small>{formatDate(order.Order_DT)}</small>
        {#if selectedOrder != order.id}
          <br />Total: {@html formatMoney(
            parseFloat(order.Total_Paid) + parseFloat(order.Total_Due)
          )}
        {/if}
      </div>
    </div>
    {#if selectedOrder == order.id}
      <div class="row m-0 p-1 pt-0{index % 2 == 0 ? ' nkbglightblue' : ''}">
        <div class="col-5 m-0 p-2 small">
          {#if order.address}
            <strong>Delivery Address</strong><br />
            {order.address.Addr_1}<br />
            {#if order.address.Suite && order.address.Suite != ""}{order.address
                .Suite}<br />{/if}
            {order.address.City}, {order.address.State}
            {order.address.ZIP_1}<br />
            {order.address.Country}<br />
            {order.address.Phone}
          {/if}
        </div>
        <div class="col-1 m-0 p-0" />
        <div class="col-5 m-0 p-2 small">
          {#if order.Ship_By && order.Ship_By != ""}
            <strong>Delivery Method</strong><br />
            {order.Ship_By}<br /><br />
          {/if}
          <strong>Payment Method</strong><br />
          {paymentTypes[order.Pymnt_Type]}<br />
          {#if order.payment}
            x{order.payment.CC_Num}
            {order.payment.CC_ExpM}/{order.payment.CC_ExpY}
          {/if}
        </div>
      </div>
      <div class="row m-0 mb-1 p-1{index % 2 == 0 ? ' nkbglightblue' : ''}">
        {#if order.details && order.details.length}
          <div class="col-12 m-0 mb-1 p-0">
            {#if order.details[0].noresults}
              <p class="text-center"><em>No details found</em></p>
            {:else}
              {#each order.details as detail, ind}
                {#if detail.Typ == 0 || detail.Typ == 50 || detail.Typ == 55 || detail.Typ == 60}
                  <div
                    class="row m-0 py-1 px-0 small{detail.Package_ID != ''
                      ? ind % 2 == 0
                        ? ' NKc_FBlue'
                        : ' NKc_MBlue'
                      : ' NKc_LGray'}"
                  >
                    <div class="col-1 text-center">
                      {#if detail.Qty > 1}{detail.Qty}{/if}
                    </div>
                    <div class="col-9">{detail.Comic_Name}</div>
                    <div class="col-2 text-right">
                      {#if detail.Quoted_Price != 0}{@html formatMoney(
                          detail.Quoted_Price
                        )}{/if}
                    </div>
                  </div>
                {:else if detail.Typ == 10}
                  <div
                    class="row m-0 py-1 px-0 small{detail.Package_ID != ''
                      ? ' NKc_FGold'
                      : ' NKc_LGray'}"
                  >
                    <div class="col-1 text-center">
                      {#if detail.Qty > 1}{detail.Qty}{/if}
                    </div>
                    <div class="col-9">{detail.Comic_Name}</div>
                    <div class="col-2 text-right">
                      {#if detail.Quoted_Price != 0}{@html formatMoney(
                          detail.Quoted_Price
                        )}{/if}
                    </div>
                  </div>
                {:else if detail.Typ == 20}
                  <div
                    class="row m-0 py-1 px-0 small{detail.Package_ID != ''
                      ? ' NKc_LGreen'
                      : ' NKc_LGray'}"
                  >
                    <div class="col-1 text-center">
                      {#if detail.Qty > 1}{detail.Qty}{/if}
                    </div>
                    <div class="col-9">{detail.Comic_Name}</div>
                    <div class="col-2 text-right">
                      {#if detail.Quoted_Price != 0}{@html formatMoney(
                          detail.Quoted_Price
                        )}{/if}
                    </div>
                  </div>
                {:else if detail.Typ == 140 || detail.Typ == 141 || detail.Typ == 145 || detail.Typ == 100 || detail.Typ == 105 || detail.Typ == 110 || detail.Typ == 115 || detail.Typ == 120 || detail.Typ == 125 || detail.Typ == 130 || detail.Typ == 135 || detail.Typ == 150 || detail.Typ == 200}
                  <div class="row m-0 py-1 px-0 small NKc_LYellow">
                    <div class="col-1 text-center" />
                    <div class="col-9">{@html detail.Comic_Name}</div>
                    <div class="col-2 text-right">
                      {#if detail.Quoted_Price != 0}{@html formatMoney(
                          detail.Quoted_Price
                        )}{/if}
                    </div>
                  </div>
                {/if}
              {/each}
            {/if}
            <p class="m-0 py-0 px-2 text-right">
              Total: {@html formatMoney(
                parseFloat(order.Total_Paid) + parseFloat(order.Total_Due)
              )}
            </p>
          </div>
        {:else}
          <div class="col-12 text-center">
            <div
              class="spinner-border text-info my-1 mr-auto ml-auto"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        {/if}
      </div>
    {/if}
  {/each}
{:else}
  <Login />
{/if}
