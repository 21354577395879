<script>
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let days = [7, 14, 30];
  let daysSelected = days[0];

  let copies = [1, 10, 25];
  let copiesSelected = copies[0];

  let isLoading = true;
  let data = [];
  let titles = [];
  let navLetters = [];

  function goToIssues(tskey, subseries, issuenumber) {
    application.update((n) => {
      return {
        ...n,
        screen: "issues",
        screenmode: tskey,
        screencriteria: subseries,
        screensubcriteria: issuenumber,
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function scrollToLetters(ev, l) {
    if (document.querySelectorAll(".scroll" + l).length)
      animateScroll.scrollTo({
        container: "div#titlesList",
        element: ".scroll" + l,
      });
    else if (typeof ev.target.nextSibling.nextSibling != "undefined")
      ev.target.nextSibling.nextSibling.click();
  }

  function getNewArrivals() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    fetch(
      app.apiUrl + "new_arrivals/" + daysSelected + "/" + copiesSelected,
      options
    )
      .then((res) => res.json())
      .then((res) => {
        data = res;
        titles = [];
        navLetters = [];
        let lastTitleSeries = "";
        for (var i = 0; i < data.length; i++) {
          if (
            lastTitleSeries != data[i].Title + data[i].Series &&
            data[i].Issue_Num > 0
          ) {
            titles = [...titles, [data[i].Title, data[i].Series]];
            lastTitleSeries = data[i].Title + data[i].Series;
            var letter = lastTitleSeries.substring(0, 1).toUpperCase();
            if (navLetters.indexOf(letter) == -1)
              navLetters = [...navLetters, letter];
          }
        }
        isLoading = false;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  $: {
    const daysCopies = daysSelected + " - " + copiesSelected;
    isLoading = true;
    getNewArrivals();
  }
</script>

<div class="row m-0 p-1 border-bottom border-dark">
  <div class="col-12 m-0 p-1 text-center">
    In the past <select bind:value={daysSelected}
      >{#each days as d}<option>{d}</option>{/each}</select
    >
    days, we added<br />
    <select bind:value={copiesSelected}
      >{#each copies as c}<option>{c}</option>{/each}</select
    > or more copies of these comics:
  </div>
</div>

<div class="row m-0 p-0">
  <div class="col-11 overflow-auto" style="max-height:650px;" id="titlesList">
    {#if isLoading}
      <div class="container my-1 py-1 text-center">
        <div class="spinner-grow spinner-grow-sm text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    {/if}
    {#each titles as t}
      <div class="row m-0 mt-1 scroll{t[0].substring(0, 1).toUpperCase()}">
        <div class="col-12 px-2">
          {t[0]}
          {#if t[1] != ""}
            &nbsp;({t[1]})
          {/if}
        </div>
      </div>
      <div class="row m-0 mb-1">
        <div class="col-1 p-1" />
        <div class="col-11 p-1">
          {#each data.filter((item) => item.Title == t[0] && item.Series == t[1] && item.Issue_Num > 0) as i}
            <span
              class="d-inline-block mr-2 p-0 nkblue text-nowrap"
              role="button"
              tabindex="0"
              on:click|preventDefault={() =>
                goToIssues(i.TS_Key, i.Sub_Series, i.Issue_Num)}
              on:keypress|preventDefault={() =>
                goToIssues(i.TS_Key, i.Sub_Series, i.Issue_Num)}
              >#{i.Sub_Series != ""
                ? i.Sub_Series + " "
                : ""}{i.Issue_Num}{i.Issue_Txt != ""
                ? " " + i.Issue_Txt
                : ""}{i.Printing != "" ? " " + i.Printing : ""}</span
            >
          {/each}
        </div>
      </div>
    {/each}
  </div>
  <div class="col-1 text-center">
    {#each navLetters as letter}
      <a
        href={null}
        role="button"
        tabindex="0"
        on:click|preventDefault={(event) => scrollToLetters(event, letter)}
        on:keypress|preventDefault={(event) => scrollToLetters(event, letter)}
        >{letter}</a
      ><br />
    {/each}
  </div>
</div>
