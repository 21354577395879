<div class="row m-0 p-0 border-bottom border-dark">
  <div class="col-12 m-0 p-0 text-center nkred">
    <h4 class="title">Security &amp; Privacy</h4>
  </div>
</div>
<div class="row m-0 mb-1 p-1">
  <div class="col-12 m-0 mb-1 p-1">
    <h5>Privacy</h5>
    <p class="indent">
      We'll <strong>never share</strong> your personal or financial information
      with anyone, ever. McAfee rates NewKadia
      <strong>&quot;SAFE&quot;</strong>. We don’t<img
        src="https://img.newkadia.com/A/McAfee.png"
        alt="McAfee SAFE"
        align="right"
        class="pad1_5"
      /> spam, phish, or place adware or spyware on your computer.
    </p>
    <p class="indent">
      We use your email and delivery address to process your order and to tell
      you about new offers.
    </p>
    <h5>Security</h5>
    <p style="text-align:center;text-indent:0;font-size:120%;">
      Your information is 100% safe
    </p>
    <p style="text-indent:0;">
      <img
        src="https://www.newkadia.com/A/SSLsecure.jpg"
        alt="SSL Secure"
        align="left"
        class="pad1_5"
      />Your debit or credit card and name and address are encrypted with secure
      server software (SSL) – the industry standard – used by Amazon, Apple,
      PayPal, and eBay. The information can’t be read as it travels the
      internet.
    </p>
    <p class="indent">
      If using Google Chrome you’ll see the padlock icon in the address bar. If
      using Safari or Microsoft Edge, the green color in the address bar
      confirms our secure website.
    </p>
    <p class="indent">
      Still concerned? We also accept PayPal, checks and money orders.
    </p>
    <h5>Cookies</h5>
    <p class="indent">
      We use cookies and other technologies to improve your browsing experience,
      to display personalized content, to analyze our website traffic. By
      browsing our website, you consent to our use of cookies and other tracking
      technologies.
    </p>
  </div>
</div>
