<script>
  import { onMount } from "svelte";
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  function goTo(s) {
    application.update((n) => {
      return {
        ...n,
        screen: s,
        screenmode: "",
        screencriteria: "",
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function goToCriteria(m, s) {
    application.update((n) => {
      return {
        ...n,
        screen: "titles",
        screenmode: m,
        screencriteria: s,
        screensubcriteria: "",
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function goToSubCriteria(m, s, ss) {
    application.update((n) => {
      return {
        ...n,
        screen: "titles",
        screenmode: m,
        screencriteria: s,
        screensubcriteria: ss,
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function goToDiscounted(type, criteria) {
    if (type == "publisher") {
      goToSubCriteria("publishers", criteria, "A");
    } else if (type == "type") {
      goToSubCriteria("categories", criteria, "A");
    } else if (type == "hero") {
      goToCriteria("heroes", criteria);
    } else if (type == "era") {
      if (criteria == "lastfiveyears") criteria = "5";
      goToSubCriteria("era", criteria, "A");
    }
  }

  let monthsOfYear = [
    ["January", "Jan"],
    ["February", "Feb"],
    ["March", "Mar"],
    ["April", "Apr"],
    ["May", "May"],
    ["June", "Jun"],
    ["July", "Jul"],
    ["August", "Aug"],
    ["September", "Sep"],
    ["October", "Oct"],
    ["November", "Nov"],
    ["December", "Dec"],
  ];

  let isWorking = true;
  let showCouponDetails = false;
  let discounts = [];
  let dt = new Date();
  dt = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
  async function getDiscounts() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "discounts/today", options)
      .then((res) => res.json())
      .then((res) => {
        discounts = res;
        isWorking = false;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  onMount(async () => {
    await getDiscounts();
  });
</script>

{#if showCouponDetails}
  <div
    class="shade"
    role="button"
    tabindex="0"
    on:click|preventDefault={() => {
      showCouponDetails = false;
    }}
    on:keypress|preventDefault={() => {
      showCouponDetails = false;
    }}
  />
  <div class="informationModal">
    <button
      type="button"
      class="close"
      aria-label="Close"
      style="margin:-0.5em -0.25em 0 0;"
      on:click|preventDefault={() => {
        showCouponDetails = false;
      }}><span aria-hidden="true">&times;</span></button
    >
    <p style="width:85vw;text-indent:0.5em;">
      Coupons are valid only for comics in stock over 60 days. Their prices
      appear in <span class="nkgreen strong">green</span>.
    </p>
    <p style="width:85vw;text-indent:0.5em;">
      Here's why: When coupons were valid anytime, competitors would scour our
      website every day for key issues, buy them with a coupon, and resell them
      at full price.
    </p>
    <p style="width:85vw;text-indent:0.5em;">
      But now, for 60 days, coupons are not valid so regular customers like you
      have 60 days to get these key issues.
    </p>
  </div>
{/if}
<div class="row m-0 p-0">
  <div class="col-12 m-0 p-0" style="position:relative;">
    {#if isWorking}
      <div class="subshade" />
      <div class="subshadeloader">
        <div class="container my-1 py-1 text-center">
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    {/if}
    <div class="row m-0 p-0 border-bottom border-dark">
      <div class="col-12 m-0 p-0 text-center nkred">
        <h4 class="title">Today's Sales</h4>
      </div>
    </div>
    <div class="row m-0 p-0">
      <div class="col-12 m-0 p-0 text-center">
        <p class="p-1 m-0">
          Coupons good for comics in stock over <span class="nkgreen"
            >60 days</span
          >
        </p>
        <p
          class="m-1 nkblue strong"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => {
            showCouponDetails = true;
          }}
          on:keypress|preventDefault={() => {
            showCouponDetails = true;
          }}
        >
          Here's why
        </p>
        {#if !usr || !usr.Orders_Placed || usr.Orders_Placed == 0}
          <div class="coupon newuser">
            <div class="row m-0 p-0 border-bottom border-red banner top">
              <div class="col-12 text-center larger133 strong">
                New Customers Only
              </div>
            </div>
            <div class="row m-0 px-0 py-2">
              <div class="col-4 m-0 p-0 text-center">
                <img
                  src="https://www.covernk.com/Covers/L/A/Amazing%20Spider-Man%202003%20series/amazingspider-man2003series520.jpg"
                  alt="Comic Book"
                  class="smallCoverImg"
                />
              </div>
              <div class="col-8 m-0 p-0">
                <p class="text-left mb-0">Save 15% Orders up to $99</p>
                <p class="text-left m-0">Save 25% Orders $99 to $498</p>
                <p class="text-left mt-0">Save 30% Orders $499 &amp; up</p>
                <p>Use code: <span class="larger120 strong">New4</span></p>
              </div>
            </div>
          </div>
        {/if}
        {#if discounts && discounts.daily && discounts.daily.length}
          {#each discounts.daily as c}
            <div class="coupon daily">
              <div class="row m-0 mb-1 p-0 banner top">
                <div class="col-12 text-center larger133 strong">
                  24 Hour Sale<br /><small>A new sale every 24 hours</small>
                </div>
              </div>
              <img
                src={c.images[0]}
                alt="Comic Book"
                class="smallCoverImg d-inline-block m-1 align-top"
              />
              <div
                class="d-inline-block m-0 p-0 align-top text-center"
                style="width:calc(100% - 110px - 1em);max-width:calc(100% - 110px - 1em);"
              >
                <p class="nkred larger133 strong mb-0 pt-1">
                  Save {c.Value * 100}%
                </p>
                <p
                  class="nkblue larger120 strong m-0"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() =>
                    goToDiscounted(c.restrictiontype, c.restrictioncriteria)}
                  on:keypress|preventDefault={() =>
                    goToDiscounted(c.restrictiontype, c.restrictioncriteria)}
                >
                  {c.displayTitle}
                </p>
                <p class="mt-2">
                  Use code: <span class="larger120 strong">{c.Code}</span>
                </p>
              </div>
              <div class="row m-0 mt-1 p-0 banner bottom">
                <div class="col-12 text-center small">
                  Ends at 11:59PM Eastern USA Time
                </div>
              </div>
            </div>
          {/each}
        {/if}
        {#if discounts && discounts["3day"] && discounts["3day"].length}
          {#each discounts["3day"] as c}
            <div class="coupon threeday">
              <div class="row m-0 mb-1 p-0 banner top">
                <div class="col-12 text-center larger133 strong">
                  3-day Sale<br /><small>Friday, Saturday &amp; Sunday</small>
                </div>
              </div>
              <img
                src={c.images[0]}
                alt="Comic Book"
                class="smallCoverImg d-inline-block m-1 align-top"
              />
              <div
                class="d-inline-block m-0 p-0 align-top text-center"
                style="width:calc(100% - 110px - 1em);max-width:calc(100% - 110px - 1em);"
              >
                <p class="nkred larger133 strong mb-0 pt-1">
                  Save {c.Value * 100}%
                </p>
                <p
                  class="nkblue larger120 strong m-0"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() =>
                    goToDiscounted(c.restrictiontype, c.restrictioncriteria)}
                  on:keypress|preventDefault={() =>
                    goToDiscounted(c.restrictiontype, c.restrictioncriteria)}
                >
                  {c.displayTitle}
                </p>
                <p class="mt-2">
                  Use code: <span class="larger120 strong">{c.Code}</span>
                </p>
              </div>
              <div class="row m-0 mt-1 p-0 banner bottom">
                <div class="col-12 text-center small">
                  Ends Sunday 11:59PM Eastern USA Time
                </div>
              </div>
            </div>
          {/each}
        {:else}
          <div class="coupon nkbgred text-center">
            <p class="larger133 strong white mb-0 pt-1">50% off Sale</p>
            <p class="strong nkyellow m-0">
              Check here Friday, Saturday &amp; Sunday
            </p>
            <p class="nkyellow mt-0">Eastern USA Time</p>
          </div>
        {/if}
        {#if discounts && discounts.weekly && discounts.weekly.length}
          {#each discounts.weekly as c}
            <div class="coupon weekly">
              <div class="row m-0 mb-1 p-0 banner top">
                <div class="col-12 text-center larger133 strong">
                  1 Week Sale
                </div>
              </div>
              <img
                src={c.images[0]}
                alt="Comic Book"
                class="smallCoverImg d-inline-block m-1 align-top"
              />
              <div
                class="d-inline-block m-0 p-0 align-top text-center"
                style="width:calc(100% - 110px - 1em);max-width:calc(100% - 110px - 1em);"
              >
                <p class="nkred larger133 strong mb-0 pt-1">
                  Save {c.Value * 100}%
                </p>
                <p
                  class="nkblue larger120 strong m-0"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() =>
                    goToDiscounted(c.restrictiontype, c.restrictioncriteria)}
                  on:keypress|preventDefault={() =>
                    goToDiscounted(c.restrictiontype, c.restrictioncriteria)}
                >
                  {c.displayTitle}
                </p>
                <p class="mt-2">
                  Use code: <span class="larger120 strong">{c.Code}</span>
                </p>
              </div>
              <div class="row m-0 mt-1 p-0 banner bottom">
                <div class="col-12 text-center small">
                  Ends Saturday 11:59PM Eastern USA Time
                </div>
              </div>
            </div>
          {/each}
        {/if}
        {#if discounts && discounts.monthly && discounts.monthly.length}
          {#each discounts.monthly as c}
            <div class="coupon monthly">
              <div class="row m-0 mb-1 p-0 banner top">
                <div class="col-12 text-center larger133 strong">
                  1 Month Sale
                </div>
              </div>
              <img
                src={c.images[0]}
                alt="Comic Book"
                class="smallCoverImg d-inline-block m-1 align-top"
              />
              <div
                class="d-inline-block m-0 p-0 align-top text-center"
                style="width:calc(100% - 110px - 1em);max-width:calc(100% - 110px - 1em);"
              >
                <p class="nkred larger133 strong mb-0 pt-1">
                  Save {c.Value * 100}%
                </p>
                <p
                  class="nkblue larger120 strong m-0"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() =>
                    goToDiscounted(c.restrictiontype, c.restrictioncriteria)}
                  on:keypress|preventDefault={() =>
                    goToDiscounted(c.restrictiontype, c.restrictioncriteria)}
                >
                  {c.displayTitle}
                </p>
                <p class="mt-2">
                  Use code: <span class="larger120 strong">{c.Code}</span>
                </p>
              </div>
              <div class="row m-0 mt-1 p-0 banner bottom">
                <div class="col-12 text-center small">
                  Ends {monthsOfYear[dt.getMonth()][1]}. {dt.getDate()} 11:59PM Eastern
                  USA Time
                </div>
              </div>
            </div>
          {/each}
        {/if}
        <div class="coupon overstock">
          <div class="row m-0 mb-1 p-0 banner top">
            <div class="col-12 text-center larger133 strong">Overstock</div>
          </div>
          <img
            src="https://www.covernk.com/Covers/L/W/Wolverine%20the%20X-Men%202014%20series/wolverinethex-men2014series1.jpg"
            alt="Comic Book"
            class="smallCoverImg d-inline-block m-1 align-top"
          />
          <div
            class="d-inline-block m-0 p-0 align-top text-center"
            style="width:calc(100% - 110px - 1em);max-width:calc(100% - 110px - 1em);"
          >
            <p class="nkred larger133 strong mb-0 pt-1">Save 80% or more</p>
            <p
              class="nkblue larger120 strong m-0"
              role="button"
              tabindex="0"
              on:click|preventDefault={() => goTo("ninetypercent")}
              on:keypress|preventDefault={() => goTo("ninetypercent")}
            >
              Under 60&cent;
            </p>
            <p class="strong mt-2">No code needed</p>
          </div>
        </div>
        {#if discounts && discounts.clearance && discounts.clearance.length}
          {#each discounts.clearance as c}
            <div class="coupon clearance">
              <div class="row m-0 mb-1 p-0 banner top">
                <div class="col-12 text-center larger133 strong">Clearance</div>
              </div>
              <img
                src={c.images[0]}
                alt="Comic Book"
                class="smallCoverImg d-inline-block m-1 align-top"
              />
              <div
                class="d-inline-block m-0 p-0 align-top text-center"
                style="width:calc(100% - 110px - 1em);max-width:calc(100% - 110px - 1em);"
              >
                <p class="nkred larger133 strong mb-0 pt-1">
                  Save {c.Value * 100}%
                </p>
                <p
                  class="nkblue larger120 strong m-0"
                  role="button"
                  tabindex="0"
                  on:click|preventDefault={() =>
                    goToDiscounted(c.restrictiontype, c.restrictioncriteria)}
                  on:keypress|preventDefault={() =>
                    goToDiscounted(c.restrictiontype, c.restrictioncriteria)}
                >
                  {c.displayTitle}
                </p>
                <p class="mt-2">
                  Use code: <span class="larger120 strong">{c.Code}</span>
                </p>
              </div>
              <div class="row m-0 mt-1 p-0 banner bottom">
                <div class="col-12 text-center small">
                  Sale may end at any time
                </div>
              </div>
            </div>
          {/each}
        {/if}
      </div>
    </div>
    <div class="row m-1 mb-2 p-1">
      <div class="col-12 m-0 py-3 px-6 text-center">
        <img
          src="https://www.newkadia.com/A/NewKadia20-200x366.png"
          alt="NewKadia.com - 20 years online, 2000-2020"
          style="max-width:90%;"
        />
      </div>
    </div>
  </div>
</div>
