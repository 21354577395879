<script>
  import { onMount } from "svelte";
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { countries } from "../shared/CountriesStates.js";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let crt;
  const cartUnsubscribe = cart.subscribe((value) => {
    crt = value;
  });

  function formatMoney(number, decPlaces, decSep, thouSep) {
    (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
      (decSep = typeof decSep === "undefined" ? "." : decSep);
    thouSep = typeof thouSep === "undefined" ? "," : thouSep;
    var sign = number < 0 ? "-" : "";
    if (sess.currency == "USD") sign = "$" + sign;
    var i = String(
      parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
    );
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (
      sign +
      (j ? i.substr(0, j) + thouSep : "") +
      i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
      (decPlaces
        ? decSep +
          Math.abs(number - i)
            .toFixed(decPlaces)
            .slice(2)
        : "") +
      (sess.currency != "USD" ? "<small>" + sess.currency + "</small>" : "")
    );
  }

  let isWorking = true;

  let shippingOptions = [];
  async function getShippingOptions() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    var url =
      app.apiUrl +
      "newcust_shipping/" +
      sess.country +
      "/" +
      (sess.state && sess.state != "" ? sess.state : "0") +
      "/" +
      sess.currency +
      "/0/1/5.00";
    if (usr && usr.Orders_Placed && usr.Orders_Placed > 0)
      url =
        app.apiUrl +
        "shipping/" +
        sess.country +
        "/" +
        (sess.state && sess.state != "" ? sess.state : "0") +
        "/" +
        sess.currency +
        "/0/1/5.00";
    await fetch(url, options)
      .then((res) => res.json())
      .then((res) => {
        shippingOptions = res;
        for (var i = 0; i < shippingOptions.length; i++) {
          shippingOptions[i].Ship_By = shippingOptions[i].Ship_By.replace(
            /\s\s+/gi,
            ""
          );
          shippingOptions[i].Ship_By =
            shippingOptions[i].Ship_By.split("</b>")[0];
        }
        isWorking = false;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }
  function updateCountry(ev) {
    isWorking = true;
    session.update((n) => {
      return { ...n, country: ev.target.value };
    });
    getShippingOptions();
  }

  onMount(async () => {
    await getShippingOptions();
  });
</script>

<div class="row m-0 mb-3 p-0">
  <div class="col-12 m-0 p-0" style="position:relative;">
    {#if isWorking}
      <div class="subshade" />
      <div class="subshadeloader">
        <div class="container my-1 py-1 text-center">
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-light" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    {/if}
    <div class="row m-0 p-0 border-bottom border-dark">
      <div class="col-12 m-0 p-0 text-center nkred">
        <h4 class="title">Shipping</h4>
      </div>
    </div>
    <div class="row m-0 mb-1 p-1">
      <div class="col-12 m-0 mb-1 p-1">
        <h5>Same Day Shipping</h5>
        <ul class="pis125">
          <li>Order weekdays by 5PM Eastern USA time, and we ship that day</li>
          <li>
            Order weekdays after 5PM Eastern USA time or weekends, and we ship
            the next weekday
          </li>
        </ul>
        <hr class="border-dark" />
        <h5>
          Select Shipping Method<br /><small>at the bottom of your Cart</small>
        </h5>
        <p class="m-0 p-0 pt-2 text-center" style="text-indent:0;">
          Shipping options to...
        </p>
        <select
          class="d-block w-75 m-0 ml-auto mr-auto px-1 pb-2"
          bind:value={sess.country}
          on:change={(event) => updateCountry(event)}
        >
          {#each countries as country}
            <option value={country.abbreviation}>{country.name}</option>
          {/each}
        </select>
        {#if shippingOptions.length}
          <ul class="pis125">
            {#each shippingOptions as so}
              <li style="white-space:nowrap;">
                <div
                  class="d-inline-block align-middle"
                  style="white-space:break-spaces;width:40%;"
                >
                  {@html "<b>" + so.Ship_By}
                </div>
                <div
                  class="d-inline-block align-middle"
                  style="white-space:break-spaces;width:59%;"
                >
                  {@html formatMoney(so.Base_Cost)}
                  {#if so.Group_Size > 1 && so.Group_Size < 1000}
                    for up to {so.Group_Size} books
                  {:else if so.Comic_Cost > 0}
                    + {@html formatMoney(so.Comic_Cost)}/book
                  {:else}
                    for any size order
                  {/if}
                </div>
              </li>
            {/each}
          </ul>
        {/if}
        <hr class="border-dark" />
        <h5>We'll email your tracking number</h5>
        <hr class="border-dark" />
        <h5>Secure Packing</h5>
        <p class="indent">
          <img
            src="https://img.newkadia.com/A/CustomBoxes171hx250w.jpg"
            alt="Secure Packaging"
            align="left"
            class="pad25_5_width40"
          />We use strong boxes. The top one is for 15-30 comics. The ends have
          3 walls of cardboard protection!
        </p>
        <p class="indent">
          The box in front is for smaller orders. It has extra corner
          protection.
        </p>
        <p class="indent">
          Both have easy opening tearstrips. You won't need a knife, scissors,
          or keys to open them!
        </p>
      </div>
    </div>
  </div>
</div>
