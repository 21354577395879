<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  import { application, session, cart, user } from "../stores/stores.js";
  import {
    countries,
    usStates,
    auStates,
    esStates,
    gbStates,
    jpStates,
    caStates,
  } from "../shared/CountriesStates.js";
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import {
    faChevronDown,
    faChevronRight,
    faPlus,
    faTrashAlt,
  } from "@fortawesome/pro-solid-svg-icons";

  const dispatch = createEventDispatcher();

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let originalAddresses = [];
  let addresses = [];
  let selectedAddress = null;
  let addressError = "";
  let addressUpdating = false;

  async function getAddresses() {
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    await fetch(app.apiUrl + "cust_addrs/" + usr.Cust_ID, options)
      .then((res) => res.json())
      .then((res) => {
        addresses = res;
        originalAddresses = JSON.parse(JSON.stringify(res));
        dispatch("message", { data: res });
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  function addAddress() {
    var newAddress = {
      id: 0,
      Cust_ID: usr.Cust_ID,
      Addr_1: "New Address",
      Country: sess.country,
      Current: "",
    };
    addresses = [...addresses, newAddress];
    originalAddresses = [...originalAddresses, newAddress];
    selectedAddress = 0;
  }

  function cancelAddressChange(index) {
    addresses[index] = JSON.parse(JSON.stringify(originalAddresses[index]));
    addresses = addresses;
    selectedAddress = null;
  }

  function delAddress(add) {
    selectedAddress = null;
    if (add.id > 0) {
      add.Current = "X";
      addresses = addresses;
      updateAddresses(null);
    } else {
      addresses = addresses.filter(function (value, index, arr) {
        return value != add;
      });
    }
  }

  async function updateAddresses(ev) {
    if (!addressUpdating) {
      addressUpdating = true;
      var BreakException = {};
      var err = [];
      try {
        addresses.forEach(function (val, ind, arr) {
          err = [];
          if (!val.Name || val.Name == "") err.push("missing name");
          if (!val.Addr_1 || val.Addr_1 == "" || val.Addr_1 == "New Address")
            err.push("missing street address");
          if (!val.City || val.City == "") err.push("missing city");
          if (!val.State || val.State == "") err.push("missing state/province");
          if (!val.ZIP_1 || val.ZIP_1 == "")
            err.push("missing zip/postal code");
          if (!val.Country || val.Country == "") err.push("missing country");
          selectedAddress = val.id;
          if (err.length) throw BreakException;
        });
      } catch (e) {
        addressError =
          "<strong>Please correct the following errors:</strong><br/><small>" +
          err.join("<br/>") +
          "</small>";
        addressUpdating = false;
        return false;
      }
      var options = {
        method: "PUT",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(addresses),
      };
      await fetch(app.apiUrl + "cust_addrs", options)
        .then((res) => res.json())
        .then((res) => {
          addressUpdating = false;
          selectedAddress = null;
          addresses = res;
          originalAddresses = JSON.parse(JSON.stringify(res));
          dispatch("message", { data: res });
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    }
  }

  onMount(async () => {
    await getAddresses();
  });
</script>

{#if addressError != ""}
  <div class="modalNotification">
    <button
      type="button"
      class="close"
      aria-label="Close"
      style="margin-top:-0.5em;"
      on:click|preventDefault={() => {
        addressError = "";
      }}><span aria-hidden="true">&times;</span></button
    >
    {@html addressError}
  </div>
{/if}
{#each addresses as address, addIndex}
  {#if address.Current != "X"}
    <div
      class="row mx-0 mb-0 mt-1 p-1 nkbglightergrey clearfix rounded-top{selectedAddress ==
      address.id
        ? ''
        : ' rounded-bottom'}"
    >
      <div class="col-11 m-0 p-0">
        {address.Addr_1},
        {#if typeof address.City != "undefined" && address.City != ""}
          {address.City},
        {/if}
        {#if typeof address.State != "undefined" && address.State != ""}
          {address.State},
        {/if}
        {address.Country}
      </div>
      <div
        class="col-1 m-0 p-0 text-center"
        role="button"
        tabindex="0"
        on:click|preventDefault={() => {
          if (selectedAddress == address.id) selectedAddress = null;
          else selectedAddress = address.id;
        }}
        on:keypress|preventDefault={() => {
          if (selectedAddress == address.id) selectedAddress = null;
          else selectedAddress = address.id;
        }}
      >
        {#if selectedAddress == address.id}
          <FontAwesomeIcon icon={faChevronDown} />
        {:else}
          <FontAwesomeIcon icon={faChevronRight} />
        {/if}
      </div>
    </div>
    {#if selectedAddress == address.id}
      <div class="row m-0 p-1 border border-dark border-top-0 rounded-bottom">
        <div class="col-12 m-0 p-0 clearfix">
          <input
            type="text"
            class="w-100 mb-1 px-1 border-red"
            placeholder="Name"
            bind:value={address.Name}
          />
          <input
            type="text"
            class="w-100 mb-1 px-1"
            placeholder="Company"
            bind:value={address.Company}
          />
          <input
            type="text"
            class="w-100 mb-1 px-1 border-red"
            placeholder="Street or P.O. Box"
            bind:value={address.Addr_1}
          />
          <input
            type="text"
            class="w-100 mb-1 px-1"
            placeholder="Apt, Suite, Unit or Bldg"
            bind:value={address.Suite}
          />
          <input
            type="text"
            class="w-100 mb-1 px-1 border-red"
            placeholder="City"
            bind:value={address.City}
          />
          {#if address.Country == "US"}
            <select
              class="w-100 mb-1 px-1 border-red border-width-2"
              bind:value={address.State}
            >
              <option value="">- Select State -</option>
              {#each usStates as st}
                <option value={st.abbreviation}>{st.name}</option>
              {/each}
            </select>
          {:else if address.Country == "AU"}
            <select
              class="w-100 mb-1 px-1 border-red border-width-2"
              bind:value={address.State}
            >
              <option value="">- Select State -</option>
              {#each auStates as st}
                <option value={st.abbreviation}>{st.name}</option>
              {/each}
            </select>
          {:else if address.Country == "ES"}
            <select
              class="w-100 mb-1 px-1 border-red border-width-2"
              bind:value={address.State}
            >
              <option value="">- Select Province -</option>
              {#each esStates as st}
                <option value={st.abbreviation}>{st.name}</option>
              {/each}
            </select>
          {:else if address.Country == "GB"}
            <select
              class="w-100 mb-1 px-1 border-red border-width-2"
              bind:value={address.State}
            >
              <option value="">- Select County -</option>
              {#each gbStates as st}
                <option value={st.name}>{st.region} - {st.name}</option>
              {/each}
            </select>
          {:else if address.Country == "JP"}
            <select
              class="w-100 mb-1 px-1 border-red border-width-2"
              bind:value={address.State}
            >
              <option value="">- Select County -</option>
              {#each jpStates as st}
                <option value={st.name}>{st.name}</option>
              {/each}
            </select>
          {:else if address.Country == "CA"}
            <select
              class="w-100 mb-1 px-1 border-red border-width-2"
              bind:value={address.State}
            >
              <option value="">- Select Province -</option>
              {#each caStates as st}
                <option value={st.abbreviation}>{st.name}</option>
              {/each}
            </select>
          {:else}
            <input
              type="text"
              class="w-100 mb-1 px-1 border-red"
              placeholder="State / Province"
              bind:value={address.State}
            />
          {/if}
          <input
            type="text"
            class="w-100 mb-1 px-1 border-red"
            placeholder={address.Country == "US"
              ? "5-Digit Zip Code"
              : "Postal Code"}
            bind:value={address.ZIP_1}
          />
          <select
            class="w-100 mb-1 px-1 border-red border-width-2"
            bind:value={address.Country}
          >
            {#each countries as country}
              <option value={country.abbreviation}>{country.name}</option>
            {/each}
          </select>
          <input
            type="text"
            class="w-100 mb-1 px-1"
            placeholder="Area Code + Phone (optional)"
            bind:value={address.Phone}
          />
          <textarea
            class="w-100 mb-1 px-1"
            placeholder="Delivery instructions (optional)"
            bind:value={address.Instr}
          />
          <button
            class="btn btn-primary btn-sm float-right"
            on:click|preventDefault={(event) => updateAddresses(event)}
            >Save
            <div
              class="spinner-grow spinner-grow-sm m-0 ml-1 p-0 align-middle"
              style="display:{addressUpdating ? 'inline-block' : 'none'};"
            /></button
          >
          <button
            class="btn btn-secondary btn-sm mr-1 float-right"
            on:click|preventDefault={() => cancelAddressChange(addIndex)}
            >Cancel</button
          >
          <button
            class="btn btn-danger btn-sm mr-1 float-right"
            on:click|preventDefault={() => delAddress(address)}
            ><FontAwesomeIcon icon={faTrashAlt} /></button
          >
        </div>
      </div>
    {/if}
  {/if}
{/each}
<div class="container mx-0 mb-0 mt-1 p-0 clearfix">
  <button
    class="btn btn-secondary btn-sm float-right"
    on:click|preventDefault={addAddress}
    ><FontAwesomeIcon icon={faPlus} /></button
  >
</div>
