<script>
  import { FontAwesomeIcon } from "@fortawesome/svelte-fontawesome";
  import {
    faEye,
    faEyeSlash,
    faExclamationTriangle,
  } from "@fortawesome/pro-solid-svg-icons";

  import {
    application,
    history,
    session,
    cart,
    user,
  } from "../stores/stores.js";

  let username = "";
  let userErr = "";
  let password = "";
  let passErr = "";
  export let goBack = false;

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
    username = value.email;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  let hist;
  const histUnsubcribe = history.subscribe((value) => {
    hist = value;
  });
  function rewindHistory() {
    if (hist.length > 0) {
      var hs = hist;
      hs.pop();
      var lastPage = hs.pop();
      history.update((h) => {
        return hs;
      });
      application.update((n) => {
        return {
          ...n,
          screen: lastPage.screen,
          screenmode: lastPage.screenmode,
          screencriteria: lastPage.screencriteria,
          screensubcriteria: lastPage.screensubcriteria,
          letternav: sess.lastSubNavLetter,
        };
      });
    }
  }

  async function doLogin() {
    if (
      document.getElementById("loginBtn").querySelector("div").style.display !=
      "inline-block"
    ) {
      var options = {
        method: "POST",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + sess.token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user: username,
          pass: password,
          sessionid: sess.id,
        }),
      };
      document.getElementById("loginBtn").querySelector("div").style.display =
        "inline-block";
      await fetch(app.apiUrl + "login", options)
        .then((res) => res.json())
        .then((res) => {
          document
            .getElementById("loginBtn")
            .querySelector("div").style.display = "none";
          if (typeof res.errors != "undefined") {
            if (typeof res.errors.username != "undefined")
              userErr = res.errors.username;
            else userErr = "";
            if (typeof res.errors.password != "undefined")
              passErr = res.errors.password;
            else passErr = "";
          } else {
            session.update((n) => {
              return { ...n, token: res.token, email: username };
            });
            user.update((n) => {
              return res.user;
            });
            if (sess.shippingSelected && sess.shippingSelected.ship_id) {
              var options = {
                method: "GET",
                cache: "no-cache",
                headers: {
                  Authorization: "Bearer " + sess.token,
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              };
              fetch(
                app.apiUrl +
                  "shippinglookup/" +
                  sess.shippingSelected.Destination +
                  "/" +
                  sess.shippingSelected.Ship_Code +
                  "/" +
                  sess.currency,
                options
              )
                .then((res) => res.json())
                .then((res) => {
                  session.update((n) => {
                    return { ...n, shippingSelected: res[0] };
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (goBack) rewindHistory();
          }
        })
        .catch((err) => {
          /*console.log(err)*/
        });
    }
  }

  function userOnKey(ev) {
    if (!ev) ev = window.event;
    var keycode = ev.keyCode || ev.which;
    if (keycode == "13") {
      document.querySelector("input.pwd").focus();
      return false;
    }
  }

  function passOnKey(ev) {
    if (!ev) ev = window.event;
    var keycode = ev.keyCode || ev.which;
    if (keycode == "13") {
      doLogin();
      return false;
    }
  }

  let passwordField;
  let passwordShown = false;
  function togglePwd(ev) {
    passwordShown = !passwordShown;
    if (passwordShown) {
      passwordField.type = "text";
    } else {
      passwordField.type = "password";
    }
  }
</script>

<div class="row mx-1 mb-1 mt-2 p-1">
  <div class="col-12 m-0 p-0" style="font-size:120%;">
    <h1 class="h1_15">Sign-in</h1>
  </div>
</div>
<div class="row m-1 p-1">
  <div class="col-12 m-0 p-0" style="font-size:120%;">
    <p class="login">
      <strong>Returning customers</strong><br />Enter your email and password
      below.
    </p>
    <p class="login">
      <strong>First-Time Customers</strong><br />Enter your email and create a
      password below to create an account.
    </p>
  </div>
</div>
<div class="row m-1 p-1">
  <div class="col-12 m-0 p-0">
    <input
      class="w-100 pad55"
      placeholder="Email"
      type="text"
      bind:value={username}
      on:keydown={(event) => userOnKey(event)}
    />
    <div class="invalid-feedback" style={userErr != "" ? "display:block;" : ""}>
      <FontAwesomeIcon icon={faExclamationTriangle} />{userErr}
    </div>
  </div>
</div>
<div class="row m-1 p-1">
  <div class="col-12 m-0 p-0">
    <input
      class="w-100 pwd pad55"
      placeholder="Password (8 to 50 characters)"
      type="password"
      bind:this={passwordField}
      bind:value={password}
      on:keydown={(event) => passOnKey(event)}
      data-openreplay-obscured
    /><span
      class="togglePwd"
      role="button"
      tabindex="0"
      on:click|preventDefault={(event) => togglePwd(event)}
      on:keypress|preventDefault={(event) => togglePwd(event)}
      >{#if passwordShown}<FontAwesomeIcon
          icon={faEyeSlash}
        />{:else}<FontAwesomeIcon icon={faEye} />{/if}</span
    >
    <div class="invalid-feedback" style={passErr != "" ? "display:block;" : ""}>
      <FontAwesomeIcon icon={faExclamationTriangle} />{passErr}
    </div>
  </div>
</div>
<div class="row mx-1 mt-2 mb-1 p-1">
  <div class="col-12 m-0 p-0">
    <button
      class="btn btn-primary border-0 nkbgred white w-100"
      id="loginBtn"
      on:click|preventDefault={doLogin}
      >Sign-in
      <div
        class="spinner-grow spinner-grow-sm m-0 ml-1 p-0 align-middle"
        style="display:none;"
      /></button
    >
  </div>
</div>
<div class="row m-1 mb-4 p-1">
  <div class="col-12 m-0 p-0">
    <p class="login">
      We email you when we ship books. We'll never share your email.
    </p>
  </div>
</div>
