<script>
  import { application, session, cart, user } from "../stores/stores.js";
  import * as animateScroll from "svelte-scrollto";
  import { conditions } from "../shared/Conditions.js";
  import Login from "./Login.svelte";

  let sess;
  const sessUnsubcribe = session.subscribe((value) => {
    sess = value;
  });

  let app;
  const unsubscribe = application.subscribe((value) => {
    app = value;
  });

  let usr;
  const usrUnsubscribe = user.subscribe((value) => {
    usr = value;
  });

  function goToIssues(tskey, subseries, issuenumber) {
    application.update((n) => {
      return {
        ...n,
        screen: "issues",
        screenmode: tskey,
        screencriteria: subseries,
        screensubcriteria: issuenumber,
        letternav: "",
      };
    });
    animateScroll.scrollToTop();
  }

  function picImage(img, size) {
    if (img != "") {
      img = JSON.parse(img);
      if (img) {
        switch (size.toLowerCase()) {
          case "l":
            if (img.L && img.L != "") return img.L;
            else if (img.S && img.S != "") return img.S;
            break;
          case "s":
          default:
            if (img.S && img.S != "") return img.S;
            else if (img.L && img.L != "") return img.L;
            break;
        }
      }
    }
    return "";
  }

  let isLoading = true;
  let issues = [];

  function getIssues() {
    issues = [];
    var options = {
      method: "GET",
      //cache: "no-cache",
      headers: { Authorization: "Bearer " + sess.token },
    };
    fetch(app.apiUrl + "custRecommendations/" + usr.Cust_ID, options)
      .then((res) => res.json())
      .then((res) => {
        issues = res;
        isLoading = false;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }

  $: {
    const uid = usr.Cust_ID;
    isLoading = true;
    if (usr && usr.Cust_ID && usr.Cust_ID > 0) getIssues();
  }
</script>

{#if usr && usr.Cust_ID && usr.Cust_ID > 0}
  <div class="row m-0 p-0 border-bottom border-dark">
    <div class="col-12 m-0 px-1 py-0 text-center nkred">
      <h4 class="title">Comic Book Mind Reader</h4>
    </div>
  </div>
  <div class="row m-0 p-0">
    <div class="col-12">
      {#if isLoading}
        <div class="container my-1 py-1 text-center">
          <div class="spinner-grow spinner-grow-sm text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow spinner-grow-sm text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow spinner-grow-sm text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      {:else}
        <div class="container px-1 mx-0 my-1 py-2">
          For each comic you buy
          <ul>
            <li>We find which of our other 80,000 customers also bought it.</li>
            <li>
              Our computer finds comics they've bought but you haven't, and
            </li>
            <li>Predicts the ones you'll like the most.</li>
          </ul>
          <strong
            >It's like reading 80,000 minds to find their favorites that you
            haven't tried.</strong
          ><br /><br />
          <strong>Click a comic to view its issues</strong><br />
          <small>
            <span class="nkred"
              >Red = Recommended comics you've never bought at NewKadia</span
            ><br />
            <span class="nkblue"
              >Blue = Recommended comics you have bought at NewKadia.</span
            >
          </small>
        </div>
      {/if}
      {#each issues as comic, i}
        <div
          style="line-height:1.25rem;"
          class="col-4 d-inline-block align-top m-0 px-1 py-3 text-center {comic.ordered >
          0
            ? 'nkblue'
            : 'nkred'}"
          role="button"
          tabindex="0"
          on:click|preventDefault={() => goToIssues(comic.TS_Key, "", "")}
          on:keypress|preventDefault={() => goToIssues(comic.TS_Key, "", "")}
        >
          <strong class="black">{i + 1}</strong><br />
          {#if comic.image && comic.image != ""}
            <img
              class="smallCoverImg"
              src="{app.imgUrl}{picImage(comic.image, 's')}"
              alt="{comic.Title}{comic.Series && comic.Series != ''
                ? ' (' + comic.Series + ')'
                : ''}"
            />
          {/if}
          {comic.Title}<br />
          <small>{comic.Series}</small>
        </div>
      {/each}
    </div>
  </div>
{:else}
  <Login />
{/if}
